import React from "react";
import { useState, useEffect } from "react";
import "../Podspiria/MainPage.css";
import CrossPopupImg from "../../../images/Podspiria/Vector.svg";
// import ErrorPopupImg from "../../../images/Podspiria/spotifyError.svg";
// import ErrorPopupImg from "../../../images/Podspiria/spotifyError2.svg";
import ErrorPopupImg from "../../../images/Podspiria/spotifyError3.svg";
import podspirianame from "../../../images/Podspiria/Podspirianame.svg";
import rectangleImg from "../../../images/Podspiria/Rectangle.svg";
import SucessfulPopup from "../../../images/Podspiria/SucessfulPopup.svg";
import SideImageMan from "../../../images/Podspiria/SideImageMan.svg";
import Icon from "../../../images/Podspiria/Icon.svg";
import Faq from "./FAQ/Faq";
import Footer from "./Footer/Footer";
const MainPage = () => {
  let dropCount = 0;
  const [isOpenLanguage, setIsOpenLanguage] = useState(false);
  const [selectedOption, setSelectedOption] = useState("");
  const [isOpenPurpose, setIsOpenPurpose] = useState(false);
  const [language, setLanguage] = useState("English");
  // const [buttonPopup, setButtonPopup] = useState(false);

  const [inputValue, setInputValue] = useState("");
  const [result, setResult] = useState("");

  const [url, Seturl] = useState("");
  const [selectPurpose, setSelectPurpose] = useState("");

  const [currentInput, setCurrentInput] = useState("");
  const [enteredValues, setEnteredValues] = useState([]);

  const [email, setEmail] = useState("");
  const [isValidEmail, setIsValidEmail] = useState(true);
  const options = [
    "SEO",
    "sales",
    "Marketing",
    "Content Writing",
    "Study",
    "Research & Development",
    "Other",
  ];
  useEffect(() => {
    const bodyHasClassA = document.querySelector(".podspiria-header");

    if (bodyHasClassA) {
      const bodyHasClassb = document.querySelector(".header-wrap");
      const bodyHasClassc = document.querySelector(".footer");
      bodyHasClassc.style.display = "none";
      bodyHasClassb.style.display = "none";
    }
  });
  // Video link input
  const handleInputChange = (event) => {
    setInputValue(event.target.value);

    if (event.target.value === "") {
      document.getElementById("ErrorPopup").style.display = "none";
    }
  };

  // handle weather the link is of youtube or spotify
  const handleStartClick = () => {
    // if(selectedOption != "")
    // {
    if (isYouTubeLink(inputValue)) {
      console.log("YouTube link detected!");
      document.getElementById("Emailpopup").style.display = "grid";
      document.getElementById("ErrorPopup").style.display = "none";
      Seturl(inputValue);
      setResult("YouTube link detected!");
    } else if (isSpotifyLink(inputValue)) {
      setResult("Spotify link detected!");
      console.log("Spotify link detected!");
      Seturl(inputValue);
      document.getElementById("Emailpopup").style.display = "grid";
      document.getElementById("ErrorPopup").style.display = "none";
    } else {
      document.getElementById("ErrorPopup").style.display = "block";
      console.log("Link is neither from YouTube nor Spotify.");
    }
    // }
    // else
    // {
    //   console.log("Please enter purpose");
    // }
  };

  const isYouTubeLink = (link) => {
    const youtubeRegex =
      // /^(https?:\/\/)?(www\.)?youtube\.com\/watch\?v=([a-zA-Z0-9_-]+)$/;
      /^(https?:\/\/)?(www\.)?(youtube\.com\/watch\?v=|youtu\.be\/)([a-zA-Z0-9_-]+)$/
    return youtubeRegex.test(link);
  };

  const isSpotifyLink = (link) => {
    const spotifyRegex =
      /^https:\/\/open\.spotify\.com\/track\/[a-zA-Z0-9]+(\?.*)?$/;
    return spotifyRegex.test(link);
  };

  // email validation

  // const handleEmailChange = (event) => {
  //   setEmail(event.target.value);
  // };

  const handleEmailChangeBlur = () => {
    const isValid = validateEmail(email);
    console.log("isvalid", isValid);
    setIsValidEmail(isValid);

    document.getElementById("Thanks").style.cursor = "pointer";
  };

  const validateEmail = (input) => {
    const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
    return emailRegex.test(input);
  };

  // scrollbar
  const scrollbarStyle = {
    /* For webkit-based browsers (Chrome, Safari) */
    scrollbarWidth: "thin",
    scrollbarColor: "blue",

    /* For Firefox */
    scrollbarColor: "blue",
  };

  const CallApi = async (email, result, Purpose) => {
    let requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        email: email,
        Link: result,
        Purpose: Purpose,
        Language: language,
      }),
    };
    await fetch(
      "https://podspiria-bak-pj5xuob5ca-el.a.run.app/User-details",
      requestOptions
    );
  };
  // Tags input

  // purpose
  const handleOptionClick = (event) => {
    setSelectedOption(event.target.value);

    // setIsOpenPurpose(false);
    // console.log(option);
  };
  return (
    <>
      <div style={{ maxWidth: "1330px" }} className="container pod-main">
        <div className="podspiria-header">
          <div className="podImg">
            <img src={podspirianame} alt="Podspiria" id="PodspiriaLogo" />
          </div>
          <div className="sideHeader">
            <img
              className="img-fluid "
              src={rectangleImg}
              alt=""
              srcset=""
              id="rectangle"
            />
            {/* <div className="buttons">
      <button id="Login">Login</button>
      <button type="button" id="GetStarted">
        Get Started
      </button>
    </div> */}
          </div>
        </div>
        <div className="Pod-Sections mob-div">
          <div className="RightSection">
            <h1 className="main-h">
             Youtube and Spotify Podcast Transcribe and Summarize Audio To Text Free Online
            </h1>
            <span className="home-text">
              Podspiria helps podcasters, content writers, marketers, and anyone
              who wants to capture transcriptions and Summarize of Spotify
              podcasts. Get instant Youtube and Spotify podcast transcripts delivered to
              your email.{" "}
            </span>
            <div className="p-div">
              <div className="ErrorPopup " id="ErrorPopup">
                <img className="errorImg" src={ErrorPopupImg} alt="" />
              </div>
              <input
                type="text"
                placeholder="Enter the Link here"
                onChange={handleInputChange}
                id="inputLink"
              />
            </div>
            {/* ------------------- */}

            <div className="startbuttons">
              <div className="startdiv">
                <button id="Start" onClick={handleStartClick}>
                  Transcribe
                </button>
                <a
                  href="https://chrome.google.com/webstore/detail/podspiria/amkkpnkdfgjgdkgakphepcbfjfcjfhbc"
                  target="_blank" rel="noreferrer">
                  <button id="add-chrome">Add to chrome, Its free</button>
                </a>

                <div className="free">
                  <span className="free-text"> 100% Free for Lifetime </span>
                </div>
              </div>

              <div className="mainStepDiv mob-steps">
                <div className="subStepDiv1 ">
                  {/* <button className="StepsButton">1</button> */}
                  <div className="btn1">1</div>
                  {/* <img className="img-fluid one" src={One}  /> */}
                  <div className="StepsSpan">
                    Insert the Youtube or Spotify Podcast Link
                  </div>
                </div>
                <div className="subStepDiv2">
                  {/* <button className="StepsButton">2</button> */}
                  {/* <img className="img-fluid two"  src={Two} /> */}
                  <div className="btn1">2</div>
                  <div className="StepsSpan">Provide your email</div>
                </div>
                <div className="subStepDiv3 ">
                  {/* <img className="img-fluid three" src={Three}  /> */}
                  {/* <button className="StepsButton">3</button> */}
                  <div className="btn1">3</div>
                  <div className="StepsSpan">
                    Receive podcast transcription and summary over your email.
                  </div>
                </div>
              </div>
            </div>
          </div>

          <>
            <div className="Emailpopup" id="Emailpopup">
              <img
                src={CrossPopupImg}
                alt=""
                id="CrossPopup"
                onClick={() => {
                  document.getElementById("Emailpopup").style.display = "none";
                }}
              />
              <h3 className="h3-tag">
                Enter your mail for transcription and summary
              </h3>

              <p className="p-tag">Recieve mail on completion</p>
              <input
                type="email"
                placeholder="Enter your Email"
                value={email}
                id="EmailInput"
                onChange={(e) => setEmail(e.target.value)}
                onBlur={handleEmailChangeBlur}
                required
              />
              <input
                type="text"
                placeholder="Enter your Purpose"
                value={selectedOption}
                id="EmailInput"
                onChange={handleOptionClick}
                onBlur={handleEmailChangeBlur}
                required
              />
              {!isValidEmail && (
                <p className="p-error">Please enter a valid email address.</p>
              )}
              <button
                type="button"
                id="Thanks"
                disabled={!email}
                onClick={() => {
                  if (isValidEmail) {
                    // setIsClose(false);
                    document.getElementById("popup1").style.display = "flex";
                    document.getElementById("Emailpopup").style.display =
                      "none";
                    document.getElementById("Thanks").style.cursor = "pointer";
                    CallApi(email, url, selectedOption, language);
                  }
                }}>
                Yeah Thanks!
              </button>
            </div>

            <div id="popup1" className="popup1">
              <div className="popup">
                <div class="left-content">
                  <img
                    style={{ background: "white", borderRadius: "8px" }}
                    src={Icon}
                    alt=""
                  />
                </div>
                <div className="right-content">
                  <h2>Successful !</h2>
                  <p className="p-tag-popup">
                    Your summary has been successfully captured, and it will be
                    forwarded to you shortly.
                  </p>
                </div>
                <div className="crossPopup">
                  <button
                    className="closepopup"
                    onClick={() => {
                      document.getElementById("popup1").style.display = "none";
                    }}>
                    ×
                  </button>
                </div>
              </div>
            </div>

            {/* <div className="sucessfull" id="sucessfull">
              <img
                style={{ verticalAlign: "unset" }}
                src={SucessfulPopup}
                alt="Sucessfully uploaded"
              />
              <img
                src={CrossPopupImg}
                alt="cancel"
                id="crossSuccesfull"
                onClick={() => {
                  document.getElementById("sucessfull").style.display = "none";
                }}
              />
            </div> */}
            {/* <div className="mainStepDiv row">
      <div className="subStepDiv col-2"> */}
            {/* <button className="StepsButton">1</button> */}
            {/* <img className="img-fluid one" src={One}  />
        <div className="StepsSpan">Insert the Spotify Podcast Link</div>
      </div>
      <div className="subStepDiv col-2"> */}
            {/* <button className="StepsButton">2</button> */}
            {/* <img className="img-fluid two"  src={Two} />
        <div className="StepsSpan">Provide your email</div>
      </div>
      <div className="subStepDiv col-3">
      <img className="img-fluid three" src={Three}  /> */}
            {/* <button className="StepsButton">3</button> */}
            {/* <div className="StepsSpan">
          Receive podcast transcription over your email.
        </div>
      </div>
  </div> */}
            <div className="LeftSection">
              <img
                className="img-fluid"
                src={SideImageMan}
                id="SideMan"
                alt="man"
              />
            </div>
          </>
        </div>
        {/* <div className="mob-div">
          <h1 className="mob-h">
            Spotify Podcast Transcribe Audio To Text Free Online
          </h1>
          <span className="mob-text">
            Podspiria help podcaster, content writer, marketers and anyone who
            want to capture transcription of Spotify podcast. Get Instant
            Spotify Podcast Transcript over mail.
          </span>
          <input
            type="text"
            placeholder="Enter the Link here"
            onChange={handleInputChange}
            id="inputLink"
          />
          <div className="startdiv">
            <button id="Start" onClick={handleStartClick}>
              Transcribe
            </button>
            <div className="free">
              <span className="free-text"> 100% Free for Lifetime </span>
            </div>
            <div className="mob-steps row ">
              <div className="mstep-1 col-3">
               
                <div className="btn1">1</div>
                <div className="StepsSpan">Insert the Spotify Podcast Link</div>
              </div>
              <div className="mstep-2 col-3">
               
                <div className="btn1">2</div>
                <div className="StepsSpan">Provide your email</div>
              </div>

              <div className="mstep-3 col-3">
               
                <div className="btn1">3</div>
                <div className="StepsSpan3">
                  Receive podcast transcription over your email.
                </div>
              </div>
            </div>
          </div>
        </div> */}
      </div>
      <Faq />
      <hr className="hr-tag" />
      <Footer />
    </>
  );
};

export default MainPage;
