import React, { useEffect, useRef } from "react";
import "./HubspotContactForm.css";

const HubspotContactForm = ({ setShowPopUp}) => {
  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://js.hsforms.net/forms/v2.js";
    document.body.appendChild(script);

    script.addEventListener("load", () => {
      // @TS-ignore
      if (window.hbspt) {
        // @TS-ignore
        window.hbspt.forms.create({
          portalId: "22613214",
          formId: "992082ce-67f1-45dc-8f02-d3886873d216",
          target: "#hubspotForm",
        });
      }
    });
  }, []);


//  const ref = useRef(null);

//   useEffect(() => {
//     const handleClickOutside = (event) => {
//       if (ref.current && !ref.current.contains(event.target)) {
//         onClickOutside && onClickOutside();
//       }
//     };
//     document.addEventListener('click', handleClickOutside, true);
//     return () => {
//       document.removeEventListener('click', handleClickOutside, true);
//     };
//   }, [onClickOutside]);

  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef);

  function useOutsideAlerter(ref) {
    useEffect(() => {
      /**
       * Alert if clicked on outside of element
       */
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          setShowPopUp(false)
        }
      }
      // Bind the event listener
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        // Unbind the event listener on clean up
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref]);
  }

  return (
    <div className="container-fluid d-flex align-items-center hubspot-contact-form-001 " >
      <div className="container">
        <div className="section">
          <div className="row justify-content-center hubspot-wrapper-container-00 " >
            <div className="col-12 col-sm-6 p-4 " ref={wrapperRef}>
              <button
                className="float-right"
                onClick={() => setShowPopUp(false)}
              >
                X
              </button>
              <h2 className="text-center">Enquiry Now</h2>
              <div id="hubspotForm"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HubspotContactForm;
