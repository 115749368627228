import React from "react";

import data from "./ScreenRecorderData";
import Bannnerimage from "../../../../images/casestudy/screen_rec_ext.webp";
import { SuperSEO } from "react-super-seo";
import Casebanner from "../CaseBanner/Casebanner";

const ScreenRecorder = () => {
    return (
        <>
            <SuperSEO
                title="ScreenRecorder - Lakebrains"
                description="Screen Recorder Extension is a valuable chrome extension capturing your chrome tabs like google meets & other content related to websites."
                lang="en"
                twitter={{
                    twitterSummaryCard: {
                        summaryCardImage: { Bannnerimage },
                        summaryCardImageAlt: "ScreenRecorder",
                        summaryCardSiteUsername: "ScreenRecorder",
                    },
                }}
            />


            {data.map((items, key) => (
                <Casebanner key={key} data={items.data} />
            ))}
        </>
    );
};

export default ScreenRecorder;
