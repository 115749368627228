import Bannnerimage from "../../../../images/logo/logo_lakebrains.webp";

const data = [
  {
    slugurl: "/ClozureExtension",
    data: [
      {
        Banner: [
          {
            sector:"Recruitment/ Technology",
            headingtext: "Clozure Extension",
            headingpara:
              "Clozure is a browser extension that allows users to create shortcuts and tags for frequently used phrases or sentences.",
            image: Bannnerimage,
          },
        ],
        heading:
          "Streamline your typing with shortcuts and organise content with tags.",
        casestudy: {
          Problem:
            `Clozure is a feature-rich browser extension by which users can set up custom abbreviations that automatically expand into longer text .This capability significantly reduces the time spent on repetitive typing tasks, ensuring quicker and more consistent communication.Tags can be used to label and sort information.`,
          challenge: [
            {
              heading: "How To Use It:",
              challengeli: [
                { First: "Install the Clozure extension" },
                { First: "Pin the extension and click on the extension icon to open it." },
                { First: "Click on the tags button to create the tags and shortcuts button to create shortcuts." },
                { First: `After clicking the "Tags" or "Shortcuts" button, a form appears allowing you to create and save new tags or shortcuts.` },
              ],
            },
            {
              heading: "Why To Use It:",
              challengeli: [
                { First: `Time-saving Shortcuts: Clozure significantly reduces the time spent on repetitive typing tasks.Users can simply type a short abbreviation with //  and have it automatically expanded into the desired phrase. This feature is particularly beneficial for individuals who frequently communicate through messaging platforms, emails, or documents.` },
                { First: "Consistency and Accuracy: With Clozure, users can ensure consistency and accuracy in their communication. By standardising phrases through shortcuts, there's less room for error or variation in language usage." },
                { First: "Efficient Content Organisation: Users can categorise their content using custom tags, making it easier to search for and retrieve information when needed." },
                { First: `Personalization and Customization: Clozure allows users to tailor the extension according to their specific needs and preferences. They can create custom shortcuts and tags that reflect their unique communication style and organisational requirements.`},
                { First: `Enhanced Productivity: Clozure contributes to enhanced productivity by streamlining typing tasks and improving content management. By reducing the time spent on typing repetitive phrases and facilitating efficient content organisation, users can focus their time and energy on more important tasks.`},
              ],
            },
            {
              heading: "Challenges:",
              challengeli: [
                { First: `Challenges in loading the extension UI on a webpage.` },
                { First: "Challenges in detecting the current active input field of the web page." },
                { First: "Displaying extension's UI on the web pages." },
                { First: "While making changes in one tab, the same data should be reflected on all other tab." },
                { First: "Displaying shortcuts and tags within the input field based on user input." },
              ],
            },
            {
              heading: "Approaches:",
              challengeli: [
                { First: `We overridden the UI using Chrome extension power.` },
                { First: "The JavaScript event listener was employed to detect the input field." },
                { First: "By utilising the CSS’s properties we made it possible to display the correct UI of the extensions" },
                { First: "Integrating with Chrome storage for efficient data management and synchronisation." },
                { First: "Using the extension's ability to detect events and changes in web pages we were able to display shortcuts and tags at correct places on the UI." },
              ],
            },
            {
              heading: "Impact:",
              challengeli: [
                { First: `The Clozure extension brings a transformative impact to users' browsing experiences by offering a comprehensive set of features aimed at streamlining text input and content organisation. With its ability to convert custom abbreviations into longer text automatically, Clozure significantly reduces the time spent on repetitive typing tasks, thereby enhancing productivity and efficiency. By enabling users to categorise and manage their data effectively, Clozure contributes to a more streamlined workflow and facilitates easy retrieval of specific content when needed.` },
              ],
            },
          ],
        },

        companyinfo: {
          logourl:
            "yasmin",
          about: "About",
          aboutpara:
            "Clozure Extension simplifies browsing by enabling users to create shortcuts and tags for commonly used phrases or sentences. This streamlines communication and workflow, enhancing productivity.",
          companysector: [
            {
              first: "Company Name:",
              secound: "Clozure",
            },
            {
              first: "INDUSTRY:",
              secound: "Recruitment/ Technology",
            },
            // {
            //   first: "LOCATION:",
            //   secound: "Udaipur, India",
            // },
          ],
        },
        customer: [

        ],
      },
    ],
  },
];

export default data;
