import React from 'react';
import Logo from '../../../../images/Podspiria/Podspirianame.svg'
import '../Footer/Footer.css'
const Footer = () => {
    return (
        <div className="container-fluid">
        <div className="footermain d-flex justify-content-between align-items-center">
          <div className="footer-section-one">
            <img className="logoImg2 w-65" src={Logo} alt="" />
          </div>
          <div className="d-flex">
            <div className="footer-section-two">
              <span className="d-flex justify-content-center align-items-center">
              © 2023, <a href="https://lakebrains.com/">Lakebrains Technologies.</a> All rights reserved.
                
              </span>
            </div>
          </div>
        </div>
      </div>
    );
}

export default Footer;
