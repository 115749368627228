import Bannnerimage from "../../../images/casestudy/idealeades.webp";

const data = [
  {
    slugurl: "/interact",
    data: [
      {
        Banner: [
          {
            sector: "Marketing/ Lead Generation",
            headingtext: "Idealeads",
            headingpara:
              "Quality contacts are greatest asset for any business. Get quality B2B lead database which includes CXO, Owner, Director, Partner, VP & Managers of 150+ sectors.",
            image: Bannnerimage,
          },
        ],
        heading:
          "Idealeads B2B Lead Generation Tools",
        casestudy: {
          Problem:
            `The challenges involve running and deploying a complex algorithm over the web, creating a user-friendly interface, working with frameworks that don't support multithreading processes, and ensuring data protection and security for Idealeads' B2B lead generation tools.`,
          challenge: [
            {
              heading: "The Challenges ",
              challengeli: [
                { First: "Run and deploy our complex algorithm over web." },
                { First: "Interface should be user friendly." },
                { First: "Many Frameworks doesn’t support Multithreading process." },
                { First: "Protect over data and security." },
              ],
            },
            {
              heading: "The Solutions ",
              challengeli: [
                { First: "Create SaaS tool using Django Framework." },
                { First: "Connect all automation tool with Django framework." },
              ],
            },
            {
              heading: "Impact",
              challengeli: [{ First: `The implementation of a SaaS tool using the Django Framework and connecting all automation tools with the framework enables the development of Idealeads' B2B Lead Generation Tools. These tools provide lifetime access to a quality lead database, facilitating effective lead generation and enhancing business growth opportunities for users.` },],
            },
            {
              heading: "Results",
              challengeli: [
                { First: "Idealeads B2B Lead Generation Tools." },
                { First: "Lifetime Access to Quality Lead Database." },
              ],
            },

          ],
        },

        companyinfo: {
          logourl:
            "",
          about: "About ",
          aboutpara:
            "Our Aim to consistently deliver engaging leads. We have worked with a great number of B2B companies and helped them to convert the right prospects into valuable clients.",
          companysector: [
            {
              first: "INDUSTRY:",
              secound: "Sales and Marketing",
            },
            {
              first: "HQ LOCATION:",
              secound: "Udaipur, Rajasthan",
            },
          ],
        },
        customer: [

        ],
      },
    ],
  },
];

export default data;
