import Bannnerimage from "../../../../images/casestudy/SQUIRE_Img.jpg";

const data = [
  {
    slugurl: "/SquireRing",
    data: [
      {
        Banner: [
          {
            sector:"Recruitment/ Technology",
            headingtext: "Squire: Ringcentral Marketplace Application",
            headingpara:
              "Squire's Ringcentral app lets you easily capture conversations with candidates and push the recording to the Squire application for processing, automatically updating your ATS with the relevant candidate information and an AI-generated recruiter write-up.",
            image: Bannnerimage,
          },
        ],
        heading:
          "Unlock Recruiter Potential with Squire!",
        casestudy: {
          Problem:
            `Squire’s conversational intelligence equips recruiters to place more candidates. By capturing every call your recruiters make, and analysing and extracting the right information, recruitment leaders know that the data going into their ATS is as accurate as possible and that their consultants are having the right conversations`,
          challenge: [
            {
              heading: "WHY USE SQUIRE??:- ",
              challengeli: [
                { First: "Increase Recruiter Capacity" },
                { First: "Reduce Recruiter's Mental Load." },
                { First: "Accurate and Valuable Info in Your ATS" },
                { First: "Capture Value in Every Interaction" },
              ],
            },
            {
              heading: "The Challenges:-",
              challengeli: [
                { First: "Authenticate user’s ringcentral account and maintain tokens in the database for future use." },
                { First: "Subscribe to webhook when using integrate ringcentral to squire and delete old subscriptions." },
                { First: "Manage ringcentral tokens, prevent the expiration of ringcentral tokens." },
                { First: "Listen webhook events and on call start, start recording and on call end download the recording and upload file to s3 bucket and send url to squire backend." },
              ],
            },
            {
              heading: "Solutions",
              challengeli: [
                { First: "Using Ringcentral’s OAuth authentication to get tokens and save all tokens in the database along with the user's Ringcentral owner id." },
                { First: "After getting tokens from OAuth, called Ringcentral’s API to delete all previous webhook subscriptions. And then subscribing to a new webhook." },
                { First: "Earlier we were using Ringcentral’s npm package which was automatically expiring tokens so to prevent that we used a custom setup to call apis endpoints." },
                { First: "Listening call answered event on webhook url and calling start recording API for that meeting id and on call discard getting recording uploading to S3 Bucket." },
              ],
            },
          ],
        },

        companyinfo: {
          logourl:
            "yasmin",
          about: "About ",
          aboutpara:
            "Squire is a product of https://olas.io/ which is developed by Lakebrains Technologies. Squire's Chrome Extension lets you easily capture conversations with candidates and save the recording for processing an AI-generated recruiter write-up.",
          companysector: [
            {
              first: "Company Name:",
              secound: "Squire",
            },
            {
              first: "INDUSTRY:",
              secound: "Recruitment",
            },
            {
              first: "LOCATION:",
              secound: "Dublin City, County Dublin",
            },
          ],
        },
        customer: [

        ],
      },
    ],
  },
];

export default data;
