import Bannnerimage from "../../../../images/casestudy/BCG.png";

const data = [
  {
    slugurl: "/BCG_CaseStudy",
    data: [
      {
        Banner: [
          {
            sector:"Consumer/ Technology",
            headingtext: "Data Scrape from Glassdoor",
            headingpara:
              "A Javascript (NodeJs) Implementation to scrape companies job data from glassdoor.",
            image: Bannnerimage,
          },
        ],
        heading:
          " This Implementation is capable of scraping jobs data of the given company name from glassdoor.",
        casestudy: {
          Problem:
            `The objective of this project is to demonstrate the use of Puppeteer, a headless browser automation tool, to scrape data from Glassdoor, specifically salary information for various job roles at a given company. The scraped data is then processed and integrated into a Google Sheets spreadsheet using the Google Sheets API.`,
          challenge: [
            {
              heading: "The Challenges:- ",
              challengeli: [
                { First: "There should not be repetition of the same data." },
                { First: "Avoid authenticate glassdoor again and again" },
                { First: "Scrape data for n number of companies" },
                { First: "URL Processing and Data Scraping" },
                { First: "Store data in google sheet" },
              ],
            },
            {
              heading: "Solutions:-",
              challengeli: [
                { First: `"The code checks for the existence of a "urls.json" file containing previously scraped Glassdoor URLs for job roles at the specified company."` },
                { First: `"The code checks for the existence of a "cookies.json" file.
                If the file exists, it loads the session cookies into the browser to avoid logging in again. 
                If the file doesn't exist, it navigates to Glassdoor's login page, enters login credentials, saves the session cookies to a file for future use, and logs in."` },
                { First: "The code scrapes data for n-number of urls one by one  using loops." },
                { First: `"After processing a URL, it removes it from the list of URLs or deletes the "urls.json" file when all URLs are processed."` },
                { First: `"For each URL:
                It navigates to the Glassdoor page for a specific job role.
                Scrapes various salary-related data, including base pay and additional pay averages.
                Filters salary data for different experience levels (0-1 years, 1-3 years, etc.).
                Collects this data into an array.
                Appends the data to the Google Sheets spreadsheet for analysis.
                "` },
                { First: "The code utilizes the Google Sheets API to authenticate and append data to a specific Google Sheets spreadsheet. Data is added in the appropriate format and range within the spreadsheet." },
              ],
            },
            {
              heading: "Impact",
              challengeli: [ {First:`The implementation of solutions for the extension addresses these challenges. By adding new buttons that blend with the Ali Express website's UI, users can easily access the product analysis tool. The extraction of product IDs, order IDs, and tracking IDs from their respective sections on the website streamlines the data collection process for analysis and evaluation. The ability to extract tracking IDs without displaying an iframe enhances the user experience and maintains a seamless browsing experience. Overall, the "Product Analysis Tool for Ali Express" extension improves efficiency, data analysis, and decision-making for users navigating the Ali Express platform.`},],
            },
          ],
        },

        companyinfo: {
          logourl:
            "yasmin",
          about: "About ",
          aboutpara:
            " This case study demonstrates how Puppeteer and the Google Sheets API can be used to automate web scraping and data integration tasks, streamlining the process of gathering and analyzing data from external sources.",
          companysector: [
            {
              first: "Company Name:",
              secound: "BCG",
            },
            {
              first: "INDUSTRY:",
              secound: "Consumer Products Industry",
            },
            {
              first: "LOCATION:",
              secound: "Massachusetts, United States",
            },
          ],
        },
        customer: [

        ],
      },
    ],
  },
];

export default data;
