import React from "react";
import { PopupButton } from "react-calendly";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import "./presentation.scss";
import "./home.css";
import CustomerSpeak from "../../../../images/Videos/customer-speak.webm";
import SucessStory from "../../../../images/Videos/sucess-story.webm";
import Service from "../../../../images/Videos/services.webm";
import Article from "../../../../images/Videos/articles.webm";
import TransformVisionLight from "../../../../images/Videos/TransformVisionLight.mp4";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";

const Home = () => {
  return (
    <>
      <Helmet>
        <title>AI SaaS Development Company - Lakebrains</title>
        <meta name="description" content="Transform your vision into reality with Lakebrains, an AI SaaS development company offering innovative software solutions." />
        <meta name="keywords" content="AI SaaS, software development, innovative solutions, AI software, Lakebrains" />
      </Helmet>

      <div className="home-top-banner example-classname">
        <Carousel
          autoFocus={true}
          autoPlay={false}
          infiniteLoop={true}
          interval={false}
          emulateTouch={false}
          preventMovementUntilSwipeScrollTolerance={true}
          stopOnHover={true}
          showThumbs={false}
          showStatus={false}
          useKeyboardArrows
          className="presentation-mode"
        >
          <div key="content-4" className="my-slide video-container fifth">
            <video
              className="banner_bg_video"
              muted
              autoPlay={"autoplay"}
              preload="auto"
              loop
              loading="lazy"
            >
              <source
                src={TransformVisionLight}
                type="video/webm"
              />
            </video>
            <div className="container">
              <div className="banner-content-wrap">
                <div className="banner_content">
                  <div className="banner-inner-content">
                    <h2 className="underline">An AI SaaS Development Company</h2>
                    <h1>Transform Your Vision into Reality</h1>
                    <p>
                      Empowering growth through innovative software solutions. Your vision, our expertise. Let's build success together with Lakebrains.
                    </p>
                    <PopupButton
                      className="btn mainbutton-header HomeHeaderBtn"
                      url="https://calendly.com/khushbu-jpao/30min?back=1&month=2024-05"
                      rootElement={document.getElementById("root")}
                      text="Let’s talk"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div key="content-0" className="my-slide video-container primary">
            <video
              className="banner_bg_video"
              muted
              autoPlay={"autoplay"}
              preload="auto"
              loop
              loading="lazy"
            >
              <source
                src={CustomerSpeak}
                type="video/webm"
              />
            </video>
            <div className="container">
              <div className="banner-content-wrap">
                <div className="banner_content">
                  <div className="banner-inner-content">
                    <h2 className="underline">Customer's Success</h2>
                    <h1>Interact Technologies and Lakebrains</h1>
                    <p>
                      Jack Sands, CEO & Founder at Interact, talks about their experience with Lakebrains
                    </p>
                    <a className="btn mainbutton-header HomeHeaderBtn" href="https://www.youtube.com/watch?v=wZ02FUjCYHw&t=3s&ab_channel=LakeBrainsTechnologies" rel="noopener noreferrer" target="_blank"> Watch</a>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div key="content-1" className="my-slide video-container secondary">
            <video
              className="banner_bg_video"
              muted
              autoPlay={"autoplay"}
              preload="auto"
              loop
              loading="lazy"
            >
              <source
                src={SucessStory}
                type="video/webm"
              />
            </video>
            <div className="container">
              <div className="banner-content-wrap">
                <div className="banner_content">
                  <div className="banner-inner-content">
                    <h2 className="underline">Success Stories</h2>
                    <h1>SaaS Product Development For Startups</h1>
                    <p>
                      Whether you are a start-up or an established enterprise, we offer one step SaaS development services to help prepare better software and allowing for innovation at scale.
                    </p>
                    <div className="row p-0 mx-0 my-3">
                      <a className="btn mainbutton-header HomeHeaderBtn" href="https://testimonial.to/lakebrains-technologies/all" rel="noopener noreferrer" target="_blank">
                        Testimonial
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div key="content-2" className="my-slide video-container third">
            <video
              className="banner_bg_video"
              muted
              autoPlay={"autoplay"}
              preload="auto"
              loop
              loading="lazy"
            >
              <source
                src={Service}
                type="video/webm"
              />
            </video>
            <div className="container">
              <div className="banner-content-wrap">
                <div className="banner_content">
                  <div className="banner-inner-content">
                    <h2 className="underline">Our Services</h2>
                    <h1>AI Enabled Web Development Services</h1>
                    <p>Scale Your Business with Idea Custom-Built AI Software</p>
                    <div className="row p-0 mx-0 my-3">
                      <Link className="btn mainbutton-header HomeHeaderBtn" to="/saas-product-service-provider-company/">
                        SaaS Product
                      </Link>{" "}
                      <Link className="btn mainbutton-header HomeHeaderBtn" to="/ai-ml-Development-service-provider-company/">
                        AI/ML Development
                      </Link>{" "}
                      <Link className="btn mainbutton-header HomeHeaderBtn" to="/Cloud-Deployment-service-provider-company/">
                        Cloud Deployment
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div key="content-3" className="my-slide video-container fourth">
            <video
              className="banner_bg_video"
              muted
              autoPlay={"autoplay"}
              preload="auto"
              loop
              loading="lazy"
            >
              <source
                src={Article}
                type="video/webm"
              />
            </video>
            <div className="container">
              <div className="banner-content-wrap">
                <div className="banner_content">
                  <div className="banner-inner-content">
                    <h2 className="underline">Article</h2>
                    <h1>Zoom Marketplace Application</h1>
                    <p>
                      In today’s fast-paced digital world, effective communication and collaboration have become the cornerstone of success for businesses, educational institutions, and individuals.
                    </p>
                    <a
                      className="btn mainbutton-header HomeHeaderBtn"
                      href="/casestudy/SquireZoom/"
                      rel="noopener noreferrer"
                      // target="_blank"
                    >
                      Blogs
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Carousel>
      </div>
    </>
  );
};

export default Home;
