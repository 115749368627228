import React from "react";
import data from "./Rea_ExtensionData";
import Bannnerimage from "../../../../images/casestudy/ReaExtension.png";
import { SuperSEO } from "react-super-seo";
import Casebanner from "../CaseBanner/Casebanner";

const Rea_Extension = () => {
  return (
    <>
      <SuperSEO
        title="Rea Extension - Lakebrains"
        description="Rea is a valuable chrome extension tool To Remind saved articles by desktop notifications"
        lang="en"
        twitter={{
          twitterSummaryCard: {
            summaryCardImage: { Bannnerimage },
            summaryCardImageAlt: "Rea Extension",
            summaryCardSiteUsername: "Rea Extension",
          },
        }}
      />

      {data.map((items, key) => (
        <Casebanner key={key} data={items.data} />
      ))}
    </>
  );
};

export default Rea_Extension;
