import Bannnerimage from "../../../../images/casestudy/texthighlight.webp";
import Logo from "../../../../images/logo/logo_lakebrains.webp";

const data = [
  {
    slugurl: "/texthighlight",
    data: [
      {
        Banner: [
          {
            sector: "Productivity/ Reading",
            headingtext: "Text Highlight Chrome Extension",
            headingpara:
              "Text Highlight is a valuable chrome extension for showing selected or highlighted text in different WPM(Words per min).",
            image: Bannnerimage,
          },
        ],
        heading:
          "chrome extension to show highlighted text in different WPM(Words per min)",
        casestudy: {
          Problem:
            `"Existing Chrome extensions for displaying highlighted text in different Words Per Minute (WPM) face challenges related to capturing the highlighted text within the browser and adjusting the timing calculations based on the selected WPM. "`,
          challenge: [
            {
              heading: "How to use it:- ",
              challengeli: [
                { First: "Download Text Highlight Chrome Extension" },
                { First: "By left-clicking on the mouse select the text by highlighting it" },
                { First: "Click on the extension icon" },
                { First: "Click on the start button on the popup" },
                { First: "Use the WPM dropdown & 10s forward and backward buttons for customizing text." },
              ],
            },
            {
              heading: "The Challenges:- ",
              challengeli: [
                { First: "Take text in extension by highlighting on the chrome." },
                { First: "10s forward,  backward & WPM Math varies by words." },
              ],
            },
            {
              heading: "The Approaches:-",
              challengeli: [
                { First: "We used window.getSelection(); function to get highlighted text." },
                { First: "Function to dynamically change calculations depending on WPM in extension." },
              ],
            },
            {
              heading: "Impact",
              challengeli: [{ First: ` The implementation of the solution addresses the challenges faced by existing text highlighting extensions. By utilizing the window.getSelection() function, the extension is able to accurately capture the highlighted text within the browser. Additionally, the implementation of dynamic calculations based on the selected WPM ensures accurate timing adjustments for displaying the highlighted text. This enhances the reading experience for users, allowing them to customize the speed at which the text is displayed and improving their overall reading efficiency. The extension promotes effective reading and comprehension by providing users with a tool that adapts to their preferred reading pace.` },],
            },
          ],
        },

        companyinfo: {
          logourl:
            Logo,
          about: "About ",
          aboutpara:
            "Text Highlight is a valuable chrome extension for showing selected or highlighted text in different WPM(Words per min).",
          companysector: [
            {
              first: "Company Name:",
              secound: "Yasmin",
            },
            {
              first: "INDUSTRY:",
              secound: "Text Highlight",
            },
            {
              first: "LOCATION:",
              secound: "San Francisco, United States",
            },
          ],
        },
        customer: [],
      },
    ],
  },
];

export default data;
