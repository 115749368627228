import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCoffee } from "@fortawesome/free-solid-svg-icons";

const Benefits = ({ Data }) => {
  const gridCols = [[], []];

  const [section, setsection] = useState("");

  useEffect(() => {
    setsection(Data);
  }, [Data]);

  section?.steps?.map((data, i) => {
    const comp = (
      <div
        className={
          i % 2
            ? " content-benifit  row flex-row-reverse"
            : " content-benifit flex content-benifit0 row"
        }
      >
        <p className="para-benifits para-benifits0 col-10"> {data.para} </p>
        <img
          className="img-benifits img-benifits0 col-2"
          src={data.img}
          alt="Image not found"
        ></img>
        <img
          className="img-benifits hide-benifits "
          src={data.img}
          alt="Image not found"
        ></img>
        <p className="para-benifits hide-benifits text-center"> {data.para} </p>
      </div>
    );
    const colNumber = i % 2;
    gridCols[colNumber].push(comp);
  });

  return (
    <div className="stepbystep">
      <header className="homepage-heading stepbystep">
        <h2>{section?.title}</h2>
        <p>{section?.subTitle}</p>
      </header>
      <section className="container">
        <div className="row mx-2">
          <div className="col-md-4">{gridCols[0]}</div>
          <div className="col-md-4">
            <img
              className="img-content-benifit"
              src={section?.image}
              alt="Image not found"
            />
          </div>
          <div className="col-md-4">{gridCols[1]}</div>
        </div>
      </section>
    </div>
  );
};

export default Benefits;
