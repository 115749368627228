import React from "react";
import UnderstandYourUser from "../../../../images/NewHomePage/UnderstandYourUser.png";
import PainPoints from "../../../../images/NewHomePage/PainPoints.png";
import PurposePotentialSolutions from "../../../../images/NewHomePage/PurposePotentialSolutions.png";
import BuildIteratively from "../../../../images/NewHomePage/BuildIteratively.png";
import SeeWorks from "../../../../images/NewHomePage/SeeWorks.png";
import "./OurApproach.css";

const OurApproach = () => {
    const data = [
        {
            number: 1,
            para: "Start by defining who the user is? What do they do? and try and build the persona of the user you wish to help or facilitate.",
            h4: "Empathize",
            h2: "Understand your user",
            alt: "UnderstandYourUser",
            image: UnderstandYourUser,
            classes: {
                cont: "OurApproach-UserRight",
            }
        },
        {
            number: 2,
            para: "Then with the help of the user personas try and come up with problem statements that accurately define the issues the user faces.",
            h4: "Define",
            h2: "The pain points",
            alt: "PainPoints",
            image: PainPoints,
            classes: {
                cont: "OurApproach-UserLeft",
            }
        },
        {
            number: 3,
            para: "Once you are at this stage gather the troops and try to purpose multiple solutions for the problems outlined.",
            h4: "Ideate",
            h2: "Purpose potential solutions",
            alt: "PurposePotentialSolutions",
            image: PurposePotentialSolutions,
            classes: {
                cont: "OurApproach-UserRight",
            }
        },
        {
            number: 4,
            para: "Now that you have selected a potential solution, move forward by using your creative flair to bring it to life but in stages.",
            h4: "Prototype",
            h2: "Build iteratively",
            alt: "BuildIteratively",
            image: BuildIteratively,
            classes: {
                cont: "OurApproach-UserLeft",
            },
            listItem1: "So to discuss the idea with peers you should use Low Fidelity prototypes.",
            listItem2: "When presenting to stakeholders use High Fidelity prototypes.",
            listItem3: "When you want to test it with end customers use a working prototype.",
        },
        {
            number: 5,
            para: "To test your solution, arrange user groups or go to your user and present the design to them. Let them use it and provide you feedback about what they think works and what doesn’t. In light of these findings, you can either go back to the drawing board and rebuild your prototype, or you can try and refine your problem statement or go into full-scale production.",
            h4: "Test",
            h2: "See what works",
            alt: "SeeWorks",
            image: SeeWorks,
            classes: {
                cont: "OurApproach-UserRight",
            }
        },
    ];

    return (
        <div className="OurApproach-main container-fluid d-flex justify-content-center">
            <div className="OurApproach-main-container d-flex flex-column align-items-center justify-content-center">
                <div className="OurCommitment-text d-flex text-center flex-column align-items-center">
                    <h4>Our Approach</h4>
                    <h2>Our Design Thinking Approach in Five Key Steps</h2>
                    <p>Results in user-centered, innovative solutions through collaboration, iteration, and empathy.</p>
                </div>

                <div className="OurApproach-container">
                    {data.map((items, key) => (
                        <div
                            id={key}
                            key={key}
                            className={`yourUser d-flex align-items-center justify-content-center ${items.classes?.cont}`}>
                            <div className="OurApproach-imageContainer">
                                <img className='OurApproach-image' src={items.image} alt={items.alt} loading="lazy"/>
                            </div>
                            <div className="yourUser-text">
                                <h4>{items.h4}</h4>
                                <h2>{items.h2}</h2>
                                <p>{items.para}</p>
                                {items.number === 4 && (
                                    <ul>
                                        <li>{items.listItem1}</li>
                                        <li>{items.listItem2}</li>
                                        <li>{items.listItem3}</li>
                                    </ul>
                                )}
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default OurApproach;
