import Bannnerimage from "../../../../images/CaseStudiesImagesLogo/savekarBanner.png";

const data = [
  {
    slugurl: "/SavekarWeb",
    data: [
      {
        Banner: [
          {
            sector: "Recruitment/ Technology",
            headingtext: "Savekar",
            headingpara:
              " SaveKar brings clarity, passion and insight to work for its customers and deliver it with unmatched service.",
            image: Bannnerimage,
          },
        ],
        heading:
          "Your Offshore Accounting Partner in US, UK, Ireland, Canada & Australia",
        casestudy: {
          Problem:
            `Savekar is a leading offshore accounting firm specializing in serving US, UK, Ireland, Canada, and Australian CPA firms and Small & Medium Businesses (SMBs). We offer a comprehensive suite of services designed to streamline your accounting operations, reduce costs, and free up your valuable time to focus on core business activities.`,
          challenge: [
            {
              heading: "The Challenges:-",
              challengeli: [
                { First: "Theme Customization: Personalise the theme to match your brand with colors, fonts, layouts, and logo." },
                { First: "Plugin conflicts leading to site instability." },
                { First: "Performance issues due to heavy themes and plugins." },
                { First: "Security vulnerabilities and frequent updates." },
              ],
            },
            {
              heading: "The Solutions:-",
              challengeli: [
                { First: "Utilize Child Themes: Employ child themes to customize without affecting the parent theme's functionality." },
                { First: "Resolve Plugin Conflicts: Identify and deactivate conflicting plugins for stability." },
                { First: "Optimize Performance: Streamline themes and plugins to improve website speed." },
                { First: "Frequent Updates: Regularly update WordPress, themes, and plugins for security." },
              ],
            },
            {
              heading: "Impact",
              challengeli: [{ First: `By collaboratively setting clear project milestones, maintaining open communication channels, and involving the client’s in feedback. We ensured alignment with client expectations and delivered a professional, user-friendly website for smart Fiber. The site is responsive, visually engaging, and optimised for a seamless user experience across devices, showcasing innovative fibre technologies while promoting sustainability.` },],
            },
          ],
        },

        companyinfo: {
          logourl:
            "yasmin",
          about: "About ",
          aboutpara:
            "Savekar is a leading offshore accounting firm specializing in serving US, UK, Ireland, Canada, and Australian CPA firms and Small & Medium Businesses (SMBs). We offer a comprehensive suite of services designed to streamline your accounting operations, reduce costs, and free up your valuable time to focus on core business activities.",
          companysector: [
            {
              first: "Company Name:",
              secound: "Savekar",
            },
            {
              first: "INDUSTRY:",
              secound: "Accounting/ Technology",
            },
            {
              first: "LOCATION:",
              secound: "Udaipur",
            },
          ],
        },
        customer: [

        ],
      },
    ],
  },
];

export default data;
