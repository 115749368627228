import React from "react";
// import containerVideo from "../../../../images/LiveTranscriptionExtension/LiveTranscriptionExtension.mp4";
import "./LiveTranscriptFooter.css";
import Feature1 from "../../../../images/LiveTranscriptionExtension/transcript_feature/android-send.svg";

const LiveTranscriptFooter = () => {
  return (
    <>
      <div className="container-lg transcript-footer-container">
        <div className="d-flex justify-content-around align-items-center live-footer-container">
          <div className="d-flex align-items-center ">
            <div className="live-footer-text">
              Help Us Improve:<br/> Your Feedback Matters!
            </div>
          </div>
          <div className="live-footer-img d-flex justify-content-around align-items-center ">
            <div className="live-footer-mail">Mail Us</div>
            <img className="footer-img1" src={Feature1} alt="" />
          </div>
        </div>
      </div>
    </>
  );
};

export default LiveTranscriptFooter;
