import Bannnerimage from "../../../../images/CaseStudiesImagesLogo/VoecoLogo.png";

const data = [
  {
    slugurl: "/VoecoEvWeb",
    data: [
      {
        Banner: [
          {
            sector: "Recruitment/ Technology",
            headingtext: "Voeco EV",
            headingpara:
              "Voeco Charging enhances uptime and reduces maintenance costs with AI-based preemptive maintenance and remote management, offering a secure, user-friendly experience with OCPP 2.0.1 support and compatibility with various EV charger components.",
            image: Bannnerimage,
          },
        ],
        heading:
          "Ensuring Client Satisfaction through Collaborative Project Management for Voeco EV's Website",
        casestudy: {
          Problem:
            `Create a professional, user-friendly website for Voeco EV on WordPress using a premium theme, intuitive navigation, mobile responsiveness for a pixel-perfect interface. Launch, test, and maintain for a seamless user experience.`,
          challenge: [
            {
              heading: "The Challenges:-",
              challengeli: [
                { First: "Plugin Compatibility: Integrating multiple plugins on WordPress can lead to compatibility issues, affecting the website's functionality and performance." },
                { First: "Theme Customization: Tailoring the premium theme to match Voeco EV's brand identity, including colour schemes, fonts, layouts, and logo integration, posed a design challenge." },
              ],
            },
            {
              heading: "The Solution:-",
              challengeli: [
                { First: "Plugin Prioritisation: We prioritised plugins with excellent reviews, active support, and regular updates to ensure compatibility and optimal performance. Thorough testing in a staging environment before deployment minimised risks." },
                { First: "Theme Customization: Leveraging WordPress Customizer and theme settings, we seamlessly customised the theme to reflect Voeco EV's brand identity, creating a cohesive and visually appealing interface." },
              ],
            },
            {
              heading: "Impact",
              challengeli: [{ First: `By collaboratively setting clear project milestones, maintaining open communication channels, and involving the client’s in feedback.we ensured alignment with client expectations and delivered a professional, user-friendly website for Voeco EV. The site is responsive, visually engaging, and optimised for a seamless user experience across devices, showcasing innovative EV technologies while promoting sustainability. ` },],
            },
          ],
        },

        companyinfo: {
          logourl:
            "yasmin",
          about: "About ",
          aboutpara:
            "Voeco EV optimizes uptime and cuts maintenance expenses via AI-driven preemptive maintenance and remote management. It offers secure, user-friendly experiences with OCPP 2.0.1 support..",
          companysector: [
            {
              first: "Company Name:",
              secound: "Voeco",
            },
            {
              first: "INDUSTRY:",
              secound: "ev charging/ Technology",
            },
            {
              first: "LOCATION:",
              secound: "Udaipur",
            },
          ],
        },
        customer: [

        ],
      },
    ],
  },
];

export default data;
