import React from "react";



const ServiceOurBenefits = ({ OurBenefitsData, heading, subHeading, description }) => {


    return (
        <>
            <div className="OurCulture-main container-fluid d-flex flex-column">
                <div className="OurCulture-main-container d-flex flex-column align-items-center justify-content-center container">
                    <div className="OurCommitment-text d-flex flex-column align-items-center">
                        <h4>{subHeading}</h4>
                        <h2>{heading}</h2>
                        <p className="p-0">{description}</p>
                    </div>

                    <div className="OurCulture-Freedom  d-flex justify-content-between">
                        {OurBenefitsData.map((items, key) => (
                            <div id={key}
                                key={key}
                                className="px-3 py-4 OurCulture-parent d-flex flex-column align-items-center justify-content-end">
                                <div className="p-4 pt-0" style={{}}>
                                    <img style={{ width: "100%" }} className='' src={items.image} alt={items.alt} loading="lazy"/>
                                </div>
                                <p style={{padding:"0 15%"}}>{items.para}</p>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </>
    );
};

export default ServiceOurBenefits;
