import React from "react";
import { Route, Routes, BrowserRouter, Navigate, useParams } from "react-router-dom";
import Header from "../layout/Header/header";
import Homepage from "../pages/HomePage/Homepage";
import Footer from "../layout/Footer/Footer.jsx";
import InteractCaseStudy from "../pages/CaseStudypage/Interact-casestudy";
import MeinshortCaseStudy from "../pages/CaseStudypage/Meinshort-CaseStudy";
import CommunicationCaseStudy from "../pages/CaseStudypage/Communication-Call recording/Communication-Call_recording";

import IdealeadsCaseStudy from "../pages/CaseStudypage/Idealeads-CaseStudy";
import AboutUs from "../pages/AboutUs/AboutUs";
import ProductLayout from "../pages/ProductPage/ProductLayout";
import WebApplication from "../pages/Services/WebApplication/WebApplication";
import NLPService from "../pages/Services/NLPService/NLPService";
import BrowserExtension from "../pages/Services/BrowserExtension/BrowserExtension";
import ScrollToTop from "./ScrollToTop";
import LiveTranscriptCaseStudy from "../pages/CaseStudypage/LiveTranscriptExt-CaseStudy";
import WriteGPTOpenAI from "../pages/CaseStudypage/WriteGPTOpenAI/WriteGPTOpenAI";
import city from "./ITCities.json";
import product from "./product_data.js";
import Linkpages from "../LInkPages/Linkpages";
import ContactUs from "../pages/ContactUs/ContactUs";
import CitiesFooter from "../Comman/CitiesFooter/CitiesFooter";
import VoiceAssistentCaseStudy from "../pages/CaseStudypage/voice-assistent-CaseStudy";
import InteractExtCaseStudy from "../pages/CaseStudypage/InteractExt-casestudy";
import Page404 from "../Comman/Page404/Page404";
import HireDeveloper from "../pages/HireDeveloper/HireDeveloper";
import DetectorVideoCaseStudy from "../pages/CaseStudypage/DetectorVideoCaseStudy";
import TextHighlight from "../pages/CaseStudypage/TextHighlight/TextHighlight";
import ReaExtension from "../pages/CaseStudypage/Rea_Extension/Rea_Extension";
import PreScouter_User from "../pages/CaseStudypage/PreScouter User-360/PreScouter_User";

import HireReact from "../pages/HireDeveloper/HireReact";
import HireNode from "../pages/HireDeveloper/HireNode";
import HireExtension from "../pages/HireDeveloper/HireExtension";
import CaseStudySquire from "../pages/CaseStudypage/CaseStudySquire";
import CaseStudyYoutube from "../pages/CaseStudypage/CaseStudyYoutube";
import CaseStudyGoogleDocs from "../pages/CaseStudypage/GoogleDocsDataCollector/GoogleDocsDataCollector";
import CaseStudyScreenRecorder from "../pages/CaseStudypage/ScreenRecorder/ScreenRecorder";
import Dropscale from "../pages/CaseStudypage/dropscale/Dropscale";
import ApiPages from "../pages/ApiPages/ApiPages";
import Datalix from "../pages/CaseStudypage/DatalixExtension/DatalixExtension";
import ChatGptQandA from "../pages/ApiPages/ChatGptQandA/ChatGptQandA";
import BehaviorAnalysis from "../pages/Services/ServiceOurServices/BehaviorAnalysis/BehaviorAnalysis";
import AudioToText from "../pages/Services/ServiceOurServices/AudioToText/AudioToText";
import Chatbots from "../pages/Services/ServiceOurServices/Chatbot/Chatbot";
import NoteCapture from "../pages/Services/ServiceOurServices/NoteCapture/Notes-Capture";
import AiBrowserExtension from "../pages/Services/BrowserExtension/AI/AiBrowserExtension";
import DataExtractor from "../pages/Services/BrowserExtension/Data Extractor/DataExtractor";
import ReactBasedBrowserExtension from "../pages/Services/BrowserExtension/ReactBasedBrowserExtension/ReactBasedBrowserExtension";
import SaasProduct from "../pages/Services/BrowserExtension/SaasProduct/SaasProduct";
import WebAutomation from "../pages/Services/BrowserExtension/WebAutomation/WebAutomation";
import AiWebApplication from "../pages/Services/WebApplication/AiWebApplication/AiWebApplication";
import DataVisualizer from "../pages/Services/WebApplication/DataVisualizer/DataVisualizer";
import HubSpotCMSdevelopment from "../pages/Services/WebApplication/HubSpotCMSdevelopment/HubSpotCMSdev";
import MERNstack from "../pages/Services/WebApplication/MERNstack/MERNstack";
import ZoomApp from "../pages/PluginDeveloper/ZoomApp/ZoomApp";
import HireMicrosoftTeam from "../pages/PluginDeveloper/HireMicrsoftTeam/HireMicrosoftTeam";
import SPIdeveloper from "../pages/PluginDeveloper/SPIdeveloper/SPIdeveloper";
import HubSpotMarketplaceDeveloper from "../pages/PluginDeveloper/HubspotMarketplaceDeveloper/HubspotMarketplaceDeveloper";
import GoogleAppDeveloper from "../pages/PluginDeveloper/GoogleAppPluginDevelop/GoogleAppDeveloper";
import Extension from "../pages/CaseStudypage/CaseStudyCarousel/Extension";
import NotesCapture from "../pages/ApiPages/ChatGptQandA/NotesCapture";


import OutingPage from "../pages/AboutUs/teams-group/OutingPage";

import Privacy from "../../Privacy";
import MainPage from "../pages/Podspiria/MainPage";
import LiveTranscript from "../pages/LiveTranscript/LiveTranscript";
import NewHomePage from "../pages/newHomePage/newHome";

// Live Transcript Extension
import ChromeExtensionDevlopment from "../pages/chrome-extension-development/chrome-extension-development";
import SaasNewPage from "../pages/Services/SaasProductPage/saasProduct";
import AiMlNewPage from "../pages/Services/SaasProductPage/AiMlNewPage";
import CloudNewPage from "../pages/Services/SaasProductPage/CloudNewPage";
import FloomyCaseStudy from "../pages/CaseStudypage/FloomyCaseStudy/FloomyCaseStudy";
import GnoteCaseStudy from "../pages/CaseStudypage/GnoteCaseStudy/GnoteCaseStudy";
import AcuityCaseStudy from "../pages/CaseStudypage/AcuityCaseStudy/AcuityCaseStudy";
import SquireRingCentralMarketplace from "../pages/CaseStudypage/SquireRingCentralMarketplace/SquireRingCentralMarketplace";
import SquireZoomMarketplace from "../pages/CaseStudypage/SquireZoomMarketplace/SquireZoomMarketplace";
import BCGCaseStudy from "../pages/CaseStudypage/BCG_CaseStudy/BCG_CaseStudy";
import LinkedinSalesNavigator from "../pages/CaseStudypage/LinkedinSalesNavigator/LinkedinSalesNavigator";
import AutomateLinkedinReachout from "../pages/CaseStudypage/AutomateLinkedinReachout/AutomateLinkedinReachout";
import AIMLcaseStudyPage from "../pages/CaseStudypage/AIMLcaseStudyPage/AIMLcaseStudyPage.jsx";
import ExtensionAddonCaseStudyPage from "../pages/CaseStudypage/AIMLcaseStudyPage/ExtensionAddonCaseStudyPage.jsx";
import WebDevCaseStudyPage from "../pages/CaseStudypage/AIMLcaseStudyPage/WebDevCaseStudyPage.jsx";
import WebAutoCaseStudyPage from "../pages/CaseStudypage/AIMLcaseStudyPage/WebAutoCaseStudyPage.jsx";
import OutlookAddon from "../pages/CaseStudypage/OutlookAddon/OutlookAddon.jsx";
import DaisyZendeskExtension from "../pages/CaseStudypage/DaisyZendeskExtension/DaisyZendeskExtension.jsx";
import AlpineAIExtension from "../pages/CaseStudypage/AlpineAIExtension/AlpineAIExtension.jsx";
import NoteTakingExtension from "../pages/CaseStudypage/NoteTakingExtension/NoteTakingExtension.jsx";
import VoecoEvWeb from "../pages/CaseStudypage/VoecoEvWeb/VoecoEvWeb.jsx";
import SmartFiberWeb from "../pages/CaseStudypage/SmartFiberWeb/SmartFiberWeb.jsx";
import SavekarWeb from "../pages/CaseStudypage/SavekarWeb/SavekarWeb.jsx";
import FloomyWeb from "../pages/CaseStudypage/FloomyWeb/FloomyWeb.jsx";
import Seven3BitWeb from "../pages/CaseStudypage/Seven3BitWeb/Seven3BitWeb.jsx";
import Empathy from "../pages/CaseStudypage/Empathy/Empathy.jsx";
import ChatGPT_QA from "../pages/CaseStudypage/ChatGPT_QA/ChatGPT_QA.jsx";
import ClozureExtension from "../pages/CaseStudypage/ClozureExtension/ClozureExtension.jsx";


const caseStudyComponents = {

  // single page Route
  '/': NewHomePage,
  'about-us': AboutUs,
  'Outing': OutingPage,
  'chrome-extension-plugin-development-company': ChromeExtensionDevlopment,
  'live-transcript-extension': LiveTranscript,
  'youtube-spotify-podcast-transcript': MainPage,
  'privacy-policies': Privacy,

  'OldHome': Homepage,
  'hire-developer': HireDeveloper,

  // caseStudies Route 
  'casestudy': Extension,
  'casestudy/ai-ml-development': AIMLcaseStudyPage,
  'casestudy/extension-addon-development': ExtensionAddonCaseStudyPage,
  'casestudy/web-development': WebDevCaseStudyPage,
  'casestudy/web-automation': WebAutoCaseStudyPage,

  'casestudy/chatGPT-qa': ChatGPT_QA,
  'casestudy/clozure-extension': ClozureExtension,
  'casestudy/interact': InteractCaseStudy,
  'casestudy/idealeads': IdealeadsCaseStudy,
  'casestudy/meinshort': MeinshortCaseStudy,
  'casestudy/communication': CommunicationCaseStudy,
  'casestudy/empathy': Empathy,
  'casestudy/capture-transcript-from-a-meet': LiveTranscriptCaseStudy,
  'casestudy/browser-control-through-voice-commands': VoiceAssistentCaseStudy,
  'casestudy/interact-extension': InteractExtCaseStudy,
  'casestudy/video-downloader-by-detector-video': DetectorVideoCaseStudy,
  'casestudy/text-highlight-extention': TextHighlight,
  'casestudy/rea-extention': ReaExtension,
  'casestudy/OutlookAddon': OutlookAddon,
  'casestudy/DaisyZendeskExtension': DaisyZendeskExtension,
  'casestudy/AlpineAIExtension': AlpineAIExtension,
  'casestudy/NoteTakingExtension': NoteTakingExtension,
  'casestudy/VoecoEvWeb': VoecoEvWeb,
  'casestudy/SmartFiberWeb': SmartFiberWeb,
  'casestudy/SavekarWeb': SavekarWeb,
  'casestudy/FloomyWeb': FloomyWeb,
  'casestudy/Seven3BitWeb': Seven3BitWeb,
  'casestudy/PreScouter-User-360': PreScouter_User,
  'casestudy/squire': CaseStudySquire,
  'casestudy/Youtube-Extra-Ad-Chrome-Extension': CaseStudyYoutube,
  'casestudy/Write-GPT-OpenAI': WriteGPTOpenAI,
  'casestudy/Google-Docs-Data-Collector': CaseStudyGoogleDocs,
  'casestudy/screen_rec_ext': CaseStudyScreenRecorder,
  'casestudy/dropscale': Dropscale,
  'casestudy/datalix': Datalix,
  'casestudy/floomy': FloomyCaseStudy,
  'casestudy/Gnote': GnoteCaseStudy,
  'casestudy/Acuity': AcuityCaseStudy,
  'casestudy/SquireRingCentral': SquireRingCentralMarketplace,
  'casestudy/SquireZoom': SquireZoomMarketplace,
  'casestudy/BCG_CaseStudy': BCGCaseStudy,
  'casestudy/LinkedinSalesNavigator': LinkedinSalesNavigator,
  'casestudy/LinkedinReachout': AutomateLinkedinReachout,

  // Routes for services pages 
  'saas-product-service-provider-company': SaasNewPage,
  'ai-ml-Development-service-provider-company': AiMlNewPage,
  'Cloud-Deployment-service-provider-company': CloudNewPage,

  // Browser Extension 6 pages
  'chrome-browser-extension-development-company': BrowserExtension,
  'chrome-browser-extension-development-company/Ai-Browser-extenstion': AiBrowserExtension,
  'chrome-browser-extension-development-company/browser-extension-saas-product': SaasProduct,
  'chrome-browser-extension-development-company/react-browser-extension': ReactBasedBrowserExtension,
  'chrome-browser-extension-development-company/web-automation': WebAutomation,
  'chrome-browser-extension-development-company/data-extractor': DataExtractor,

  // web development 5 pages
  'web-application-development-company': WebApplication,
  'web-application-development-company/web-based-saas-product': SaasProduct,
  'web-application-development-company/ai-web-application': AiWebApplication,
  'web-application-development-company/mern-stack-web-development': MERNstack,
  'web-application-development-company/web-data-visualizer': DataVisualizer,
  'web-application-development-company/hubspot-cms-development': HubSpotCMSdevelopment,

  'api/empathy': ApiPages,
  'api/ChatGptQandA': ChatGptQandA,
  'api/NotesCapture': NotesCapture,

  // Hire developer routes
  'hire-developer/hire-react-developer': HireReact,
  'hire-developer/hire-node-developer': HireNode,
  'hire-developer/hire-browser-extension-developer': HireExtension,

  'AllPages': Linkpages,

  // plugin developer
  'plugin-developer/zoom-app-plugin-developer': ZoomApp,
  'plugin-developer/microsoft-teams-app-plugin-developer': HireMicrosoftTeam,
  'plugin-developer/amazon-seller-platform-application-spi-developer': SPIdeveloper,
  'plugin-developer/hubspot-marketplace-app-developer': HubSpotMarketplaceDeveloper,
  'plugin-developer/google-app-plugin-developer': GoogleAppDeveloper,

  // NLP sub 4 pages 
  'nlp-service-provider-company': NLPService,
  'nlp-service-provider-company/Behavior-Analysis': BehaviorAnalysis,
  'nlp-service-provider-company/audio-to-text': AudioToText,
  'nlp-service-provider-company/chatbots': Chatbots,
  'nlp-service-provider-company/notes-capture': NoteCapture,

};

const caseStudyRoutes = Object.keys(caseStudyComponents).map(key => ({
  path: key,
  component: caseStudyComponents[key]
}));

const AppRoutes = () => (

  <BrowserRouter>
    <ScrollToTop />
    <ContactUs />
    <Header />
    <Routes>

      {caseStudyRoutes.map(({ path, component: Component }) => (
        <Route key={path} path={`/${path}/`} element={<Component />} exact />
      ))}

      {city.map((cities, index) => (
        <Route
          path={`/nlp-service-provider-company-in-${cities.Cities}/`}
          key={index}
          element={<NLPService city={cities.Cities} />}
          exact
        />
      ))}
      {city.map((cities, index) => (
        <Route
          path={`/browser-extension-development-company-in-${cities.Cities}/`}
          key={index}
          element={<BrowserExtension city={cities.Cities} />}
          exact
        />
      ))}
      {city.map((cities, index) => (
        <Route
          path={`/web-application-development-company-in-${cities.Cities}/`}
          key={index}
          element={<WebApplication city={cities.Cities} />}
          exact
        />
      ))}

      {/* Routes for All Cities */}
      <Route
        path="/web-cities/"
        element={
          <Linkpages
            path="/web-application-development-company-in"
            title="Web Application"
          />
        }
        exact
      />
      <Route
        path="/browser-cities/"
        element={
          <Linkpages
            path="/browser-extension-development-company-in"
            title="Browser Extension"
          />
        }
        exact
      />
      <Route
        path="/nlp-cities/"
        element={
          <Linkpages
            path="/nlp-service-provider-company-in"
            title="Natural Language Processing Services"
          />
        }
        exact
      />

      {product.map((product) => (
        <Route
          path={`/product-pages/${product.localURL}/`}
          element={<ProductLayout data={product} />}
          exact
        />
      ))}

      <Route path="*" element={<Page404 />} />

    </Routes>
    <Footer />

    <CitiesFooter />
  </BrowserRouter>
);


export default AppRoutes;
