import React, { useState, useEffect } from "react";
import "./ApiPages.css";
import { SuperSEO } from "react-super-seo";
import Positive from "../../../images/Apis/positive.png";
import Negative from "../../../images/Apis/negative.png";
import axios from "axios";
import ReCAPTCHA from "react-google-recaptcha";
const ApiPages = () => {
  const [box, setBox] = useState(1);
  const [text, setText] = useState(false);
  const [textValue, setTextValue] = useState("");
  const [isEmpathy, setIsEmpathy] = useState(null);
  // const [active, setActive] = useState(false);

  function onChange(value) {
    console.log("SAS", value);
    document.querySelector("#analyzeBtn").style.display = "block";
  }

  const textSubmit = (e) => {
    if (e.target.value === "custome") {
      setText(true);
      setTextValue(e.target.value);
    } else {
      setText(false);
      setTextValue(e.target.selectedOptions[0].text);
    }
  };

  const textSubmit2 = (e) => {
    setTextValue(e.target.value);
  };
  // function hideLoading() {
  //   loader.classList.remove("display");
  // }
  const submit = async () => {
    document.querySelector("#loader_empthy").classList.remove("d-none");
    try {
      console.log(textValue);
      await axios
        .post("https://asia-south1-lakerains-websites.cloudfunctions.net/app/google/get", {
          q: textValue,
        })
        .then((response) => {
          document.querySelector("#loader_empthy").classList.add("d-none");
          console.log("response =>", response);
          response.data.empathy === "Empathy"
            ? setIsEmpathy(true)
            : response.data.empathy === "Non Empathy"
            ? setIsEmpathy(false)
            : setIsEmpathy(null);
        });
    } catch (error) {
      console.error(error);
      console.log("error");
    }
  };

  const changeEmapthy = (box) => {
    setBox(box);
    setIsEmpathy(null);
  };

  return (
    <>
     <SuperSEO
        title="API Pages | React Application"
        description=" Explore our API Pages to try our free demo and analyze text for empathy. Choose from different options or enter custom text to evaluate empathy levels. Get insights and improve customer service, sales, and psychiatric interactions. Start using our APIs today!"
        lang="en"
        twitter={{
          twitterSummaryCard: {
            summaryCardImage: "https://www.youtube.com/watch?v=wZ02FUjCYHw&t=3s&ab_channel=LakeBrainsTechnologies",
            summaryCardImageAlt: "video",
            summaryCardSiteUsername: "justinmahar",
          },
        }}
      />
        <div className="background-img" style={{ margin: "100px 0px" }}>
      {/* <h1 className="text-center title pt-3">XYZ</h1>
      <div className="row contentRow">
        <p className="col-md-7 para text-center ml-5">
          Lorem ipsum dolor sit amet consectetur adipisicing elit. Impedit
          excepturi sequi earum autem vitae animi rerum est sint facilis cumque
          maiores quidem tempore quam, illum officia pariatur perferendis totam
          quas provident ut aliquam eaque. Lorem ipsum dolor sit amet
          consectetur adipisicing elit. Doloremque explicabo quod nihil, omnis
          maxime consectetur officia. Eum odio velit, qui alias perferendis,
          nulla perspiciatis sapiente, impedit unde officiis id pariatur natus
          expedita quos. Recusandae praesentium, nemo minima ea ullam rerum
          illum. Voluptatem, quos nesciunt. Adipisci deserunt cumque optio
          voluptatum minus.
        </p>
        <img className="col-md-4 ml-3" src="img.avif" alt="" srcset="" />
      </div> */}

      {/* <div className="text-center">
        <button
          type="button"
          className="btn btn-outline-primary btn1 mt-2 bg-primary"
        >
          Start Free
        </button>
      </div> */}

      <div id="#loading" className="container">
        <h3 className="heading">Try The Demo</h3>
        <p className="subHeading">
          Try our free demo now by typing a sentence or choose from the options
          in the drop-down menu
        </p>
        <button
          id="btn1"
          onClick={() => {
            changeEmapthy(1);
          }}
          type="button"
          className={
            box === 1
              ? "btn1-color btn btn-outline-primary-toggle m-2 empathy-img"
              : "btn1-color btn btn-outline-primary m-2 empathy-img"
          }>
          Customer Service
        </button>
        <button
          id="btn2"
          onClick={() => {
            changeEmapthy(2);
          }}
          type="button"
          className={
            box === 2
              ? "btn1-color btn btn-outline-primary-toggle m-2 empathy-img"
              : "btn1-color btn btn-outline-primary m-2 empathy-img"
          }>
          Sales
        </button>
        <button
          id="btn3"
          onClick={() => {
            changeEmapthy(3);
          }}
          type="button"
          className={
            box === 3
              ? "btn1-color btn btn-outline-primary-toggle m-2 empathy-img"
              : "btn1-color btn btn-outline-primary m-2 empathy-img"
          }>
          Psychiatric
        </button>
        <div className="row mt-3">
          <div id="box1" className="col-12 col-md-9 mb-3">
            {box === 1 ? (
              <select
                onChange={(e) => textSubmit(e)}
                id="mySelect1"
                className="input w-75 p-2">
                <option hidden selected className="choice">
                  select option
                </option>
                <option value="0" className="choice">
                  I am sorry you are having this problems
                </option>
                <option value="1" className="choice">
                  That would frustrated me too
                </option>
                <option value="2" className="choice">
                  I will help you get this issue resolved
                </option>
                <option value="3" className="choice">
                  I realise how upsetting this must be
                </option>
                <option value="0" className="choice">
                  This problem is not from our side
                </option>
                <option value="0" className="choice">
                  That's not our fault, check from your side, we can't help you
                </option>
                <option value="0" className="choice">
                  I can't figure out how to edit an email address
                </option>
                <option value="0" className="choice">
                  I can't help you
                </option>
                <option
                  value="custome"
                  style={{
                    color: "#777",
                    fontSize: "12px",
                    fontStyle: "italic",
                  }}>
                  + Check demo on your text
                </option>
              </select>
            ) : box === 2 ? (
              <select
                onChange={(e) => textSubmit(e)}
                id="mySelect1"
                className="input w-75 p-2">
                <option hidden selected className="choice">
                  select option
                </option>
                <option value="0" className="choice">
                  That would be frustrated me too
                </option>
                <option value="1" className="choice">
                  Thank you for bringing this to our notice. I appreciate your
                  effort
                </option>
                <option value="2" className="choice">
                  I have experienced a similar problem recently, so I understand
                  what you are saying. Let me see what I can do to help you out.
                </option>
                <option value="3" className="choice">
                  I understand your frustration, and I wish I could make it
                  better for you
                </option>
                <option value="0" className="choice">
                  Could you direct me to the right point of contact?
                </option>
                <option value="0" className="choice">
                  Do you have budget for this?
                </option>
                <option value="0" className="choice">
                  It's really easy to understand.
                </option>
                <option value="0" className="choice">
                  So, you're not interested in our services.
                </option>
                <option
                  value="custome"
                  style={{
                    color: "#777",
                    fontSize: "12px",
                    fontStyle: "italic",
                  }}>
                  + Check demo on your text
                </option>
              </select>
            ) : (
              <select
                onChange={(e) => textSubmit(e)}
                id="mySelect1"
                className="input w-75 p-2">
                <option hidden selected className="choice">
                  select option
                </option>
                <option value="0" className="choice">
                  I always try to understand what patients and families are
                  going through
                </option>
                <option value="1" className="choice">
                  My heart hurts for you and you can really count on me
                </option>
                <option value="2" className="choice">
                  I really wish I can be of any help to you
                </option>
                <option value="3" className="choice">
                  I can understand what you are going through
                </option>
                <option value="0" className="choice">
                  I don't know what's wrong with you
                </option>
                <option value="0" className="choice">
                  You can’t, Your making that sacrifice
                </option>
                <option value="0" className="choice">
                  No. Why should you be scared to seek mental help, depression
                  if not treated can kill
                </option>
                <option value="0" className="choice">
                  Poor you, that's awful
                </option>
                <option
                  value="custome"
                  style={{
                    color: "#777",
                    fontSize: "12px",
                    fontStyle: "italic",
                  }}>
                  + Check demo on your text
                </option>
              </select>
            )}
            {text ? (
              <div>
                <div style={{ margin: "10px 0px" }}>Write your custom text</div>
                <input
                  type="text"
                  name="custome"
                  onChange={(e) => textSubmit2(e)}
                  className="input w-75 p-2"
                />
              </div>
            ) : null}
          </div>
          <ReCAPTCHA
            sitekey="6LeSJQslAAAAANaj5ZW41ZjEzw_jomKjYHZbuhg6"
            onChange={onChange}
          />
          <div className="col-12 col-md-3">
            <button
              onClick={() => {
                submit();
              }}
              id="analyzeBtn"
              type="button"
              className="btn btn-outline-primary">
              Analyze
            </button>
          </div>
        </div>

        <div className="row emojiRow mt-3">
          <div className="col-sm-2 text-center">
            <img
              className={
                isEmpathy === true
                  ? "emoji-show"
                  : isEmpathy === false
                  ? "emoji"
                  : "emoji"
              }
              src={Positive}
            />
            <p>Empathy</p>
          </div>
          <div className="col-sm-2 text-center">
            <img
              className={
                isEmpathy === true
                  ? "emoji"
                  : isEmpathy === false
                  ? "emoji-show"
                  : "emoji"
              }
              src={Negative}
            />
            <p>Not Empathy</p>
          </div>
        </div>

        <div className="footer mt-4">
          <p className="footerText">
            <span> You might also be interested in these APIs:</span>
            <a className="footerLink" href="">
              {" "}
              Emotion Analysis
            </a>
            ,
            <a className="footerLink" href="">
              {" "}
              Intent Analysis
            </a>
            ,
            <a className="footerLink" href="">
              {" "}
              Named Entity Recognition
            </a>
          </p>
        </div>
      </div>
      <>
        <div className="d-none" id="loader_empthy">
          <div className="wrapper">
            <div className="loader">
              <div className="dot"></div>
            </div>
            <div className="loader">
              <div className="dot"></div>
            </div>
            <div className="loader">
              <div className="dot"></div>
            </div>
            <div className="loader">
              <div className="dot"></div>
            </div>
            <div className="loader">
              <div className="dot"></div>
            </div>
            <div className="loader">
              <div className="dot"></div>
            </div>
          </div>
          <div className="text">Please wait</div>
        </div>
      </>
    </div>
    </>

  );
};

export default ApiPages;
