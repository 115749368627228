import React, { useEffect } from "react";

import AOS from "aos";

AOS.init();

const Carriers = () => {
    useEffect(() => {
        AOS.init();
    }, []);
};

const ServiceUseCases = ({ UseCases, heading, subHeading, description }) => {
    return (
        <>



            <section className="usecases-section-saas d-flex justify-content-center">
                <div className="usecases-section-saas-main-div container p-0">
                    <div className="OurCommitment-text d-flex text-center flex-column align-items-center" style={{ marginBottom: "65px" }}>
                        <h4>{subHeading}</h4>
                        <h2>{heading}</h2>
                        <p>{description}</p>
                    </div>
                    <div className=" justify-content-center saas-useCase-card">
                        {UseCases.map((item, index) => (
                            <div
                                className="saas-useCase-card-div" key={index}
                            >
                                <div className="saas-useCase-card-div-img">
                                    <img src={item.img} alt="vector_saas_2" style={{backgroundColor:"white", borderRadius:"50%"}} loading="lazy"/>
                                </div>
                                <div>
                                    <p className="saas-useCase-card-para">{item.title}</p>
                                    <p>
                                        {item.description}
                                    </p>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </section>

        </>
    );
};

export default ServiceUseCases;
