import Bannnerimage from "../../../../images/CaseStudiesImagesLogo/Bitlogo.png";


const data = [
  {
    slugurl: "/Seven3BitWeb",
    data: [
      {
        Banner: [
          {
            sector: "Recruitment/ Technology",
            headingtext: "73bit",
            headingpara:
              "Innovating technology solutions for your business success and growth.",
            image: Bannnerimage,
          },
        ],
        heading:
          "Transforming Ideas into Reality with Cutting-Edge Technology Solutions",
        casestudy: {
          Problem:
            `At 73bit, we specialise in turning your innovative ideas into tangible reality through advanced technology solutions. From software development to AI integration and cybersecurity, we provide comprehensive services to propel your business forward in the digital age. Partner with us for unparalleled innovation and success.`,
          challenge: [
            {
              heading: "The Challenges:-",
              challengeli: [
                { First: "Scalability for Growing Demands." },
                { First: "Data Security and Privacy Compliance." },
                { First: "User Interface Complexity Handling." },
                { First: "Performance Optimization for Efficiency." },
              ],
            },
            {
              heading: "The Solutions:",
              challengeli: [
                { First: " Implement cloud-based architecture for scalability." },
                { First: "Use encryption and comply with regulations." },
                { First: "Design intuitive UI/UX for user-friendliness." },
                { First: "Employ code profiling and optimization techniques." },
              ],
            },
            {
              heading: "Impact",
              challengeli: [{ First: `Partnering with 73bit has a profound impact on businesses, transforming their digital landscape and operational efficiency. Our scalable solutions not only accommodate growing demands but also leverage cloud technologies for flexible expansion, ensuring seamless scalability as your business evolves. By prioritising data security and privacy compliance, we provide peace of mind to businesses and their customers, establishing trust and credibility in an increasingly digital world.` },],
            },
          ],
        },

        companyinfo: {
          logourl:
            "yasmin",
          about: "About ",
          aboutpara:
            "73bit pioneers technology solutions, fueling business success and growth with innovative strategies tailored to meet evolving industry demands..",
          companysector: [
            {
              first: "Company Name:",
              secound: "73bit",
            },
            {
              first: "INDUSTRY:",
              secound: "Recruitment/ Technology",
            },
            {
              first: "LOCATION:",
              secound: "Udaipur",
            },
          ],
        },
        customer: [

        ],
      },
    ],
  },
];

export default data;
