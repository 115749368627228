import React from "react";
import { Helmet } from "react-helmet";
import Casebanner from "./CaseBanner/Casebanner";
import data from "./CaseStudySquireData";
import squire from "../../../images/casestudy/squireimg.png"
import { SuperSEO } from "react-super-seo";

const CaseStudySquire = () => {
    return (
        <>
            <SuperSEO
                title="Squire - Lakebrains"
                description="Squire’s conversational intelligence equips recruiters to place more candidates. By capturing every call your recruiters make, and analysing and extracting the right information, recruitment leaders know that the data going into their ATS is as accurate as possible and that their consultants are having the right conversations."
                lang="en"
                twitter={{
                    twitterSummaryCard: {
                        summaryCardImage: { squire },
                        summaryCardImageAlt: "Squire",
                        summaryCardSiteUsername: "Squire",
                    },
                }}
            />


            {data.map((items, key) => (
                <Casebanner key={key} data={items.data} />
            ))}
        </>
    );
};

export default CaseStudySquire;
