import React from "react";
import "./saasProductPage.css";


import gather from "../../../../images/chrome-extension-develpment/gather-saas.svg";
import design from "../../../../images/chrome-extension-develpment/design-saas.svg";
import test from "../../../../images/chrome-extension-develpment/test-saas.svg";

import TechStackSaas from "./techStackSaas";
import ServiceOurBenefits from "../../../Comman/ServiceComponents/SaasProductService/ServiceOurBenefits";
import ServiceFaq from "../../../Comman/ServiceComponents/SaasProductService/ServiceFaq";
import ServiceIndustries from "../../../Comman/ServiceComponents/SaasProductService/ServiceIndustries";
import Services from "../../../Comman/ServiceComponents/SaasProductService/Services";
// This is Frontend images ;
import next from "../../../../images/OurtechStack/next.svg";
import react from "../../../../images/OurtechStack/react.webp";
import express from "../../../../images/OurtechStack/express.svg";
import html from "../../../../images/OurtechStack/html5.svg";
import css from "../../../../images/OurtechStack/css3.svg";
import bootstrap from "../../../../images/OurtechStack/bootstrap.svg";
import vue from "../../../../images/OurtechStack/vue.svg";
import javascript from "../../../../images/OurtechStack/javascript.svg";
import jquery from "../../../../images/OurtechStack/jquery.svg";
import postgresql from "../../../../images/OurtechStack/postgresql.svg";
import tailwind from "../../../../images/OurtechStack/tailwind-css.svg";
import typescript from "../../../../images/OurtechStack/typescript.svg";
import angular from "../../../../images/OurtechStack/angular.svg";
import redux from "../../../../images/OurtechStack/redux.svg";
import native from "../../../../images/OurtechStack/native.svg";
import htmx from "../../../../images/OurtechStack/htmx.png";

// This is  backend images

import mysql from "../../../../images/OurtechStack/mysql.svg";
import mongodb from "../../../../images/OurtechStack/mongodb.svg";
import nest from "../../../../images/OurtechStack/nest.svg";
import api from "../../../../images/OurtechStack/api.webp";
import django from "../../../../images/OurtechStack/django.svg";
import flask from "../../../../images/OurtechStack/flask.png";
import nodejs from "../../../../images/OurtechStack/nodejs.svg";
import sail from "../../../../images/OurtechStack/sails.webp";

import Personalization from "../../../../images/chrome-extension-develpment/Personalization.svg";
import Automation from "../../../../images/chrome-extension-develpment/Automation.svg";
import PredictiveAnalytics from "../../../../images/chrome-extension-develpment/PredictiveAnalytics.svg";
import ProductSearch from "../../../../images/chrome-extension-develpment/ProductSearch.svg";
import ReleaseManager from "../../../../images/chrome-extension-develpment/ReleaseManager.svg";
import EnhancedSecurity from "../../../../images/chrome-extension-develpment/EnhancedSecurity.svg";

import ServiceUseCases from "../../../Comman/ServiceComponents/SaasProductService/ServiceUseCases";
import ServiceExecution from "../../../Comman/ServiceComponents/SaasProductService/ServiceExecution";
import ServiceBanner from "../../../Comman/ServiceComponents/SaasProductService/ServiceBanner";


import SubjectMatterExperts from "../../../../images/chrome-extension-develpment/SubjectMatterExperts.png";
import AutomatedTestProcess from "../../../../images/chrome-extension-develpment/AutomatedTestProcess.png";
import IntegratedServices from "../../../../images/chrome-extension-develpment/IntegratedServices.png";
import Support24 from "../../../../images/chrome-extension-develpment/Support24.png";
import ProblemSolving from "../../../Comman/NewHomeComponent/ProblemSolving/ProblemSolving";
import HomeImage from "../../../../images/chrome-extension-develpment/home.svg";

//plugin logos
import gmailaddon from "../../../../images/OurtechStack/gmailaddon.png";
import gcalendar from "../../../../images/OurtechStack/gcalendar.svg";
import googlemeet from "../../../../images/OurtechStack/googlemeet.svg";
import chrome from "../../../../images/OurtechStack/chrome.svg";
import ringcentral from "../../../../images/OurtechStack/ringcentral.svg";
import zoom from "../../../../images/OurtechStack/zoom.webp";
import Web from "../../../../images/OurtechStack/Web.svg";
import Mobile from "../../../../images/OurtechStack/Mobile.svg";
import Plugin from "../../../../images/OurtechStack/Plugin.svg";
import safari from "../../../../images/OurtechStack/safari.svg";
import googleai from "../../../../images/OurtechStack/googleai/googleai.svg";
import openai from "../../../../images/OurtechStack/openai.svg";
import monday from "../../../../images/OurtechStack/monday.svg";
import jira from "../../../../images/OurtechStack/jira.svg";
import hubspot from "../../../../images/OurtechStack/hubspot.png";
import teams from "../../../../images/OurtechStack/teams.svg";
import salesforce from "../../../../images/OurtechStack/salesforce.svg";
import webex from "../../../../images/OurtechStack/webex.png";

//OurExpert

import LiveTranscript from "../../../../images/SubPages/OurTestimonials/LiveTranscript.png";
import Squire from "../../../../images/SubPages/OurTestimonials/Squire.png"
import Floomy from "../../../../images/SubPages/OurTestimonials/Floomy.png"
import Interact from "../../../../images/SubPages/OurTestimonials/Interact.png"
import Gnote from "../../../../images/SubPages/OurTestimonials/Gnote.png"
import Datalinx from "../../../../images/SubPages/OurTestimonials/Datalinx.png";
import Dropscale from "../../../../images/SubPages/OurTestimonials/Dropscale.png";
import Idealeads from "../../../../images/SubPages/OurTestimonials/Idealeads.png"


// industries data
import Vector3 from "../../../../images/chrome-extension-develpment/vector3.svg";
import Vector7 from "../../../../images/chrome-extension-develpment/vector7.svg";
import E_commerce from "../../../../images/chrome-extension-develpment/e-commerce.png";
import saas from "../../../../images/chrome-extension-develpment/saas.png";
import mining from "../../../../images/chrome-extension-develpment/mining.png";
import recruitment from "../../../../images/chrome-extension-develpment/recruitment.png";



const industryData = [
  {
    img: Vector3,
    title: "Health Care ",
  },
  {
    img: Vector7,
    title: "Finance",
  },
  {
    img: E_commerce,
    title: "E-commerce",
  },
  {
    img: saas,
    title: "SaaS",
  },
  {
    img: mining,
    title: "Mining",
  },
  {
    img: recruitment,
    title: "Recruitmemt",
  },
];

const execution = [
  {
    id: "1",
    img: gather,
    title: "Discovery and definition",
  },
  {
    id: "2",
    img: design,
    title: "Design and implementation",
  },
  {
    id: "3",
    img: test,
    title: "Launch and test",
  }
];

const UseCases = [
  {
    img: Personalization,
    title: "Personalization",
    description: "SaaS personalization is a set of tactics that marketing, customer success, product, and sales teams use to make a user's journey feel personalized. The goal is to make customers and users feel valued and recognized.",
  },
  {
    img: Automation,
    title: "Automation",
    description: "SaaS automation is the use of a SaaS service to automate manual processes. It can help teams spend their time more efficiently by automating repetitive tasks. This frees up time that can be invested in scaling the business.",
  },
  {
    img: PredictiveAnalytics,
    title: "Predictive Analytics",
    description: "Predictive analytics for SaaS is a business analytics tool that uses historical data and trends to estimate future trends. It can be added to any dashboard, but is most useful for general business analytics.",
  },
  {
    img: ProductSearch,
    title: "Product Search",
    description: "Search in SaaS is generally a cloud offering that provides ready-to-use search for specific online scenarios, such as ecommerce or customer support. SaaS search tools are built into existing business applications, such as SharePoint, Salesforce, and Box.com.",
  },
  {
    img: ReleaseManager,
    title: "Release Manager",
    description: "SaaS release manager coordinates and manages code deployments for cloud-based software applications. They work with cross-functional teams, including development, operations, and solutions management, to ensure smooth deployments.",
  },
  {
    img: EnhancedSecurity,
    title: "Enhanced Security",
    description: "SaaS security is the protection of data and applications hosted by a SaaS provider. SaaS environments are a target for cybercriminals because they store a large amount of sensitive data.",
  },
];

const OurBenefitsData = [{
  para: "Subject matter experts",
  alt: "SubjectMatterExperts",
  image:
    SubjectMatterExperts,
},
{
  para: "Automated test process",
  alt: "AutomatedTestProcess",
  image:
    AutomatedTestProcess,
},
{
  para: "Integrated services",
  alt: "IntegratedServices",
  image:
    IntegratedServices,
},
{
  para: "24 hrs support",
  alt: "Support24",
  image:
    Support24,
},
]

const ProblemSolvingData = [
  {
    subHeading: "Recruitment/ HR Technology",
    heading: "Squire Chrome Extension",
    photo: Squire,
    description:
      "Squire's Chrome Extension lets you easily capture conversations with candidates and push the recording to the Squire application for processing, automatically updating your ATS with the relevant candidate information and an AI-generated recruiter write-up.",
    btntext: "View More",
    caseurl: "/casestudy/squire/",
  },
  {
    subHeading: "Productivity/ Technology",
    heading: "GNote",
    photo: Gnote,
    description:
      "G-note is an AI-powered note-capturing app that frees you from tedious note taking.",
    btntext: "View More",
    caseurl: "/casestudy/Gnote/",
  },
  {
    subHeading: "Productivity/ Technology",
    heading: "Datalix",
    photo: Datalinx,
    description:
      "Datalix Chrome Extension helps users to find Ecommerce Companies and Contact Information.",
    btntext: "View More",
    caseurl: "/casestudy/browser-control-through-voice-commands/",
  },
  {
    subHeading: "CRM & Communication Technology",
    heading: "Interact Hubspot Website",
    photo: Interact,
    description:
      "Empower your sales team to build stronger relationships and close more deals with our super-charged AI Chrome extension for sales reps and robust web app for sales managers.",
    btntext: "View More",
    caseurl: "/casestudy/interact/",
  },
  {
    subHeading: "Productivity/ Technology",
    heading: "Floomy",
    photo: Floomy,
    description:
      "AI Meeting Assistant Tool to Generate Minutes Of Meeting for Google Meet.",
    btntext: "View More",
    caseurl: "/casestudy/floomy/",
  },
  {
    subHeading: "Productivity/ Technology",
    heading: "Dropscale",
    photo: Dropscale,
    description:
      "Sharing aliexpress product detail with dropscale to manage them at one place",
    btntext: "View More",
    caseurl: "/casestudy/dropscale/",
  },
  {
    subHeading: "Learning/Communication",
    heading: "Live Transcript Extension",
    photo: LiveTranscript,
    description:
      "You could want to record lectures from online meetings, online courses, podcasts, and streaming YouTube videos by our Live Transcript Extension",
    btntext: "View More",
    caseurl: "/casestudy/capture-transcript-from-a-meet/",
  },
  {
    subHeading: "Productivity/ Technology",
    heading: "Idealeads",
    photo: Idealeads,
    description:
      "Quality contacts are greatest asset for any business. Get quality B2B lead database which includes CXO, Owner, Director, Partner, VP & Managers of 150+ sectors.",
    btntext: "View More",
    caseurl: "/casestudy/idealeads/",
  },

];
const data1 = [
  {
    name: "Frontend",
    info: [
      {
        logo: next,
        name: "Next.js",
        alt: "Next.js",
        link: "https://nextjs.org/"
      },
      {
        logo: react,
        name: "React.js",
        alt: "React.js",
        link: "https://reactjs.org/",
      },
      {
        logo: javascript,
        name: "JavaScript",
        alt: "Javascript",
        link: "https://developer.mozilla.org/en-US/docs/Web/JavaScript",
      },
      {
        logo: jquery,
        name: "Jquery",
        alt: "Jquery",
        link: "https://jquery.com/",
      },
      {
        logo: html,
        name: "HTML5",
        alt: "HTML5",
        link: "https://en.wikipedia.org/wiki/HTML",
      },
      {
        logo: css,
        name: "CSS3",
        alt: "Css3",
        link: "https://en.wikipedia.org/wiki/CSS",
      },
      {
        logo: bootstrap,
        name: "Bootstrap",
        alt: "Bootstrap",
        link: "https://getbootstrap.com/",
      },
      {
        logo: tailwind,
        name: "Tailwind",
        alt: "Tailwind",
        link: "https://tailwindcss.com/",
      },
      {
        logo: typescript,
        name: "TypeScript",
        alt: "typescript",
        link: "https://www.typescriptlang.org/",
      },
      {
        logo: angular,
        name: "Angular.js",
        alt: "angular.js",
        link: "https://angularjs.org/",
      },
      {
        logo: redux,
        name: "Redux",
        alt: "redux",
        link: "https://angularjs.org/",
      },
      {
        logo: native,
        name: "React Native",
        alt: "React Native",
        link: "https://reactnative.dev/",
      },
      {
        logo: htmx,
        name: "HTMX",
        alt: "HTMX",
        link: "https://htmx.org/",
      },
      {
        logo: vue,
        name: "Vue.js",
        alt: "Vue.js",
        link: "https://vuejs.org/",
      }]
  },
  {
    name: "backend",
    info: [{
      logo: nodejs,
      name: "Node.js",
      alt: "Node.js",
      link: "https://nodejs.org/"
    },
    {
      logo: express,
      name: "Express.js",
      alt: "Express.js",
      link: "https://expressjs.com/",
    },
    {
      logo: flask,
      name: "Flask",
      alt: "Flask",
      link: "https://flask.palletsprojects.com/"
    },
    {
      logo: django,
      name: "Django",
      alt: "Django",
      link: "https://www.djangoproject.com/"
    },
    {
      logo: api,
      name: "Fast API",
      alt: "Fast API",
      link: "https://fastapi.tiangolo.com/"
    },
    {
      logo: postgresql,
      name: "PostgreSQL",
      alt: "postgreSQL",
      link: "https://www.postgresql.org/"
    },
    {
      logo: mongodb,
      name: "MongoDB",
      alt: "MongoDB",
      link: "https://www.mongodb.com/home"
    },
    {
      logo: mysql,
      name: "MySQL",
      alt: "MySQL",
      link: "https://www.mysql.com/"
    },
    {
      logo: nest,
      name: "Nest.js",
      alt: "nest.js",
      link: "https://nestjs.com/"
    },
    {
      logo: sail,
      name: "Sails.js",
      alt: "Sails.js",
      link: "https://sailsjs.com/"
    }]
  },
  {
    name: "Plugin/ Integration",
    info: [
      {
        logo: gmailaddon,
        name: "Gmail Addon ",
        alt: "Gmail Addon ",
        link: "/casestudy/Write-GPT-OpenAI/",
      },
      {
        logo: gcalendar,
        name: "Google Calendar Integration",
        alt: "Google Calendar Integration",
      },
      {
        logo: googlemeet,
        name: "Google Meet Extension",
        alt: "Google Meet Extension",
      },
      {
        logo: zoom,
        name: "Zoom Marketplace Application",
        alt: "Zoom Marketplace Application",
        link: "https://blogs.lakebrains.com/zoom-marketplace-application-revolutionizing-virtual-collaboration/"
      },
      {
        logo: ringcentral,
        name: "Ring Central Application",
        alt: "Ring Central Application",
      },
      {
        logo: webex,
        name: "Webex Plugin ",
        alt: "Webex Plugin ",
      },
      {
        logo: teams,
        name: "Teams Plugin",
        alt: "Teams Plugin",
      },
      {
        logo: salesforce,
        name: "Salesforce Plugin",
        alt: "Salesforce Plugin",
      },
      {
        logo: hubspot,
        name: "Hubspot Plugin",
        alt: "Hubspot Plugin",
      },
      {
        logo: jira,
        name: "Jira Plugin",
        alt: "Jira Plugin",
      },
      {
        logo: monday,
        name: "Monday Plugin",
        alt: "Monday Plugin",
      },
      {
        logo: openai,
        name: "OpenAI Plugin",
        alt: "OpenAI Plugin",
      },
      {
        logo: googleai,
        name: "Google AI API",
        alt: "Google AI API",
      },
      {
        logo: safari,
        name: "Safari Extension",
        alt: "Safari Extension",
      },
      {
        logo: chrome,
        name: "Chrome Extension",
        alt: "Chrome Extension",
        link: "https://lakebrains.com/chrome-extension-plugin-development-company",
      },
    ]
  }
];


const Service = [
  {
    id: "1",
    Heading: "What technologies do you use for Front End Development?",
    Answer: "We specialize in a wide array of front-end technologies including HTML5, CSS3, Bootstrap, Tailwind, React, Next, Typescript, JavaScript, jQuery, Angular, Vue, Redux, HTMX, and React Native.",

  },
  {
    id: "2",
    Heading: "What back-end technologies does your company work with?",
    Answer: "Our back-end development expertise covers Node.js, MongoDB, Express, Django, Flask, MySQL, and PostgreSQL. We also utilize containerization technologies like Docker and Podman, orchestration tools such as Kubernetes, and continuous integration tools including Jenkins, Maven, GitHub, Bitbucket, GitLab, and Jira."
  },
  {
    id: "3",
    Heading: "Do you offer CRM-related plugin development services?",
    Answer: "Yes, we provide CRM-related plugins for popular platforms like Salesforce and HubSpot, enabling seamless integration with your SaaS product.",


  },
  {
    id: "4",
    Heading: "Can you develop plugins for Microsoft applications?",
    Answer: "Certainly! We offer plugin development for Microsoft applications, including Outlook, ensuring smooth interactions and functionalities within the Microsoft ecosystem.    ",


  },
  {
    id: "5",
    Heading: "Do you provide integration services for e-commerce platforms?",
    Answer: "Yes, we offer integration services for e-commerce platforms such as Shopify Marketplace, Amazon Seller Platform, and Woocommerce/Wordpress. Our plugins enhance the functionality and user experience of your e-commerce solution.",


  },
  {
    id: "6",
    Heading: "Can you assist with project management-related plugins?",
    Answer: "Absolutely! We develop plugins for project management tools like Jira and Monday, streamlining your project management processes and enhancing collaboration within your teams.",


  },
  {
    id: "7",
    Heading: "Do you offer CRM-related plugin development services?",
    Answer: "Yes, we provide CRM-related plugins for popular platforms like Salesforce and HubSpot, enabling seamless integration with your SaaS product.",


  },
  {
    id: "8",
    Heading: "Can you develop plugins for Microsoft applications?",
    Answer: "Certainly! We offer plugin development for Microsoft applications, including Outlook, ensuring smooth interactions and functionalities within the Microsoft ecosystem.    ",


  },

  {
    id: "9",
    Heading: "Do you provide integration services for e-commerce platforms?",
    Answer: "Yes, we offer integration services for e-commerce platforms such as Shopify Marketplace, Amazon Seller Platform, and Woocommerce/Wordpress. Our plugins enhance the functionality and user experience of your e-commerce solution."


  },
  {
    id: "10",
    Heading: "Can you assist with project management-related plugins?",
    Answer: "Absolutely! We develop plugins for project management tools like Jira and Monday, streamlining your project management processes and enhancing collaboration within your teams."


  },
  {
    id: "11",
    Heading: "Do you provide integration services for AI and ML tools?",
    Answer: "Yes, we offer integration services for AI and ML technologies, including OpenAI plugin and Google AI API, enabling your SaaS product to harness the power of artificial intelligence and machine learning.    "


  },
  {
    id: "12",
    Heading: "Can you develop browser-based extensions for different web browsers?",
    Answer: "Certainly! We specialize in creating browser-based extensions for Chrome, Firefox, and Safari, extending the functionality of your SaaS product directly in the user's browser."


  },

]


const SubService = [
  {
    img: Web,
    heading:"Web",
    para: "Crafting responsive and intuitive web applications is our expertise at Lakebrains Technologies. We design user-friendly interfaces and seamless navigation, ensuring your web presence reflects your brand identity. With our innovative web solutions, your business can engage customers effectively, driving conversions and growth."
  },
  {
    img: Mobile,
    heading:"Mobile",
    para: "Elevate your business on the mobile platform with Lakebrains Technologies. Our skilled developers create captivating mobile apps for both Android and iOS platforms. We blend creativity with functionality, delivering user-friendly applications that enhance user experiences. Stay ahead in the competitive app market with our bespoke mobile solutions."

  },
  {
    img: Plugin,
    heading:"Plugin",
    para: "Enhance your software's functionality with Lakebrains Technologies' custom plugin development services. We design plugins tailored to your specific requirements, seamlessly integrating additional features into your applications. Our plugins are engineered for compatibility, ensuring smooth operation and improved user satisfaction. Elevate your software's capabilities with our plugin solutions."

  },

]





const SaasNewPage = () => {

  return (
    <>
      <ServiceBanner heading={<span>SaaS Product <br /> Engineering</span>} subHeading="Software Product Engineering is the process of converting an Idea into Reality by designing, creating, testing, deploying, and maintaining software products. We have an expert team to take your product faster to market with a scalable solution. " BannerImg={HomeImage} />
       
      <Services  subservices={SubService}  />


      <ServiceUseCases UseCases={UseCases} subHeading="Use Cases" heading="SaaS Solutions Simplified" description=" Our team of SaaS experts is here to tackle a myriad of use-cases and even more complex challenges." />

      <ServiceIndustries industryData={industryData} subHeading="Industries Transformed By SaaS" heading="Industries" description=" Our experts will work with you to understand and solve specific industry problems using SaaS." />

      <section>
        <TechStackSaas techDataInfo={data1} />
      </section>

      <ServiceExecution execution={execution} subHeading="Execution" heading="Executing SaaS Solution With Precision" description="Our improved execution process helps you move faster and build a scalable solution." />

      <ServiceOurBenefits OurBenefitsData={OurBenefitsData} subHeading="Our benefits" heading="Unlock The Value Of Our Offering" description="We don't just deliver; we collaborate as a dedicated team, supporting you at every stage of development and beyond." />

      <ProblemSolving ProblemSolvingData={ProblemSolvingData} />

      {/* <ConnectOurExperts OurExperts="Shashwat Gupta" image={Shashwat} subHeading="Connect with our experts" heading="Let Us Start Your Project Today" description="Unlock the true potential of your SaaS Product Development with our exceptional Front end, Back end and Plugins Development Team. Contact LakeBrains today to get started on your journey to enhance browsing!" /> */}

      <ServiceFaq serviceData={Service} />
    </>
  );
};

export default SaasNewPage;




