import React from "react";
import "./whyus.css";
import Transparancy from "../../../images/wordpress/Transparency.webp"
import FastDev from "../../../images/wordpress/Rapid-Development.webp"
import Responsibility from "../../../images/wordpress/Full-Responsibility.webp"


const Whyus = () => {
  const data1 = [
    {
      number: 1,
      para: {
        li: [
          "We can communicate all the good and bad news to you. You will also receive regular updates on the project's progress and dedicated resources.",
        ],
      },
      h6: "Transparency",
      image:
        Transparancy,
      alt: 'Transparency',
    },
    {
      number: 2,
      para: {
        li: [
          `We focus on developing cost-effective solutions. We invest in quality and make sure to get the job done right.`,
        ],
      },
      h6: "Cost Effective",
      image:
        FastDev,
      alt: 'Cost Effective',
    },
    {
      number: 3,
      para: {
        li: [
          "We are a company that specializes in rapid development and can help you get your idea off the ground. We release frequently so that clients can monitor customer reactions closely.",
        ],
      },
      h6: "Ideal for Startups",
      image:
        Responsibility,
      alt: 'Ideal for Startups',
    },
  ];
  return (
    <>
      <div className="container-fluid  position-relative ">
        <div className="example-classname">
          <div className="container">
            <div className="LakeComm-Whyu-Wrapper position-relative">
              <div className="LakeComm-Whyu-HeadingWrappper">
                <div className="homepage-heading">
                  <h4>Why us?</h4>
                  <h2>Our Benefits</h2>
                  <p>
                    Over the years, our team has tested and implemented
                    transparent and efficient workflows for various AI projects.
                    Our process allows our customers to receive reproducible
                    results faster and more flexibly.
                  </p>
                </div>
              </div>
              <div className="LakeComm-Whyu-Content mt-5">
                <div className="row justify-content-center">
                  {/* Benifites  */}
                  {data1.map((items, key) => (
                    <div
                      id={key}
                      key={key}
                      className="col-md-4 LakeHome-Nthchild col-sm-6 my-2"
                    >
                      <div className="LakeComm-Whyu-colum m-1  pb-3 pt-md-3">
                        <div className="w-100 h-00">
                          <div className="LakeComm-Whyu-ContentImage m-3 mx-auto ">
                            <img className="w-100" src={items.image} alt={items.alt} />
                          </div>
                          <div className="LakeComm-Whyu-ContentReason text-center">
                            <h6>{items.h6}</h6>
                          </div>
                          <div className="LakeComm-Whyu-Contentpara text-center">
                            <ul>
                              {items.para.li.map((xx, index) => (
                                <li key={index}>{xx}</li>
                              ))}
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
                <div className="LakeHome-Whyus-background1 position-absolute"></div>
                <div className="LakeHome-Whyus-background2 position-absolute"></div>
              </div>
            </div>
          </div>
          <div className="LakeHome-Whyus-background3 position-absolute"></div>
        </div>
      </div>
    </>
  );
};

export default Whyus;
