import React from 'react'
import HireDeveloper from './HireDeveloper'

export default function HireReact() {
    const data = 
        {
        
            bannersubtitle :"Hire React JS Developers",
            bannertitle :"Need to Hire React JS Developers for your next project",
            bannerdescription: "Lakebrains provides the perfect solution for enterprises and startups looking to hire React JS developers. All of our offshore React JS developers are skilled in the most current technologies and delivery methodologies, so you don't have to worry about your project getting put on hold with these rising costs. Hire a remote React JS developer today, and start expecting complex solutions at an affordable price.",
        
    
            modelsubtitle:"Engagement Models",
            modeltitle:"Our Models to Hire React JS Developers",
            modeldescription:"Get an advantage of different hiring models to collaborate with experts in ReactJS. Different hiring models include contracting, consulting, and permanent roles. You can shape your applications however you see fit with expert guidance.",
        
    
            whatwedo:{
                subtitle:"What We Do ?",
                title:"Hire React JS Developer for End-to-End Services",
                description:"Working with us will allow you to stay focused on your goals and meet standards without having to worry about the tech side of things. Our ReactJS developers are professional and know JavaScript inside out, making them the perfect choice for your business. We offer services that are tailored to your needs, including full-stack development and round-the-clock support. With our high-performing results-oriented ReactJS development services, your website is optimized for mobile and productivity so you don't have to worry about it.",
            whatwedoinnersection:[
                {
                    title: "Custom React JS Development",
                    description: "When you hire React JS developer from Lakebrains, you get a team dedicated to building custom React-based websites that are recognized for security, scalability, and functionality.",
                },
                {
                    title: "API & Web Service Integration",
                    description: "Hire React JS developers from Lakebrains, holding profound skills and experience in API development as well as web service integration.",
                },
                {
                    title: "React JS Migration Services",
                    description: "Our experienced ReactJS development teams help you migrate applications to ReactJS easily and smoothly based on your business requirements.",
                },
                {
                    title: "ReactJS Frontend Development",
                    description: "Front-end web development using ReactJS to create an interactive interface that reduces code complexity.",
                },
                {
                    title: "ReactJS Plugin Development",
                    description: "e professional developers to build complete web applications by developing custom React.JS plugins.",
                },
                {
                    title: "Support & Maintenance Services",
                    description: "Your website is supported and maintained completely by LakeBrains React JS developers, who also keep it up to date with the most recent ",
                }
            ]   
            },
            whywearedifferent:{
                subtitle:"Why we are different",
                title:"Key Differentiators of Hiring ReactJS Developers from Lakebrains",
                description:"Work With React JS Developers Through A Variety Of Hiring Models To Build And Develop Web Applications That Make A Difference."
            },
        
            whyus:{
                subtitle:"Why us",
                title:"Why Hire React JS Developer From Lakebrains",
                description:"Our expert React JS developers are premier in creating high-octane web applications. We offer our clients the freedom to hire React JS developers smoothly, which will result in their business growth. Whether you need cross-platform-friendly web applications or single-page web app development, Lakebrains can help you fulfill your needs. Here are some reasons why you should hire React JS Develoepor Fromn Lakebrains"
            },
        
        
            // ourexpertise:{
            //     description:"Lakebrains has extensive expertise on the top React component libraries worth trying. Hire React JS developer from us and build future-ready web apps with the libraries and features you need."
            // }
        }
        
    


  return (
    <>
    <HireDeveloper
    data = {data}/>
    </>
  )
}
