import React from "react";

import data from "./LinkedinSalesNavigatorData";
import Bannnerimage from "../../../../images/CaseStudiesImagesLogo/LinkNavigator.png";
import { SuperSEO } from "react-super-seo";
import Casebanner from "../CaseBanner/Casebanner";

const LinkedinSalesNavigator = () => {
    return (
        <>
            <SuperSEO
                title="LinkedinSalesNavigator - Lakebrains"
                description="Linkedin Sales Navigator Data Scraping is a puppeteer script to scrap people data from sales navigators."
                lang="en"
                twitter={{
                    twitterSummaryCard: {
                        summaryCardImage: { Bannnerimage },
                        summaryCardImageAlt: "LinkedinSalesNavigator",
                        summaryCardSiteUsername: "LinkedinSalesNavigator",
                    },
                }}
            />


            {data.map((items, key) => (
                <Casebanner key={key} data={items.data} />
            ))}
        </>
    );
};

export default LinkedinSalesNavigator;
