import React, { useEffect, useState } from 'react'
import './FAQ.css'

const FAQ = ({ Fdata, ServiceType }) => {
  const [ansExpand, setAnsExpand] = useState(false)

  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://js.hsforms.net/forms/v2.js";
    document.body.appendChild(script);

    script.addEventListener("load", () => {
      // @TS-ignore
      if (window.hbspt) {
        // @TS-ignore
        window.hbspt.forms.create({
          portalId: "22613214",
          formId: "992082ce-67f1-45dc-8f02-d3886873d216",
          target: "#hubspotForm",
        });
      }
    });
  }, []);

  return Fdata ? (
    <div className='example-classname'>
      <div className='container-fluid'>
        <div className='lakeH-Faq-wrapper '>
          <div className="container container-h">
            <div className='text-center homepage-heading'>
              <h4>Some Questions And Their Answers</h4>
              <h2 >Frequently Asked Questions</h2>
              <p>Curious about {ServiceType ? ServiceType : 'Browser Extension, NLP and Web'} Services where to get? Ask us anything about our services and solutions , and get factual responses.</p>

            </div>
            {/* Script Collapse Section */}
            {/* heading 1 */}
            <div className='row pb-5 mb-4 pt-2 FAQ-row-wrapper d-lg-flex d-block'>
              <div className='col-lg-4'>
                {/* <FAQHubspotContactForm /> */}
                <div id="hubspotForm"></div>
              </div>
              <div className='col-lg-8'>
                {Fdata.map((value, ii) => (
                  <div key={ii} >

                    {/* Collapse Section Start Here */}
                    <div className="accordion " id="accordionExample" key={ii}>
                      {value.subData?.map((subDatas, index) => (
                        <div className="" key={index}>
                          <div className="card-header py-0 bg-white lakeH-acc-h" id={`heading${subDatas.id}${ii}${index}`}>
                            <h2 className="mb-0 font-s-16 case_Study_list">
                              <button
                              
                                className="btn btn-link btn-block collapseButton collapsed"
                                id='collapsebtn-01'
                                type="button"
                                data-toggle="collapse"
                                data-target={`#collapse${subDatas.id}${ii}${index}`}
                                aria-expanded="true"
                                aria-controls=""
                              >

                                {/* {subDatas.accordionHead.length > 100 ? <span>{subDatas.accordionHead.slice(0, 100)}...</span> : subDatas.accordionHead} */}
                                {subDatas.accordionHead}
                              </button>
                            </h2>
                          </div>

                          <div
                            id={`collapse${subDatas.id}${ii}${index}`}
                            className="collapse collapse-data"
                            aria-labelledby={`heading${subDatas.id}${ii}${index}`}
                            data-parent="#accordionExample"
                          >
                            <div className="card-body font-s-14 pt-1 lakeH-acc-d">
                              {/* {subDatas.accordionPara.slice(0, 140)} */}
                              {!ansExpand ? subDatas.accordionPara.length > 150 ? <span>{subDatas.accordionPara.slice(0, 150)}...</span> : subDatas.accordionPara : subDatas.accordionPara}
                              {subDatas.accordionPara.length >= 300 ? !ansExpand ? <button className='btn faq-readme-btn' onClick={() => setAnsExpand(true)}>Read More</button> :
                                <button className='btn faq-readme-btn' onClick={() => setAnsExpand(false)}>Read Less</button> : ''}

                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  ) : ("");
}

export default FAQ