import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import product_data from "../../../routes/product_data.js";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import "react-responsive-carousel/lib/styles/carousel.min.css";

const ProductPage5 = ({ Data }) => {
  const [data, setData] = useState([]);
  const [type, setType] = useState("hello");

  useEffect(() => {
    setData(product_data);
    setType(Data?.type);
  }, [product_data, Data]);

  console.log(data);



  const number = product_data?.filter((items) =>
    items.type.includes(type)
  ).length;

  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: number === 1 ? 1 : number === 2 ? 2 : 3,
      slidesToSlide: number === 1 ? 1 : number === 2 ? 2 : 3, // optional, default to 1.
    },
    tablet: {
      breakpoint: { max: 1204, min: 800 },
      items: 2,
      slidesToSlide: 2, // optional, default to 1.
    },
    smalltablet: {
      breakpoint: { max: 800, min: 550 },
      items: 1,
      slidesToSlide: 1, // optional, default to 1.
    },
    mobile: {
      breakpoint: { max: 550, min: 0 },
      items: 1,
      slidesToSlide: 1, // optional, default to 1.
    },
  };

  return (
    <>
      <div className="container stepbystep">
        <div className="homepage-heading">
          <h2>Our Products</h2>
          <p>Tools to Connect with Your Ideal Buyers</p>
        </div>
        {/* <div className="row justify-content-center stepbystep">
          <div className="col-md-4 col-12"> */}
        <Carousel
          swipeable={true}
          draggable={true}
          //   showDots={true}
          responsive={responsive}
          ssr={true} // means to render carousel on server-side.
          infinite={true}
          autoPlay={true}
          autoPlaySpeed={3000}
          keyBoardControl={true}
          customTransition="all 1s ease-in-out"
          transitionDuration={1000}
          containerclassName="carousel-container12"
          removeArrowOnDeviceType={["tablet", "mobile"]}
          //   deviceType={props.deviceType}
          dotListclassName="custom-dot-list-style1"
          itemclassName="carousel-item-padding-40"
          className="text-center carousel-section5 align-center "
        >
          {data?.map((item, i) =>
            type === item.type ? (
              <div
                key={i}
                className="stepbystep d-flex justify-content-center m-3"
              >
                <div className="member-card1 card-product-page-5">
                  <h4>
                    <b>{item.name}</b>
                  </h4>
                  <img className="p-2" src={item.section_1?.image} alt="" />
                  {console.log(item.localURL)}
                  <Link
                    to={"/product-pages/" + item.localURL}
                    className="button-product-page-5 "
                  >
                    <span className="btn-primary header-blogs-btn  btn position-relative">
                      Go to{" "}
                    </span>
                  </Link>
                </div>
              </div>
            ) : null
          )}
        </Carousel>
        {/* </div>
        </div> */}
      </div>
    </>
  );
};

export default ProductPage5;
