import Logo from "../../../images/logo/logo_lakebrains.webp";
import squire from "../../../images/casestudy/squireimg.png"

const data = [
  {
    slugurl: "/squire",
    data: [
      {
        Banner: [
          {
            sector:"Recruitment/HR Technology",
            headingtext: "Squire: Chrome Extension",
            headingpara:
              "Squire's Chrome Extension lets you easily capture conversations with candidates and push the recording to the Squire application for processing, automatically updating your ATS with the relevant candidate information and an AI-generated recruiter write-up.",
            image: squire,
          },
        ],
        heading:
          "  Unlock Recruiter Potential with Squire!",
        casestudy: {
          Problem:
            `"Squire’s conversational intelligence equips recruiters to place more candidates. By capturing every call your recruiters make, and analysing and extracting the right information, recruitment leaders know that the data going into their ATS is as accurate as possible and that their consultants are having the right conversations"`,
          challenge: [
            {
              heading: "WHY USE SQUIRE?? :-",
              challengeli: [
                {First:"Increase Recruiter Capacity"},
                {First:"Reduce Recruiter's Mental Load"},
                {First:"Accurate and Valuable Info in Your ATS"},
                {First:"Capture Value in Every Interaction"},
                
              ],
            },
            {
              heading: "The Challenges:-",
              challengeli: [
                {First:"Login - OAuth login through Google & Linkedin + Email with Django API"},
                {First:"Background capture of Audio - System, and Microphone (while the call is going on in the foreground) - record the call and send it in chunks to django server through websocket. We’re able to capture audio of desktop applications like zoom, teams etc.  in windows."},
                {First:"Securely Capture user input and after call send them to the server with some additional information."},
                {First:"Dynamic display of questions from the AWS database when the user selects a (job type) from the dropdown menu."},
                {First:"Capture closed captions/transcript from meeting platforms like google meet, zoom etc. and send that transcript to Squire’s backend in real time."},
                {First:"Extension should be accessible only on meeting platforms only."},
                
              ],
            },
            {
              heading: "Solutions:-",
              challengeli: [
                {First:"Communication with Squire web application to transfer tokens when users authenticate the web application."},
                {First:"Capture Audio - System and Microphone From a popup window and send the window in background while the call is going on. Sending audio chunks to Squire’s backend to be processed in real time using websocket."},
                {First:"Get user Inputs and save them in localstorage and after call end send that data to the django API with adding some additional information of recorded audio."},
                {First:"Fetch all the job type’s relevant questions from the database through API and display them in the popup."},
                {First:"Scraping captions/transcript from google meet and zoom by using mutation observer and sending that to squire backend on real time using websockets."},
                {First:"Checking tab’s url and if tab url is zoom or meet tab then the extension will be accessible"},
              ],
            },
          ],
        },

        companyinfo: {
          logourl: Logo,
          about: "About ",
          aboutpara:
            " Squire is a product of https://olas.io/ which is developed by Lakebrains Technologies. Squire's Chrome Extension lets you easily capture conversations with candidates and save the recording for processing an AI-generated recruiter write-up.",
          companysector: [
            {
              first: "Company Name:",
              secound: "Squire",
            },
            {
              first: "Industry:",
              secound: "Recruitment",
            },
            {
              first: "LOCATION:",
              secound: "Dublin City, County Dublin",
            },
          ],
        },
        customer: [],
      },
    ],
  },
];

export default data;
