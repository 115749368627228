import React from "react";
import { Link } from "react-router-dom";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import "react-responsive-carousel/lib/styles/carousel.min.css";
import "./CaseStudy.css";

const DemoDataSection = ({ DemoData }) => {
  {
    return DemoData ? (
      <>
        <div
          className="container-fluid example-classname"
          id="casestudy-courasal"
        >
          <div className="container">
            <div className="LakeHome-CaseS-Wrapper">
              <div className="">
                <div className="">
                  <div className="LakeHome-CaseS-HeadingContainer">
                    <div className="homepage-heading">
                      {/* <h4>Problems We Solve</h4> */}
                      <h2>Demos Section</h2>
                      <p>
                        Lorem ipsum dolor sit, amet consectetur adipisicing
                        elit. Fuga doloremque aut culpa soluta, ad excepturi
                        amet quibusdam odio libero voluptatem nisi, harum
                        voluptas?
                      </p>
                    </div>
                  </div>
                  <div className="LakeHome-CaseS-CorouselWrapper">
                    <div className="LakeHome-CaseS-Container">
                      <Carousel
                        showArrows={true}
                        showThumbs={false}
                        showStatus={false}
                        showIndicators={true}
                        autoPlay={true}
                        preventMovementUntilSwipeScrollTolerance={true}
                        // autoPlay={false}
                        infiniteLoop={true}
                      >
                        {DemoData.map((items, index) => (
                          <div key={index}>
                            <div className="row py-4 mx-3 Lake-Case-Carousel-Container">
                              <div className="col-md-5 pl-sm-5 pl-0  Lake-Case-Carousel-Wrapper d-flex justify-content-center align-items-center pt-5 pt-md-0 mt-sm-5">
                                <div className="LaKeHome-Case-Carousel-TextWrapper text-md-left text-center">
                                  <div className="LaKeHome-Case-Carousel-Heading">
                                    <div className="LakeHome-case-logo mb-3 mx-md-0 mx-auto">
                                      <img
                                        src={items.logo ? items.logo : ""}
                                        alt={items.alt}
                                      />
                                    </div>
                                  </div>
                                  <div className="LaKeHome-Case-Carousel-Para">
                                    <h6>{items.subheading}</h6>
                                    <p>{items.para}</p>
                                    <div className="Lake-Case-Carousel-Btn">
                                      <Link target="blank" to={items.caseurl}>
                                        <button>{items.btntext}</button>
                                      </Link>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="col-md-7 d-flex justify-content-center align-items-center order-md-2 order-1">
                                <div className="Lake-Case-Carousel-ImageWrapper">
                                  <div className="Lake-Case-Carousel-image">
                                    <img
                                      className="w-100 h-100"
                                      src={items.imgurl}
                                      alt="Case Study"
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        ))}
                      </Carousel>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    ) : (
      ""
    );
  }
};

export default DemoDataSection;
