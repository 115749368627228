import React, { useState, useEffect } from "react";
import "./Counts.css";
import CountUp from "react-countup";
import VisibilitySensor from "react-visibility-sensor";

import customer from "../../../../images/Products/Idealeads/OurTools/idealeads-icon1.webp";
import location from "../../../../images/Products/Idealeads/OurTools/idealeads-icon2.webp";
import sector from "../../../../images/Products/Idealeads/OurTools/idealeads-icon3.webp";

const LeadMatrics = ({ Data }) => {
  const [section, setsection] = useState("");

  useEffect(() => {
    // console.log(Data.count);

    // const dataCount = Data?.count.map((items) => {
    //   var images = require(`../../../../images/Products/${items.image}`);
    //   console.log(images);
    //   return {
    //     image: images,
    //     endNo: items.endNo,
    //     text: items.text,
    //     alt: items.alt,
    //     CountIcon: items.CountIcon,
    //   };
    // });

    // console.log("dataCount =>", dataCount);

    setsection(Data);
  }, [Data]);

  // const newFun = async (params) => {
  //   console.log("params =>", params);
  //   let images = await require(`../../../../images/Products/${params}`);
  //   // setsection({ ...Data, image: images });
  //   return images;
  // };

  return (
    <div className=" IdelLeads-DriveMetrics-wrapper ">
      <div className="IdelLeads-DriveMetrics-wrapper-00 pt-4  text-dark ">
        <div className="container">
          <div className="IdelLeads-DriveMetrics-container1-wrapper text-center">
            <p className="IdelLeads-DriveMetrics-container1-p font-w-700">{section?.title}</p>
            <p className="font-s-16 p-0 m-0">
              {section?.subTitle}
            </p>
          </div>
        </div>

        <div className="pt-4 container IdelLeads-DriveMetrics-container2-wrapper ">
          <div className="row justify-content-center">
            {section?.count?.map((item, index) => (
              <div className="col-lg-4 col-md-4 col-sm-4 mt-1 text-center" key={index}>
                <div className="Idealeads-Matric-Icon">
                  <img src={item.image} alt={item.alt} />
                </div>
                <p className="IdelLeads-DriveMetrics-container1-p font-w-700 font-s-40">
                  <CountUp end={item.endNo} redraw={true}>
                    {({ countUpRef, start }) => (
                      <VisibilitySensor onChange={start} delayedCall>
                        <span className="px-2" ref={countUpRef}></span>
                      </VisibilitySensor>
                    )}
                  </CountUp>
                  {item.CountIcon}
                </p>
                <p className="font-s-20">{item.text}</p>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default LeadMatrics;
