import React, { useState, useEffect } from "react";
import VideoContentYT from 'react-video-content-youtube';

const StepByStep = ({ Data }) => {
  const [section, setsection] = useState("");

  console.log(section);

  useEffect(() => {
    setsection(Data);
  }, [Data]);

  return (
    <div id="hide" className="stepbystep">
      <header className="homepage-heading ">
        <h2>{section?.title}</h2>
        <p>{section?.subTitle}</p>
      </header>
      <section className="container stepbystep">
        {section?.list?.length === 1 ? (
          <div className="row-stepbystep">
            {/* <div className="col-md-5 col-sm-12 single-stepbystep containt-stepbystep">
              <h3 className="subheading-stepbystep">
                {section?.list[0]?.heading}
              </h3>
              <p>{section?.list[0]?.para}</p>
            </div> */}
            <div className="">
              <div className="video-responsive">
                {/* <ReactPlayer
                  className="video-stepbystep "
                  width="70vw"
                  height="70vh"
                  controls={true}
                  url={section?.list[0]?.video}
                /> */}
                
                <VideoContentYT src={section?.list[0]?.video} params={{autoPlay: true}}/>
              </div>
            </div>
          </div>
        ) : (
          section?.list?.map((item, i) => {
            if (i % 2 === 0) {
              return (
                <div className="row row-stepbystep my-5 px-2">
                  <div className="col-md-5  colm containt-stepbystep">
                    <h3 className="subheading-stepbystep">{item.heading}</h3>
                    <p>{item.para}</p>
                  </div>
                  <div className="col-md-2  colm icon-stepbystep">
                    <h1 className="numbring-stepbystep">{i + 1}</h1>
                  </div>
                  <div className="container colm-2 ">
                    <div className="row">
                      <div className="col-2 icons-stepbystep">
                        <h1 className="numbring-stepbystep">{i + 1}</h1>
                      </div>
                      <div className="stepbystep-small0 col-10">
                        <h3 className="subheading-stepbystep">
                          {item.heading}
                        </h3>
                        <p>{item.para}</p>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-5 col-sm-12">
                    <div className="video-responsive">
                      {/* <ReactPlayer
                        width="70vw"
                        height="70vh"
                        controls={true}
                        url={item.video}
                      /> */}
                      <VideoContentYT src={item.video} params={{autoPlay: true}}/>
                    </div>
                  </div>
                </div>
              );
            } else {
              return (
                <div className="my-5 px-2 row row-reverse-stepbystep">
                  <div className="col-md-5  colm containt-stepbystep">
                    <h3 className="subheading-stepbystep">{item.heading}</h3>
                    <p>{item.para}</p>
                  </div>
                  <div className="col-md-2  colm icon-stepbystep">
                    <h1 className="numbring-stepbystep">{i + 1}</h1>
                  </div>
                  <div className="container colm-2">
                    <div className="row">
                      <div className="col-2 icons-stepbystep">
                        <h1 className="numbring-stepbystep">{i + 1}</h1>
                      </div>
                      <div className="stepbystep-small col-10">
                        <h3 className="subheading-stepbystep">
                          {item.heading}
                        </h3>
                        <p>{item.para}</p>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-5 col-sm-12">
                    <div className="video-responsive">
                      {/* <ReactPlayer
                        width="70vw"
                        height="70vh"
                        controls={true}
                        url={item.video}
                      /> */}
                      <VideoContentYT src={item.video} params={{autoPlay: true}}/>
                    </div>
                  </div>
                </div>
              );
            }
          })
        )}
      </section>
    </div>
  );
};

export default StepByStep;
