import React from "react";
import Casebanner from "./CaseBanner/Casebanner";
import data from "./casestudyVoiceAssistent";
import { SuperSEO } from "react-super-seo";
import Bannnerimage from "../../../images/casestudy/browserext.webp";

const VoiceAssistentCaseStudy = () => {
  return (
    <>


      <SuperSEO
        title="VoiceAssistent - Lakebrains"
        description='Browser Control Through Voice Commands is a free chrome extension that allows you to control your chrome browser through voice commands.'
        lang="en"
        twitter={{
          twitterSummaryCard: {
            summaryCardImage: { Bannnerimage },
            summaryCardImageAlt: "VoiceAssistentCaseStudy",
            summaryCardSiteUsername: "VoiceAssistentCaseStudy",
          },
        }}
      />
      {data.map((items, key) => (
        <Casebanner key={key} data={items.data} />
      ))}
    </>
  );
};

export default VoiceAssistentCaseStudy;
