import React from "react";

import data from "./VoecoEvWebData";
import Bannnerimage from "../../../../images/CaseStudiesImagesLogo/VoecoLogo.png";
import { SuperSEO } from "react-super-seo";
import Casebanner from "../CaseBanner/Casebanner";

const VoecoEvWeb = () => {
    return (
        <>
            <SuperSEO
                title="Voeco - Lakebrains"
                description="Ensuring Client Satisfaction through Collaborative Project Management for Voeco EV's Website"
                lang="en"
                twitter={{
                    twitterSummaryCard: {
                        summaryCardImage: { Bannnerimage },
                        summaryCardImageAlt: "Voeco",
                        summaryCardSiteUsername: "Voeco",
                    },
                }}
            />


            {data.map((items, key) => (
                <Casebanner key={key} data={items.data} />
            ))}
        </>
    );
};

export default VoecoEvWeb;
