import React from "react";
import Casebanner from "./CaseBanner/Casebanner";
import data from "./DetectorVideoCaseStudyData";
import Bannnerimage from "../../../images/banner/infaticalogo.webp";
import { SuperSEO } from "react-super-seo";

const DetectorVideoCaseStudy = () => {
    return (
        <>
            <SuperSEO
                title="DetectorVideo - Lakebrains"
                description="Video downloader by Detector Video is a valuable chrome extension for downloading videos from social media sites."
                lang="en"
                twitter={{
                    twitterSummaryCard: {
                        summaryCardImage: { Bannnerimage },
                        summaryCardImageAlt: "DetectorVideo",
                        summaryCardSiteUsername: "DetectorVideo",
                    },
                }}
            />


            {data.map((items, key) => (
                <Casebanner key={key} data={items.data} />
            ))}
        </>
    );
};

export default DetectorVideoCaseStudy;
