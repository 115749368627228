import React from "react";

import data from "./SquireRingCentralMarketplaceData";
import Bannnerimage from "../../../../images/casestudy/SQUIRE_Img.jpg";
import { SuperSEO } from "react-super-seo";
import Casebanner from "../CaseBanner/Casebanner";

const SquireRingCentralMarketplace = () => {
    return (
        <>
            <SuperSEO
                title="SquireRingCentral - Lakebrains"
                description="Squire's Ringcentral app lets you easily capture conversations with candidates and push the recording to the Squire application for processing"
                lang="en"
                twitter={{
                    twitterSummaryCard: {
                        summaryCardImage: { Bannnerimage },
                        summaryCardImageAlt: "SquireRingCentral",
                        summaryCardSiteUsername: "SquireRingCentral",
                    },
                }}
            />


            {data.map((items, key) => (
                <Casebanner key={key} data={items.data} />
            ))}
        </>
    );
};

export default SquireRingCentralMarketplace;
