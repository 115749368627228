import { React } from "react";
import { Link } from "react-router-dom";
import "./Page404.css";
import { useEffect } from "react";


const Page404 = () => {


  return (
    <section className="container error-content">
      <h1>404</h1>
      <h3>PAGE NOT FOUND</h3>
      <h6>
        Page not found. It's possible we moved it during a redesign. Or maybe we
        axed it because it was nonsense from the start. Either way, this route
        isn't looking good for anyone.
      </h6>
      <button className="btn">
        <Link to="/" className="btn-Link-error">
          Home
        </Link>
      </button>
    </section>
  );
};

export default Page404;
