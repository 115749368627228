import Bannnerimage from "../../../../images/CaseStudiesImagesLogo/Squire.png";

const data = [
  {
    slugurl: "/interact",
    data: [
      {
        Banner: [
          {
            sector:"Communication/ Technology.",
            headingtext: "Squire integration with Ringcentral.",
            headingpara:
              "To extend the existing integration functionalities in Squire to include BT Cloud (RingCentral), ensuring seamless interaction between the two platforms, easy access for users, and effective data processing.",
            image: Bannnerimage,
          },
        ],
        heading:
          "Integration between Squire and Ringcentral (BT Cloud).",
        casestudy: {
          Problem:
            `To extend the existing integration functionalities in Squire to include BT Cloud (RingCentral), ensuring seamless interaction between the two platforms, easy access for users, and effective data processing.`,
          challenge: [
            {
              heading: "The Challenge ",
              challengeli: [
                {First:"Listen to the call start and call end."},
                {First:"Use of authentication tokens again and again."},
              ],
            },
            {
              heading: "The Solution ",
              challengeli: [
                {First:"To listen to the call start and end webhook were used."},
                {First:"Postgres database was integrated to access the authentication token."},
              ],
            },
            {
              heading: "Results",
              challengeli: [{First:"Formed an integration between BT Cloud (RingCentral) and Squire."},],
            },
            {
              heading: "Impact",
              challengeli: [ {First:` Through the use of a webhook to listen to the call answered, recording was started and on the call end, recording metadata was fetched. Then the authentication token was pushed to the database and was fetched when needed.`},],
            },
          ],
        },

        companyinfo: {
          logourl:
            "",
          about: "About ",
          aboutpara:
            "The main objective of this project is to extend the existing integration functionalities in Squire to include BT Cloud (RingCentral), ensuring seamless interaction between the two platforms, easy access for users, and effective data processing.",
          companysector: [
            {
              first: "Company Name:",
              secound: "Squire",

            },  
            {
              first: "INDUSTRY: ",
              secound: "Communication/ Call recording",

            },

            {
              first: "HQ LOCATION:",
              secound: "Ireland/ Europe",

            },
          ],
        },
        customer: [

        ],
      },
    ],
  },
];

export default data;
