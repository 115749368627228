import React, { useEffect } from 'react'
import PoolofTalent from "../../../images/HireDeveloper/PoolofTalent.webp";
import Effective from "../../../images/HireDeveloper/Effective.webp";
import GlobalQualitystandards from "../../../images/HireDeveloper/GlobalQualityStandard.webp";
import EnglishProgrammer from "../../../images/HireDeveloper/EnglishProgrammer.webp";
import MobilePhoto from "../../../images/HireDeveloper/mobilephoto.webp";
import TimeZone from "../../../images/HireDeveloper/TimeZone.webp";
import Flexibility from "../../../images/HireDeveloper/Flexibility.webp";
import Diversity from "../../../images/HireDeveloper/Diversity.webp";
import HrSupport from "../../../images/HireDeveloper/HrSupport.webp";
import FAQ from '../../Comman/FAQ/FAQ';
import "./HireDeveloper.css";
import OurValues from '../AboutUs/teams-group/OurValues';
import Achive from '../AboutUs/teams-group/Achive';
import ServicesTopBanner from '../Services/ServicesTopBanner/ServicesTopBanner';
import hire from '../../../images/HireDeveloper/hire.webp'
import h1Img5 from '../../../images/HireDeveloper/h1-img-5.webp'
import h1Img6 from '../../../images/HireDeveloper/h1-img-6.webp'
import alarm from '../../../images/HireDeveloper/alarm.webp'
import money from '../../../images/HireDeveloper/money.webp'
import team from '../../../images/HireDeveloper/team.webp'
import leader from '../../../images/HireDeveloper/leader.webp'
import leader2 from '../../../images/HireDeveloper/leader2.webp'
import CompanyGoals from "../../../images/HireDeveloper/1.webp";
import Rewards from "../../../images/HireDeveloper/2.webp";
import Certificate from "../../../images/HireDeveloper/3.webp";
import WellBeing from "../../../images/HireDeveloper/4.webp";
import Training from "../../../images/HireDeveloper/5.webp";
import Environment from "../../../images/HireDeveloper/6.webp";
import HireTheBest from './HireTheBest/HireTheBest';
import { SuperSEO } from "react-super-seo";


const archive = [
    {
        heading: "React JS",
        alt: "React JS",
        para: "",
        image: CompanyGoals,
    },
    {
        heading: "Node JS",
        alt: "Node JS",
        para: "",
        image: Rewards,
    },
    {
        heading: "Mongodb",
        alt: "Mongodb",
        para: "",
        image: Certificate,
    },
    {
        heading: "Bootstrap",
        alt: "Bootstrap",
        para: "",
        image: WellBeing,
    },
    {
        heading: "Matterial UI",
        alt: "Matterial UI",
        para: "",
        image: Training,
    },
    {
        heading: " Extension",
        alt: "Chrome Extension",
        para: "",
        image: Environment,
    },
];

const perk = [
    {
        title: "Custom React JS Development",
        description: "When you hire React JS developer from Lakebrains, you get a team dedicated to building custom React-based websites that are recognized for security, scalability, and functionality.",
    },
    {
        title: "API & Web Service Integration",
        description: "Hire React JS developers from Lakebrains, holding profound skills and experience in API development as well as web service integration.",
    },
    {
        title: "React JS Migration Services",
        description: "Our experienced ReactJS development teams help you migrate applications to ReactJS easily and smoothly based on your business requirements.",
    },
    {
        title: "ReactJS Frontend Development",
        description: "Front-end web development using ReactJS to create an interactive interface that reduces code complexity.",
    },
    {
        title: "ReactJS Plugin Development",
        description: "e professional developers to build complete web applications by developing custom React.JS plugins.",
    },
    {
        title: "Support & Maintenance Services",
        description: "Your website is supported and maintained completely by LakeBrains React JS developers, who also keep it up to date with the most recent ",
    },
]
const Leadership = [
    {
        title: "Direct Contact Point",
        alt: "Direct Contact Point",
        description: "Our POCs focus on React JS, providing much-needed expertise at various stages of a project to take advantage of the latest React JS versions, updates, and releases for improved application performance.",
        image: "fa fa-address-card",
    },
    {
        title: "Technical Team",
        alt: "Technical Team",
        description: "Lakebrains React JS web application development team is considered the best in the industry for providing cutting edge solutions designed to take advantage of digital transformation.",
        image: "fa fa-gear",
    },
    {
        title: "Build Own Team",
        alt: "Build Own Team",
        description: "Hire knowledgeable and passionate React JS Web Application developers based on their expertise to meet rapid scalability needs and enable agility to solve critical challenges for strategic advantage.",
        image: "fa fa-address-card",
    },
    {
        title: "Flexible Model",
        alt: "Flexible Model",
        description: "Hire a React JS developer to meet your ever-changing needs & Workloads that provide much-needed flexibility. This will incur rental costs Minimal onboarding and training of new hires on various technologies.",
        image: "fa fa-laptop",
    },
]


const Fdata = [
    {
        heading: "Hire Developer ",
        subData: [
            {
                accordionHead: "How are Lakebrains Technologies developers different?",
                accordionPara:
                    "at lakebrains technologies, we rigorously test our developers to ensure that you only work with the best people who understand your goals technical needs and team dynamics by working with a technical expert instead of a general recruiter or recruiter the end result will be tailored to your needs and the talents will be selected by our network of experts.",
                id: "1",
            },
            {
                accordionHead: "Why should I hire developers from Lakebrains Technologies?",
                accordionPara:
                    "we welcome only the best talents and encourage them to improve very often we also offer transparency flexibility and precise work.",
                id: "2",
            },
            {
                accordionHead: "Can I speak directly to the developers?",
                accordionPara:
                    "Yes, we consider open lines of communication to be an essential aspect of software development. As a result, our customers have direct access to the developers at all times.",
                id: "3",
            },
            {
                accordionHead: "What happens if the developer decides to leave the project mid-project?",
                accordionPara:
                    "lakebrains technologies have full-time developers which is why they don't leave so easily however we are always ready with backups so that you won't experience any inconvenience",
                id: "4",
            },
            {
                accordionHead: "What types of freelance programmers can be hired at Lakebrains Technologies?",
                accordionPara:
                    "You can hire Lakebrains Developers on a contract full-time or freelance basis you can locate a software developer in your time zone who meets your requirements using our global network of qualified software engineers we have freelance front-end developers and back-end developers MERN stack and ai ml engineers you can also sort by the programming languages technologies and frameworks you are most familiar with python developer react developer MongoDB expert java expert ai ml engineer all of our developers are professionals of the intermediate and advanced levels who work remotely and are prepared to begin coding immediately.",
                id: "5",
            },
            {
                accordionHead: "How will communicate with the hired Developer?",
                accordionPara:
                    "How you communicate with employed developers is your choice. Typically, our hired developers communicate with clients via email, Skype, Google Meet, and Zoom. If you want to submit jobs and track your work progress, we can work on your project management system.",
                id: "6",
            },
            {
                accordionHead: "How many hours and days a week can a developer work?",
                accordionPara:
                    "Our employment developers can work 8 hours a day, 5 days a week. Please let us know if it is urgent, We assign developers to work on projects in multiple shifts throughout the day.",
                id: "7",
            },
            {
                accordionHead: "How skilled and experienced are your developers?",
                accordionPara:
                    "We get paid monthly if you hire developers directly from us, and weekly if you hire us through improvements. We accept payments by bank transfer and PayPal.",
                id: "8",
            },
        ],
    },
];


const WorkForce = [
    {
        heading: 'Dedicated Team',
        title1: 'Duration',
        description1: '5days/week; 8 hours per day',
        title2: 'Communication',
        description2: 'Skype,Email,Phone',
        title3: 'Billing',
        description3: 'Monthly',
        title3: 'Hiring Period',
        description3: 'Minimum 1 month',
        alt: 'title1',
        image: team
    },
    {
        heading: 'Fixed Price Model',
        title1: 'Duration',
        description1: '5days/week',
        title2: 'Communication',
        description2: 'Skype,Email,Phone',
        title3: 'Billing',
        description3: 'Milestone-based billing',
        title3: 'Hiring Period',
        description3: 'Depend on the project',
        alt: 'title1',
        image: money
    },
    {
        heading: 'Hourly',
        title1: 'Duration',
        description1: 'Starting from US$14/Hour-Block',
        title2: 'Communication',
        description2: 'Skype,Email,Phone',
        title3: 'Billing',
        description3: 'Hourly-block based',
        title3: 'Hiring Period',
        description3: 'Minimum 25 Hours',
        alt: 'alarm',
        image: alarm
    },
    // {
    //     title: 'Hire A Dedicated Team',
    //     alt: 'Hire A Dedicated Team',
    //     description: 'Hire a dedicated team of resources including developers, designers and project managers.',
    //     image: h1Img4
    // },
    // {
    //     title: 'Let Us Handle Turnkey Project',
    //     alt: 'Let Us Handle Turnkey Project',
    //     description: 'I just want you to manage this project and make it done.',
    //     image: h1Img4
    // }
]
const HireDeveloper = ({data}) => {
    useEffect(() => {
        const script = document.createElement("script");
        script.src = "https://js.hsforms.net/forms/v2.js";
        document.body.appendChild(script);

        script.addEventListener("load", () => {
            // @TS-ignore
            if (window.hbspt) {
                // @TS-ignore
                window.hbspt.forms.create({
                    portalId: "22613214",
                    formId: "992082ce-67f1-45dc-8f02-d3886873d216",
                    target: "#hubspotForm",
                });
            }
        });
    }, []);
    return (
        <>
        <SuperSEO
    title="Need To Hire React JS Developers For Your Next Project"
    description="Lakebrains provides the perfect solution for enterprises and startups looking to hire React JS developers. All of our offshore React JS developers are skilled in the most current technologies and delivery methodologies, so you don't have to worry about your project getting put on hold with these rising costs. Hire a remote React JS developer today, and start expecting complex solutions at an affordable price."
    lang="en"
    twitter={{
      twitterSummaryCard: {
        summaryCardImage: {hire},
        summaryCardImageAlt: "img",
        summaryCardSiteUsername: "justinmahar",
      },
    }}
  />
            <div className='Body-div'>
                {/* <FAQ /> */}
                {/* <ServicesTopBanner
                    headTitle="Hire React JS Developer"
                    headDes="Lakebrains is a one-stop destination for startups and enterprises to hire dedicated React JS developers. Our offshore React JS developers have the required skill sets and experience to deliver top-notch and complex solutions. Hire remote React JS developers of your choice & requirements and save up to 60% of cost in your next project."
                    video={hire}
                /> */}
                <div className="container-fluid">
                    <div className="container-fluid hire-banner-main vh-100 example-classname LakeHome--Wrapper-000-0">
                        <div className="container hire-banner-container pb-5 pt-5">
                            <div className="row hire-banner-row align-items-center mt-5 pt-5 pb-5">
                                <div className="col-md-6 col-sm-12">
                                    <div className=" banner_content-service">
                                        <h4 className="banner-subtitle mb-0">{data.bannersubtitle}</h4>
                                        <h1 className="banner-title">{data.bannertitle}</h1>
                                        <p className="banner-text">{data.bannerdescription}</p>
                                        <a href="#" className='hire-home-btn'><button className="schedule-meeting-btn btn mt-2">Request Quote <i className="far fa-arrow-alt-circle-right pl-1"></i></button>
                                        </a>
                                        <p className="banner-thttps://docs.google.com/spreadsheets/d/1-ZXuHtno_LrEU8e4-a_SXd7FyYZuW7wGJA7L5JPrQcc/edit#gid=0 px-0 mx-0"> 100% Secure and Confidiential</p>
                                    </div>
                                </div>
                                <div className="col-md-6 col-sm-12">
                                    <div className="hire-image-rap">
                                        <img src={hire} className="hire-image " alt="" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <HireTheBest />

                {/* start */}
                {/* <div className="container-fluid contactform-fluid">
                    <div className="container  pt-5">
                        <div className="row contactform-row">
                            <div className="col-md-6">
                                <div className="contactform mt-3">
                                    <h4 className="contactform-subtitle"> Work with Lakebrains</h4>
                                    <h2 className="contactform-title">Hire React JS Developer</h2>
                                    <p className="contactform-text pt-2">Looking to Hire Asp.net Developers?
                                        Our developers ensure delivery of the highest quality.
                                        Your project is therefore perfectly put together.
                                    </p>
                                    <div className="right-tick"><i className="fa fa-check icon"></i>
                                    </div>
                                    <div className="ml-3">
                                        <p className="contactform-des pl-2"> Clean, scalable code utilizing .NET programing languages</p>
                                    </div>
                                    <div className="right-tick"><i className="fa fa-check icon"></i>
                                    </div>
                                    <div className="ml-3">
                                        <p className="contactform-des pl-2"> Hands-on experience with SQL Server & architectural patterns</p>
                                    </div>
                                    <div className="right-tick"><i className="fa fa-check icon"></i>
                                    </div>
                                    <div className="ml-3">
                                        <p className="contactform-des pl-2"> Experienced in upgrading, configuring & debugging projects</p>
                                    </div>
                                    <div className="right-tick"><i className="fa fa-check icon"></i>
                                    </div>
                                    <div className="ml-3">
                                        <p className="contactform-des pl-2"> Skilled with server-side programming & client-side programming</p>
                                    </div>
                                </div>
                            </div>


                        </div>
                    </div>
                </div> */}
                {/* end */}
                <div className="container text-center card-form ">
                    <div className="homepage-heading text-capitalize text-center">
                        <h4>{data.modelsubtitle}</h4>
                        <h2>{data.modeltitle}</h2>
                        <p>{data.modeldescription}</p>

                    </div>
                    <div className="row ">
                        {WorkForce.map((item) => (


                            // <div className="col-lg-4  col-md-6 col-sm-12 Workforce-items-01 ">
                            //     <div className='Workforce-items-01-00 py-5'>
                            //         <div className='Workforce-items-wrapper'>
                            //             <img src={item.image} className="card-img-top card-image" alt={item.alt} />
                            //         </div>
                            //         <div className="mt-4">
                            //             <h5 className="Workforce-title">{item.title}</h5>
                            //             <p className="Workforce-description">{item.description}</p>
                            //             {/* <hr className="w-100 " /> */}
                            //             <div className='d-flex justify-content-center let-choose-btn-wrapper'>
                            //                 <a href="#" ><button className="let-choose-btn btn btn-primary"> Let's choose the right fit <i className="fa fa-arrow-right"></i></button></a>
                            //             </div>
                            //         </div>
                            //     </div>
                            // </div>

                            <div className='col-lg-4  col-md-6 col-sm-12 Workforce-items-01'>
                                <div className='Workforce-items-01-00 py-3 bg-white mt-3'>
                                    <div className='Workforce-items-wrapper'>
                                        <img src={item.image} alt={item.alt} srcset="" />
                                    </div>
                                    <h6 className='mb-2 mt-3'>{item.heading}</h6>
                                    <div>
                                        <p className='my-0 workforce-items-title'>{item.title1}</p>
                                        <p className='my-0 workforce-items-description'>{item.description1}</p>
                                    </div>
                                    <hr className='my-2' />
                                    <div>
                                        <p className='my-0 workforce-items-title'>{item.title2}</p>
                                        <p className='my-0 workforce-items-description'>{item.description2}</p>
                                    </div>
                                    <hr className='my-2' />
                                    <div>
                                        <p className='my-0 workforce-items-title'>{item.title3}</p>
                                        <p className='my-0 workforce-items-description'>{item.description3}</p>
                                    </div>
                                    <hr className='my-2' />
                                    <div>
                                        <p className='my-0 workforce-items-title'>Duration</p>
                                        <p className='my-0 workforce-items-description'>fsdfsdfsdfsdfds</p>
                                    </div>

                                </div>
                            </div>
                        ))}

                    </div>

                </div>
                {/* </div> 
            </div>*/}
                <div>
                    <div className="homepage-heading text-capitalize text-center">
                        <h4 >{data.whatwedo.subtitle}</h4>
                        {/* className="text-center pt-5 mt-3 chooseus-subtitle" */}
                        <h2>{data.whatwedo.title}</h2>
                        <p>{data.whatwedo.description}</p>
                        {/* className="text-center chooseus-title pb-3 */}
                    </div>
                    <div className="container-fluid choose-us pt-5 pb-5">
                        <div className="container py-5">
                            <div className="row">
                                {
                                    data.whatwedo.whatwedoinnersection.map((item) => (
                                        <div className="col-md-4 col-sm-6 col-12 pt-3">
                                            <div className="icon-tick pr-2">
                                                <i className="fa fa-check icon pt-2"></i>
                                            </div>
                                            <div className="icon-des">
                                                <h4 className="ml-4">{item.title}</h4>
                                                <p className="ml-4 pt-1">{item.description}</p>
                                            </div>

                                        </div>
                                    ))
                                }
                            </div>
                        </div>
                    </div>
                </div>
                <div className="container-fluid">
                    <div className="container">
                        <div className="row d-flex align-items-center justify-content-center">
                        </div>
                    </div>
                </div>


                <div className="container-fluid leader-wrapper pt-5 mt-5">
                    <div className="homepage-heading text-capitalize text-center mb-5 pb-5">
                        <h4>{data.whywearedifferent.subtitle}</h4>
                        <h2>{data.whywearedifferent.title}</h2>
                        <p>{data.whywearedifferent.description}</p>

                    </div>
                    <div className="container leader-cont pt-5 mt-5">

                        <div className="row leader-row">
                            <div className="col-md-12 leader-des">
                                <div className="leader-all text-white justify-content-center pt-5 pl-4 pb-5">
                                    <p className="leader-subtitle mt-3">Build Possibility With Lakebrains</p>
                                    <h1 className="leader-title mb-5">Our partners are growing and innovating their businesses faster with Lakebrains</h1>
                                </div>
                                <img src={leader} alt="" className="leader-img" width="80%" />
                            </div>
                            <div className="row business-row mb-5 w-100">

                                {Leadership.map((items) => (
                                    <div className="col-lg-3 col-md-6 col-12">
                                        <div className="business pl-3 pb-3 pt-3">
                                            <i className={items.image}></i>
                                            <h4 className="pt-4 ">{items.title}</h4>
                                            <p className="pt-3 pr-2 text-grey">{items.description}</p>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>

                <div className="container-fluid pt-5 pb-5 ">
                    <div className="homepage-heading text-capitalize text-cente">

                        <h4>{data.whyus.subtitle}</h4>
                        <h2 className="">{data.whyus.title}</h2>
                        <p className="">{data.whyus.description}</p>
                    </div>
                    <div className="container pb-5">
                        <div className="row pt-2">
                            <div className="col-xl-4 col-md-4 col-12 why-allhover-page1 d-flex flex-column justify-content-around">
                                <div className="row why-hover1 pt-2 mt-2 mb-2">
                                    <div className="col-sm-3 d-flex align-items-center justify-content-center">
                                        <img src={PoolofTalent} className="why-img-1" alt="" />
                                    </div>
                                    <div className="col-sm-9 pl-0">
                                        <h4 className="why-heading pt-2">On-time Delivery</h4>
                                        <p className="why-para">We understand that your time is valuable. That's why we stick to the timeline we promised you and deliver the product on time.</p>
                                    </div>
                                </div>
                                <div className="row why-hover2 pt-2 mt-3 mb-2">
                                    <div className="col-sm-3 d-flex align-items-center justify-content-center">
                                        <img src={Effective} className="why-img-1" alt="" />
                                    </div>
                                    <div className="col-sm-9 pl-0">
                                        <h4 className="why-heading pt-2">Cut down extra costs.</h4>
                                        <p className="why-para">React JS Developers never let their customers spend more. With React JS Developers you can get more options and save your limited budget</p>
                                    </div>
                                </div>
                                {/* <div className="row  why-hover3 pt-2 mt-3 mb-2">
                                    <div className="col-sm-3 d-flex align-items-center justify-content-center">
                                        <img src={GlobalQualitystandards} className="why-img-1" alt="" />
                                    </div>
                                    <div className="col-md-9 pl-0">
                                        <h4 className="why-heading pt-2">SELF-LEARNING KRA</h4>
                                        <p className="why-para">Lakebrains allocates 10 hours per Month to our React JS developers for self learning.</p>
                                    </div>
                                </div> */}
                                <div className="row why-hover7 pt-2 mt-3 mb-2">
                                    <div className="col-sm-3 d-flex align-items-center justify-content-center">
                                        <img src={Diversity} className="why-img-1" alt="" />
                                    </div>
                                    <div className="col-sm-9">
                                        <h4 className="why-heading pt-2">Code Standards</h4>
                                        <p className="why-para">Our Developers use code standards specified by the requirements and are validated our teams are experienced in managing all kinds of digital needs to establish a strong digital identity</p>
                                    </div>
                                </div>
                                <div className="row why-hover4 pt-2 mt-3 mb-2">
                                    <div className="col-sm-3 d-flex align-items-center justify-content-center">
                                        <img src={EnglishProgrammer} className="why-img-1 justify-content-center" alt="" />
                                    </div>
                                    <div className="col-sm-9 pl-0">
                                        <h4 className="why-heading pt-2 ">Easy Migration</h4>
                                        <p className="why-para">We offer you a secure migration from any other technology to React JS without issues like task delays, data loss, etc.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-4 col-md-4 middle_image_hiredeveloper why-developer-image-col">
                                <img src={MobilePhoto} className="why-developer-image mh-50 w-100" alt="" />
                            </div>
                            <div className="col-xl-4 col-md-4 col-12 why-allhover-page2 d-flex flex-column justify-content-around">
                                <div className="row why-hover5 pt-2 mt-2 mb-2">
                                    <div className="col-sm-3 d-flex align-items-center justify-content-center">
                                        <img src={TimeZone} className="why-img-1" alt="" />
                                    </div>
                                    <div className="col-sm-9">
                                        <h4 className="why-heading pt-2">DAILY/WEEKLY/MONTHLY REPORTING</h4>
                                        <p className="why-para">Our experts will share with you daily weekly or monthly reports on their work when you hire React JS developer from us</p>
                                    </div>
                                </div>
                                <div className="row why-hover6 pt-2 mt-3 mb-2">
                                    <div className="col-sm-3 d-flex align-items-center justify-content-center">
                                        <img src={Flexibility} className="why-img-1" alt="" />
                                    </div>
                                    <div className="col-sm-9">
                                        <h4 className="why-heading pt-2">TECHNOLOGY TRAINING AND LIBRARY</h4>
                                        <p className="why-para">We motivate and train our ReactJS developers to learn new technologies every day.</p>
                                    </div>
                                </div>
                                <div className="row  why-hover3 pt-2 mt-3 mb-2">
                                    <div className="col-sm-3 d-flex align-items-center justify-content-center">
                                        <img src={GlobalQualitystandards} className="why-img-1" alt="" />
                                    </div>
                                    <div className="col-md-9 pl-0">
                                        <h4 className="why-heading pt-2">SELF-LEARNING KRA</h4>
                                        <p className="why-para">Lakebrains allocates 10 hours per Month to our React JS developers for self learning.</p>
                                    </div>
                                </div>
                                <div className="row why-hover8 pt-2 mt-3 mb-2">
                                    <div className="col-sm-3 d-flex align-items-center justify-content-center">
                                        <img src={HrSupport} className="why-img-1" alt="" />
                                    </div>
                                    <div className="col-sm-9">
                                        <h4 className="why-heading pt-2">100% Code Ownership</h4>
                                        <p className="why-para">When you work with us, we transfer ownership of the code to the client shortly after the ReactJS project is complete.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Achive archive={archive} subHeading="Our Expertise" heading="Other Related Services" description="Lakebrains has extensive expertise on the top React component libraries worth trying. Hire React JS developer from us and build future-ready web apps with the libraries and features you need." />
                <FAQ Fdata={Fdata} ServiceType="NLP" />
            </div>
            {/* </div>  */}
        </>

        
    )

}

export default HireDeveloper