import React from 'react'
import Cities from '../routes/ITCities.json'
import './LinkPages.css'

const Linkpages = ({ path, title }) => {
    return (
        <div className='mt-5 pt-4'>
            <h1 className='text-center'>{title}</h1>
            <ul className=' cities-wrapper row'>

                {Cities.map((city, index) => (
                    <li className='cities-sub-wrapper' key={index}>
                        <a href={`${path}-${city.Cities}`}>{city.Cities}</a>
                    </li>


                ))}
            </ul>
        </div>
    )
}

export default Linkpages