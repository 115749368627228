import React from "react";
import { Link } from "react-router-dom";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import "react-responsive-carousel/lib/styles/carousel.min.css";
import "./CaseStudy.css";
// import Position from "rsuite/esm/Overlay/Position";

const CaseStudySection = ({ CaseData }) => {
  {
    return CaseData ? (
      <>
        <div
          className="container-fluid example-classname"
          id="casestudy-courasal"
        >
          <div className="container">
            <div className="LakeHome-CaseS-Wrapper">
              <div className="">
                <div className="">
                  <div className="LakeHome-CaseS-HeadingContainer">
                    <div className="homepage-heading">
                      <h4>Problems We Solve</h4>
                      <h2>Case Studies</h2>
                      <p>
                        One of the most notable examples is the rise of
                        artificial intelligence (AI). Lakebrains helped startups
                        to automate various tasks, such as customer service,
                        marketing, sales through AI.
                      </p>
                    </div>
                  </div>
                  <div className="LakeHome-CaseS-CorouselWrapper">
                    <div className="LakeHome-CaseS-Container">
                      <Carousel
                        showArrows={true}
                        showThumbs={false}
                        showStatus={false}
                        showIndicators={true}
                        autoPlay={true}
                        preventMovementUntilSwipeScrollTolerance={true}
                        // autoPlay={false}
                        infiniteLoop={true}
                      >
                        {CaseData.map((items, index) => (
                          <div key={index}>
                            <div className="row py-4 mx-3 Lake-Case-Carousel-Container Case-Container">
                              <div className="col-lg-5 col-12 pl-5 py-0 Lake-Case-Carousel-Wrapper d-flex justify-content-center align-items-center pt-md-5 pt-md-0 mt-lg-5">
                                <div className="LaKeHome-Case-Carousel-TextWrapper text-md-left text-center">
                                  <div className="LaKeHome-Case-Carousel-Heading">
                                    <div className="LakeHome-case-logo mb-3 mx-md-0 mx-auto hide-imgurl">
                                      <img src={items.imgurl} alt={items.alt} />
                                    </div>
                                  </div>
                                  <div className="LaKeHome-Case-Carousel-Para">
                                    <h6 className="mb-2">{items.subheading}</h6>
                                    <p>{items.para}</p>
                                    <div className="Lake-Case-Carousel-Btn mt-2">
                                      <Link target="blank" to={items.caseurl}>
                                        <button>{items.btntext}</button>
                                      </Link>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="col-lg-7 col-6 d-lg-flex justify-content-center align-items-center order-md-2 order-1 hide">
                                <div className="Lake-Case-Carousel-ImageWrapper">
                                  <div className="Lake-Case-Carousel-image">
                                    <img
                                      className="Carousel-img w-100 h-100"
                                      src={items.imgurl}
                                      alt="Case Study"
                                    />
                                  </div>

                                  <div className="Lake-Case-Carousel-Btn mt-2 btnbtn">
                                    <Link
                                      target="blank"
                                      to={`https://lakebrains.com/casestudy/`}
                                    >
                                      <button>View More</button>
                                    </Link>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        ))}
                      </Carousel>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    ) : (
      ""
    );
  }
};

export default CaseStudySection;
