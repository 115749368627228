import React, { useEffect, useRef, useState } from "react";
import HubspotContactForm from "../hubspotForm/HubspotContactForm";

const FormPopup = ({ showPopUp, setShowPopUp }) => {

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowPopUp(true);
    }, 5000);

    return () => clearTimeout(timer);
  }, []);

  let popup = null;
  if (showPopUp) {
    popup = <HubspotContactForm setShowPopUp={setShowPopUp} showPopUp={showPopUp}/>;
  }


  // const ref = useRef(null);

  // useEffect(() => {
  //   const handleClickOutside = (event) => {
  //     if (ref.current && !ref.current.contains(event.target)) {
  //       onClickOutside && onClickOutside();
  //     }
  //   };
  //   document.addEventListener('click', handleClickOutside, true);
  //   return () => {
  //     document.removeEventListener('click', handleClickOutside, true);
  //   };
  // }, [onClickOutside]);

  // if (!props.show)
  //   return null;

  return (
    <>
      <div >{popup}</div>
    </>
  );
};
export default FormPopup;
