import React from "react";
import LiveTranscriptHeader from "../../Comman/LiveTranscriptExtension/LiveTranscriptHeader/LiveTranscriptHeader";
import LiveTranscriptContainer from "../../Comman/LiveTranscriptExtension/LiveTranscriptContainer/LiveTranscriptContainer";
import LiveTranscriptFeature from "../../Comman/LiveTranscriptExtension/LiveTranscriptFeacher/LiveTranscriptFeature";
import LiveTranscriptGet from "../../Comman/LiveTranscriptExtension/LiveTranscriptGet/LiveTranscriptGet";
import LiveTranscriptFooter from "../../Comman/LiveTranscriptExtension/LiveTranscriptFooter/LiveTranscriptFooter";
import Questions from "../../Comman/LiveTranscriptExtension/FrequentlyAskedQuestions/Questions";

const LiveTranscriptExtension = () => {
  return (
    <>
      <LiveTranscriptHeader />
      <LiveTranscriptContainer />
      <LiveTranscriptFeature />
      <div style={{ backgroundColor: "#EBEBEB" }}>
        <LiveTranscriptGet />
      </div>
      <Questions />
      <LiveTranscriptFooter />
    </>
  );
};

export default LiveTranscriptExtension;
