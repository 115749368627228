import React from "react";
import { PopupButton } from "react-calendly";
import { Link } from "react-router-dom";
import "./Footer.css";
import Logo from "../../../images/logo/logo_lakebrains.webp";
import call from "../../../images/footer/call.webp";
import linkedin from "../../../images/logo/linkedin.png";
import facebook from "../../../images/logo/facebook1.svg";
import insta from "../../../images/logo/insta.png";
import github from "../../../images/logo/github.png";

const socialLinks = [
  { href: "https://www.linkedin.com/company/lakebrains-technologies/", src: linkedin, alt: "linkedin" },
  { href: "https://www.instagram.com/lakebrainstechnologies/", src: insta, alt: "insta" },
  { href: "https://github.com/lakebrains-technologies", src: github, alt: "github" },
  { href: "https://www.facebook.com/LakeBrainsTechnologies/", src: facebook, alt: "facebook" }
];

const companyLinks = [
  { to: "/about-us/", label: "About Us" },
  { to: "/casestudy/", label: "Case studies" },
  { to: "/api/empathy/", label: "Solutions" },
  { to: "https://testimonial.to/lakebrains-technologies/all", label: "Testimonials" }
];

const servicesLinks = [
  { to: "/saas-product-service-provider-company/", label: "SaaS Product Engineering" },
  { to: "/ai-ml-Development-service-provider-company/", label: "AI/ML Development" },
  { to: "/Cloud-Deployment-service-provider-company/", label: "Cloud Deployment" }
];

const addressDetails = [
  {
    title: "Headquarter",
    tel: "+919664353500",
    address: "612, 6th Floor, Ashok Nagar,\nCity Center, University Rd,\nUdaipur, Rajasthan, India (313001)"
  },
  {
    title: "US Office",
    tel: "+1614-325-5856",
    address: "4200 Regent StreetSuite 200, Columbus, Ohio 43219"
  }
];

const Footer = () => {
  return (
    <div className="footer container-fluid p-0">
      <div className="Footer-wrapper pb-5">
        <div className="container">
          <div className="logo-wrpapper mb-5">
            <div className="footer-img-wrapper">
              <Link to="/">
                <img src={Logo} alt="Lakebrains Technologies" />
              </Link>
            </div>
          </div>
          <div className="row Footer-sub-wrapper-01">
            <div className="col-sm-4 col-md-3">
              <div>
                <div className="font-s-18 text-lightgrey font-w-500">
                  For Sale &nbsp;
                  <a href="mailto:sales@lakebrains.in" className="font-w-700 text-decoration-underline text-1">
                    sales@lakebrains.in
                  </a>
                </div>
                <div className="font-s-18 text-lightgrey font-w-500">
                  For Career &nbsp;
                  <a href="mailto:hr@lakebrains.in" className="font-w-700 text-decoration-underline text-1">
                    hr@lakebrains.in
                  </a>
                </div>
                <PopupButton
                  className="btn footer-let-talk-btn mt-4"
                  url="https://calendly.com/khushbu-jpao/30min?back=1&month=2024-05"
                  rootElement={document.getElementById("root")}
                  text="Let's talk"
                />
                <div className="font-w-600 font-s-16 mt-4">Follow us</div>
                <div className="row">
                  {socialLinks.map(({ href, src, alt }, index) => (
                    <a href={href} key={index}>
                      <button className="footer-social-btn">
                        <img src={src} alt={alt} height="auto" width="auto" className={alt === 'insta' ? 'p-1' : ''} />
                      </button>
                    </a>
                  ))}
                </div>
              </div>
            </div>
            <div className="col-sm-4 col-md-3">
              {addressDetails.map(({ title, tel, address }, index) => (
                <div className="heading-wrapper mb-4" key={index}>
                  <div className="d-flex align-items-center mb-1">
                    <h6>{title}</h6>
                    <div className="call-logo ml-2">
                      <a href={`tel:${tel}`}>
                        <img src={call} alt="call" />
                      </a>
                    </div>
                  </div>
                  <p>{address.split('\n').map((line, i) => (<span key={i}>{line}<br /></span>))}</p>
                </div>
              ))}
            </div>
            <div className="col-lg-3 col-sm-4 col-md-3 footer-items-0 heading-wrapper">
              <h6 className="LakeComm-footer-Address">Company</h6>
              <ul className="navbar-nav mb-4">
                {companyLinks.map(({ to, label }, index) => (
                  <li className="nav-item m-0" key={index}>
                    <Link className="font-w-500 footer-sub-items-0" to={to}>
                      {label}
                    </Link>
                  </li>
                ))}
              </ul>
              <div className="mb-4 footer-items-0 heading-wrapper">
                <h6 className="font-w-600 LakeComm-footer-Address">Services</h6>
                <ul className="list-unstyled">
                  {servicesLinks.map(({ to, label }, index) => (
                    <li className="nav-item m-0" key={index}>
                      <Link className="font-w-500 footer-sub-items-0" to={to}>
                        {label}
                      </Link>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
        </div>
        <hr />
        <div className="container d-flex justify-content-between">
          <p>© 2023, <Link to="/" className="font-w-700 LB">Lakebrains Technologies</Link>. All rights reserved.</p>
          <p><Link to="/privacy-policies/" className="font-w-700 LB">Privacy Policies</Link></p>
        </div>
      </div>
    </div>
  );
};

export default Footer;
