import React from "react";
import { useState } from "react";
import "../FAQ/faq.css";
import Downimg from '../../../../images/Podspiria/Downimg.svg'
import Upimg from '../../../../images/Podspiria/Upimg.svg'

const Faq = () => {
  const [faqs, setFaqs] = useState([
    {
      id: 1,
      Heading: "How to convert youtube and spotify Podcast into Transcript and Summary ?",
      Answer:
        "Paste the podcast link above, click transcribe, enter your email and it's done. After completion, you'll receive an automatic email with a download link.",
    },
    {
      id: 2,
      Heading: "How Podspiria transcribe the Youtube and Spotify Podcast ?",
      Answer:
        "When a user provides a  podcast link on the  website, the extension's backend processes the audio content in real-time, identifying speech patterns, words, and sentences. The speech recognition algorithm then transcribes the spoken content into written text, producing an accurate transcript of the Spotify podcast.",
    },
    {
      id: 3,
      Heading: "Do you need to pay anything for transcription and summary ?",
      Answer:
        "No , its completely free of cost .",
    },
    {
      id: 4,
      Heading:
        "What langauge currently being supported by Podspiria for Youtube and Spotify Transcription ??",
      Answer:
        "Currently, it supports english language. However, we are continuously working to expand our language support .",
    },
    {
      id: 5,
      Heading: "Are you going sell our personal information ?",
      Answer: "No , its completely free of cost .",
    },
    {
      id: 6,
      Heading: "How Podspiria is different from the other tool ?",
      Answer:
        "By combining efficiency, real-time transcription, automatic email delivery, and plans for broader language support, Podspiria stands out as a powerful and versatile tool for podcast transcription",
    },
    {
      id: 7,
      Heading: "Can you transcribe Youtube videos ?",
      Answer:
        "Yes, Podspiria can also transcribe Youtube video with support of english language.",
    },
    {
      id: 8,
      Heading: "Why Podspiria for Spotify Podcast Transcription and Summary ?",
      Answer:
        "It offers effortless and accurate conversion through advanced speech recognition technology. Experience time-saving benefits, streamlined content management, and automatic email delivery with User-friendly Interface .",
    },
    {
      id: 9,
      Heading:
        "What are the steps to convert the Youtube and Spotify Podcast into Text/transcription ?",
      Answer:
        "Paste spotify or youtube Link , click 'Transcribe' , enter email and process done. Enjoy.",
    },
    {
      id: 10,
      Heading:
        "How much time it will take to generate text from the Youtube and Spotify Podcast ?",
      Answer:
        "The time depends on length of podcast. Generally, it will take 5-10 min. You will receive mail. Please check spam folder if. you don't receive in your inbox it even after 10min.",
    },
    // Add more FAQ items as needed
  ]);

  const [openFAQIndex, setOpenFAQIndex] = useState(null);

  const toggleFAQ = (id) => {
    setOpenFAQIndex((prevIndex) => (prevIndex === id ? null : id));
  };

  return (
    <div className="container">
      <div className="faqMainDiv">
        <div className="faqDivPart1">
          <h2>Frequently Asked Questions</h2>
          <h4 className="faq-text">
            Curious about podspiria? Ask us anything about our service and the
            solutions, and get factual responses.
          </h4>
        </div>
        <div className="faqDivPart3">
          {faqs.map((faq) => (
            <div className="faqtitle" key={faq.id}>
              <h2 className="f-head" id="FaqId">
                {faq.Heading}
                <button 
                  className={openFAQIndex === faq.id ? "activeButton" : "faqbtn"}
                onClick={() => toggleFAQ(faq.id)}>
                {openFAQIndex === faq.id ? <span><img src={Upimg} alt="" /></span> : <span><img src={Downimg} alt="" /></span>}
                </button>
              </h2>
              {openFAQIndex === faq.id && <p id="faqpara">{faq.Answer}</p>}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Faq;
