import Bannnerimage from "../../../../images/CaseStudiesImagesLogo/SmartFiber_Logo.webp";


const data = [
  {
    slugurl: "/SmartFiberWeb",
    data: [
      {
        Banner: [
          {
            sector: "Recruitment/ Technology",
            headingtext: "SmartFiber",
            headingpara:
              " As a GlobalConnect partner, we provide efficient management and top-tier fiber network solutions and support to businesses and public institutions.",
            image: Bannnerimage,
          },
        ],
        heading:
          "Simplifying advanced fibre networks through GlobalConnect.",
        casestudy: {
          Problem:
            `The platform offers simple and efficient management of advanced fiber network products. As a GlobalConnect partner, we work closely to provide first-class fiber network solutions and support to businesses and public institution.`,
          challenge: [
            {
              heading: "The Challenges:-",
              challengeli: [
                { First: "Elementor lacks the required functionality." },
                { First: "Identifying relevant recipients and contextual cues within conversations." },
                { First: "Designing an intuitive interface to facilitate user-friendly customization." },
                { First: "Ensuring seamless integration and compatibility across the Microsoft ecosystem." },
              ],
            },
            {
              heading: "The Approaches:",
              challengeli: [
                { First: "Leveraged Microsoft API to streamline mail data retrieval and management." },
                { First: "Employed robust local storage and session management techniques." },
                { First: "Crafted a dynamic and intuitive UI enriched with icons for enhanced user experience." },
                { First: "Implemented proactive warnings and alerts to guide users through various usage scenarios." },
              ],
            },
            {
              heading: "Impact",
              challengeli: [{ First: `By collaboratively setting clear project milestones, maintaining open communication channels, and involving the client’s in feedback. We ensured alignment with client expectations and delivered a professional, user-friendly website for smart Fiber. The site is responsive, visually engaging, and optimised for a seamless user experience across devices, showcasing innovative fibre technologies while promoting sustainability.` },],
            },
          ],
        },

        companyinfo: {
          logourl:
            "yasmin",
          about: "About ",
          aboutpara:
            "SmartFiber delivers top-tier fiber network solutions and support to businesses and public institutions, leveraging efficient management as a GlobalConnect partner..",
          companysector: [
            {
              first: "Company Name:",
              secound: "SmartFiber",
            },
            {
              first: "INDUSTRY:",
              secound: "fiber/ Technology",
            },
            {
              first: "LOCATION:",
              secound: "Norway",
            },
          ],
        },
        customer: [

        ],
      },
    ],
  },
];

export default data;
