import React, { useEffect } from "react";
import "./OurBenefits.css";
import OurBenifitsimg from "../../../../images/NewHomePage/Ourbenifits.svg";


const OurBenefits = ({ archive, heading, subHeading, description }) => {
  return (
    <div className="OurBenefits-heading">
      <div className="mb-3">
        <div className="OurBenefits-text">
          <div className="OurCommitment-text d-flex text-center flex-column align-items-center" >
            <h4>{heading}</h4>
            <h2>{subHeading}</h2>
            <p>{description} </p>
          </div>
          <div className="OBcontent">
           <div>
            <img className="OBimg"  src= {OurBenifitsimg} loading="lazy"/>
           </div>

          <div className="OurBenefits-container justify-content-center">
            {archive.map((item, index) => (
              <div className="archive-teams-data" key={index}>
                <div className="archive-teams OurBenefits-teams text-center d-flex  align-items-center">
                  <img className='OurBenefit-image' src={item.image} alt={item.alt} loading="lazy"/>
                  <p>{item.content}</p>
                  <h3>{item.heading}</h3>
                 
                  <p>{item.para}</p>
                </div>
              </div>
            ))}
          </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OurBenefits;
