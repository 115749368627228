import Bannnerimage from "../../../../images/casestudy/writeGPT.webp";

const data = [
  {
    slugurl: "/Write-GPT-OpenAI",
    data: [
      {
        Banner: [
          {
            sector:" Productivity/ Email Communication",
            headingtext: "writeGPT - OpenAI's GPT prompt assistant",
            headingpara: `GPT3.5 extension to facilitate effective prompts for emails, content creation, summarising, learning & engagement on any website.`,
            image: Bannnerimage,
          },
        ],
        heading:
          "Reply your emails smartly with Gmail GPT Auto Reply Extension",
        casestudy: {
          Problem: `" The challenges faced in developing the "Gmail GPT Auto Reply" extension include calling the ChatGPT API within the extension, retrieving selected text from the Gmail interface, and implementing limitations on API calls for security purposes."`,
          challenge: [
            {
              heading:
                "The Challenges:-",
              challengeli: [
                {First:"Call ChatGPT API in the extension"},
                {First:"Get selected text into the extension"},
                {First:"Set limit on API Calling by security purpose"},
              ],
            },
            {
              heading: "The Approaches:-",
              challengeli: [
                {First:"Use backend to call ChatGPT API in the extension because we can’t directly access chatGPT API in the frontend."},
                {First:"Getting selected code by javascript’s getSelection function"},
                {First:"Set the limit on the user's IP Address in the backend side through Express Rate Limiter."},
              ],
            },
            {
              heading: "Impact",
              challengeli: [ {First:` The "Gmail GPT Auto Reply" extension enhances productivity and efficiency for Gmail users by providing intelligent auto-reply suggestions based on email content. By utilizing the ChatGPT API and retrieving selected text, the extension enables users to reply to emails more smartly and quickly. Implementing rate limiting ensures API security and prevents misuse. Overall, the extension streamlines email communication, saves time, and improves the email response process.`},],
            },
          ],
        },

        companyinfo: {
          logourl: "Logo",
          about: "About ",
          aboutpara:
            "Gmail GPT Auto Reply Extension is responsible for generating auto reply of an email conversation, summarising the mail thread, learning and engagement on any website.",
          companysector: [
            {
              first: "Company Name:",
              secound: "writegpt.ai",
            },
            {
              first: "INDUSTRY:",
              secound: "Productivity",
            },
          ],
        },
        customer: [],
      },
    ],
  },
];

export default data;
