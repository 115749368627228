import React from "react";
import { Link } from "react-router-dom";



const CaseStudyPageCards = ({ data1, heading, subHeading, description }) => {

    return (
        <>
            <div className="container-fluid position-relative ">
                <div className="example-classname" style={{ padding: "55px 0" }}>
                    <div className="container overflow-hidden">
                        <div className="LakeComm-Whyu-Wrapper position-relative">
                            <div className="OurCommitment-text d-flex text-center flex-column align-items-center" style={{ marginBottom: "65px" }}>
                                <h4>{subHeading}</h4>
                                <h2>{heading}</h2>
                                <p>{description}</p>
                            </div>
                            <div className="LakeComm-Whyu-Content">
                                <div className="row justify-content-center" style={{rowGap: "30px"}}>
                                    {data1.map((items, key) => (
                                        <div
                                            id={key}
                                            key={key}
                                            className="col-md-6 LakeHome-Nthchild my-2"
                                        >
                                            <Link to={items.URL}>
                                            <div className="LakeComm-Whyu-colum our-services m-1 py-5 px-3">
                                                <div className="w-100 h-00">
                                                    <div className="LakeComm-Whyu-ContentImage m-3 mx-auto p-2">
                                                        <img
                                                            className="w-100"
                                                            src={items.image}
                                                            alt={items.alt}
                                                        />
                                                    </div>
                                                    <div className="LakeComm-Whyu-ContentReason text-center" style={{color: "black"}}>
                                                        {/* <span>{items.number}</span> */}
                                                        <h6>{items.h6}</h6>
                                                    </div>
                                                    <div className="LakeComm-Whyu-Contentpara text-center" style={{color: "#575757"}}>
                                                        <ul>
                                                            {items.para.li.map((xx) => (
                                                                <li>{xx}</li>
                                                            ))}
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                            </Link>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default CaseStudyPageCards;
