import React from "react";

import data from "./EmpathyData";
import Casebanner from "../CaseBanner/Casebanner";

import { SuperSEO } from "react-super-seo";
import Bannnerimage from "../../../../images/logo/logo_lakebrains.webp";

const Empath = () => {
    return (
        <>

            <SuperSEO
                title="Empathy - Lakebrains"
                description="Our AI/ML product revolutionises text analysis by accurately identifying emotions, intents, and named entities."
                lang="en"
                twitter={{
                    twitterSummaryCard: {
                        summaryCardImage: { Bannnerimage },
                        summaryCardImageAlt: "Empathy",
                        summaryCardSiteUsername: "Empathy",
                    },
                }}
            />

            {data.map((items, key) => (
                <Casebanner key={key} data={items.data} />
            ))}
        </>
    );
};

export default Empath;
