import React from "react";

import data from "./DatalixExtensionData";
import Bannnerimage from "../../../../images/casestudy/datalixLogo2.png"
import { SuperSEO } from "react-super-seo";
import Casebanner from "../CaseBanner/Casebanner";

const Datalix = () => {
    return (
        <>
            <SuperSEO
                title="Datalix - Lakebrains"
                description="The Datalix Chrome Extension is a tool designed to help users find information about e-commerce companies and their contact information."
                lang="en"
                twitter={{
                    twitterSummaryCard: {
                        summaryCardImage: { Bannnerimage },
                        summaryCardImageAlt: "Datalix",
                        summaryCardSiteUsername: "Datalix",
                    },
                }}
            />


            {data.map((items, key) => (
                <Casebanner key={key} data={items.data} />
            ))}
        </>
    );
};

export default Datalix;