import React from "react";
import data from "./TextHighlightData";
import Bannnerimage from "../../../../images/casestudy/texthighlight.webp"
import { SuperSEO } from "react-super-seo";
import Casebanner from "../CaseBanner/Casebanner";

const TextHighlight = () => {
    return (
        <>
            <SuperSEO
                title="TextHighlight - Lakebrains"
                description="Text Highlight is a valuable chrome extension for showing selected or highlighted text in different WPM(Words per min)."
                lang="en"
                twitter={{
                    twitterSummaryCard: {
                        summaryCardImage: { Bannnerimage },
                        summaryCardImageAlt: "TextHighlight",
                        summaryCardSiteUsername: "TextHighlight",
                    },
                }}
            />


            {data.map((items, key) => (
                <Casebanner key={key} data={items.data} />
            ))}
        </>
    );
};

export default TextHighlight;
