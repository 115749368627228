import Bannnerimage from "../../../../images/casestudy/screen_rec_ext.webp";

const data = [
  {
    slugurl: "/screen_rec_ext",
    data: [
      {
        Banner: [
          {
            sector:"Software Development/Technology",
            headingtext: "Screen Recorder Chrome Extension",
            headingpara:
              "Screen Recorder Extension is a valuable chrome extension capturing your chrome tabs like google meets & other content related to websites.",
            image: Bannnerimage,
          },
        ],
        heading:
          "Chrome Extension to Capture/Record chrome tabs screen",
        casestudy: {
          Problem:
            `"Screen capture and recording issues with desktop audio, low-resolution full-screen capture on Mac, video duration display bug, manifest permission problem on Mac, and countdown timer malfunction with multiple tabs.

            "`,
          challenge: [
            {
              heading: "How to use it:- ",
              challengeli: [
                {First:"Download Screen Recorder Chrome Extension"},
                {First:"Sign in to the website https://devauth.demobites.com/"},
                {First:"Select audio options from the dropdown menu"},
                {First:"Click the start recording button"},
                {First:"Select the tab & click the share button"},
                {First:"It will start counting after three seconds"},
                {First:"For stopping when you are done by clicking on the extension icon, stop sharing on top, or manually click on the extension tab stop record button"},
                {First:"It will redirect back to the extension where you find a preview upload and the cancel button upload will upload to the server cancel will reset."},
                {First:"On upload, it will upload and save the recording on the server."},
              ],
            },
            {
              heading: "The Challenges:- ",
              challengeli: [
                {First:"Screen capture desktop audio stream chrome bug of full screen & window on mac."},
                {First:"Screen capture low-resolution for full-screen issues on the mac pre-build screen."},
                {First:"The Raw blob data preview chrome bug is not showing video duration."},
                {First:"Manifest permission issue on the mac."},
                {First:"Screen capture 321 counts not working on the same email in more than one tab."},
              ],
            },
            {
              heading: "The Approaches:-",
              challengeli: [
                {First:"Removed the window & full-screen capture due to it chrome APIs official bug."},
                {First:"Removed the full-screen due to its mac’s official bug."},
                {First:"Fast forward video for the first time and shown on the second time so raw data is fully loaded before showing"},
                {First:"Used setTimeout() so it will wait for a few seconds till gets permission"},
                {First:"For this issue, we have used lastFocusedWindow: true in selecting the tab."},
              ],
            },
            {
              heading: "Impact",
              challengeli: [ {First:`Despite Chrome API limitations, the extension enables effective screen capture and recording. Bugs are addressed by removing affected options, optimizing raw data loading and playback, implementing workarounds for manifest permission issues, and improving tab selection. These enhancements ensure a seamless and efficient screen capture experience.`},],
            },
          ],
        },

        companyinfo: {
          logourl:
            "",
          about: "About ",
          aboutpara:
            "This chrome extension gives you the capability to Capture chrome tabs such as google meets, youtube & other content related to websites with different audio settings.",
          companysector: [
            {
              first: "Company Name:",
              secound: "Appove LTD",
            },
            {
              first: "INDUSTRY:",
              secound: "Software Development",
            },
            {
              first: "LOCATION:",
              secound: "Israel",
            },
          ],
        },
        customer: [
        ],
      },
    ],
  },
];

export default data;
