import "./chrome-extension-development.css";
import Nav from "../../Comman/chrome-extension-development/nav";
import Home from "../../Comman/chrome-extension-development/home";
import What from "../../Comman/chrome-extension-development/what";
import Why from '../../Comman/chrome-extension-development/why';
import Form from '../../Comman/chrome-extension-development/form';
import Tech from '../../Comman/chrome-extension-development/tech';
import Ourservice from '../../Comman/chrome-extension-development/ourservices';
import Testimonials from '../../Comman/chrome-extension-development/testimonial';
import Connect from "../../Comman/chrome-extension-development/connect";
import Blog from '../../Comman/chrome-extension-development/blogs';
import Faq from '../../Comman/chrome-extension-development/faq';


function App() {
  return (
    <div className="app-main">
      {/* <Nav /> */}
      <Home />
      <Why/>
      <What />
      <Ourservice/>
      <Form/>
      <Tech/>
      <Testimonials/>
      <Connect/>
      <Blog/>
      <Faq/>
      {/* <Footer/> */}
    </div>
  );
}

export default App;
