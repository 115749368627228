import Bannnerimage from "../../../../images/logo/logo_lakebrains.webp";

const data = [
  {
    slugurl: "/empath",
    data: [
      {
        Banner: [
          {
            sector: "AI/ ML",
            headingtext: "Empathy",
            headingpara:
              "Our AI/ML product revolutionises text analysis by accurately identifying emotions, intents, and named entities.",
            image: Bannnerimage,
          },
        ],
        heading:
          "Extract insights from text effortlessly with our AI/ML product.",
        casestudy: {
          Problem:
            `Our Emotion Analysis, Intent Analysis, and Named Entity Recognition AI/ML product offers advanced text analysis capabilities, empowering businesses to extract valuable insights from textual data. By accurately identifying emotions, intents, and named entities, our product enhances decision-making and customer engagement.`,
          challenge: [
            {
              heading: "Why Use Our product?",
              challengeli: [
                { First: "Gain deeper insights into customer sentiments, intentions, and key entities." },
                { First: "Enhance marketing strategies, customer service, and product development." },
                { First: "Improve decision-making processes based on comprehensive text analysis." },
                { First: "Harness the power of AI/ML for data-driven insights and actionable outcomes." },
              ],
            },
            {
              heading: "How to Use Our product:",
              challengeli: [
                { First: "Input textual data for emotion, intent, and named entity analysis." },
                { First: "Receive detailed insights and visualisations to understand text characteristics." },
                { First: "Utilise insights to inform marketing campaigns, customer interactions, and business strategies." },
                { First: "Continuously refine and optimise processes based on ongoing analysis and feedback." },
              ],
            },
            {
              heading: "The Challenges:-",
              challengeli: [
                { First: "Ensuring accurate emotion, intent, and named entity identification across diverse text types." },
                { First: "Managing large volumes of textual data while maintaining processing efficiency." },
                { First: "Integrating AI/ML models seamlessly into existing business workflows." },
              ],
            },
            {
              heading: "Approaches",
              challengeli: [
                { First: "Implementing state-of-the-art AI/ML algorithms for emotion, intent, and named entity recognition." },
                { First: "Employing scalable infrastructure for efficient processing of large text datasets." },
                { First: "Collaborating closely with clients to tailor solutions to their specific business needs and challenges." },
              ],
            },
            {
              heading: "Impact",
              challengeli: [{ First: `Our Emotion Analysis, Intent Analysis, and Named Entity Recognition AI/ML product revolutionises text analysis, enabling businesses to unlock valuable insights from textual data. By accurately identifying emotions, intents, and key entities, our product enhances decision-making, customer engagement, and overall business performance.` },],
            },
          ],
        },

        companyinfo: {
          logourl:
            Bannnerimage,
          about: "About ",
          aboutpara:
            "Empathy is our AI/ML product that revolutionizes text analysis by accurately identifying emotions, intents, and named entities. This cutting-edge technology provides deeper insights and a better understanding of user interactions, enhancing communication and data analysis for more informed decision-making.",
          companysector: [
            {
              first: "Company Name:",
              secound: "Lakebrains Technologies",
            },
            {
              first: "INDUSTRY:",
              secound: "Artificial Intelligence/ Machine Learning",
            },
            {
              first: "LOCATION:",
              secound: "Udaipur",
            },
          ],
        },
        customer: [

        ],
      },
    ],
  },
];

export default data;
