import React from "react";

import data from "./OutlookAddonData";
import Bannnerimage from "../../../../images/CaseStudiesImagesLogo/microsoft-outlook-icon.webp";
import { SuperSEO } from "react-super-seo";
import Casebanner from "../CaseBanner/Casebanner";

const OutlookAddon = () => {
    return (
        <>
            <SuperSEO
                title="OutlookAddon - Lakebrains"
                description="Elevate your email communication effortlessly."
                lang="en"
                twitter={{
                    twitterSummaryCard: {
                        summaryCardImage: { Bannnerimage },
                        summaryCardImageAlt: "OutlookAddon",
                        summaryCardSiteUsername: "OutlookAddon",
                    },
                }}
            />


            {data.map((items, key) => (
                <Casebanner key={key} data={items.data} />
            ))}
        </>
    );
};

export default OutlookAddon;
