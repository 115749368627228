import React from "react";
import { Helmet } from "react-helmet";
import Casebanner from "./CaseBanner/Casebanner";
import data from "./casestudyInteractproExt";

const InteractExtCaseStudy = () => {
  return (
    <>
      <Helmet>
        {/* <!-- Primary Meta Tags --> */}
        <title>Interact Extension - Lakebrains</title>
        <link rel="canonical" href="https://lakebrains.com/casestudy/interact-extension" />
        <meta name="title" content="Interact Chrome Extension" />
        <meta name="description" content="Empower your sales team to build stronger relationships and close more deals with our super-charged AI Chrome extension for sales reps and robust web app for sales managers." />
        <meta name="keywords" content="Interact Chrome Extension, how to empower sales team to build strong relationship," />
        <meta name="robots" content="index, follow" />
        <meta http-equiv="Content-Type" content="text/html; charset=utf-8" />
        <meta name="language" content="English" />
        <meta name="revisit-after" content="8 days" />



        {/* <!-- Open Graph / Facebook --> */}
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://lakebrains.com/casestudy/interact-extension/" />
        <meta property="og:title" content="Interact Chrome Extension" />
        <meta property="og:description" content="Empower your sales team to build stronger relationships and close more deals with our super-charged AI Chrome extension for sales reps and robust web app for sales managers. " />
        <meta property="og:image" content="" />

        {/* <!-- Twitter --> */}
        <meta property="twitter:card" content="summary_large_image" />
        <meta property="twitter:url" content="https://lakebrains.com/casestudy/interact-extension/" />
        <meta property="twitter:title" content="Interact Chrome Extension" />
        <meta property="twitter:description" content="Empower your sales team to build stronger relationships and close more deals with our super-charged AI Chrome extension for sales reps and robust web app for sales managers." />
        <meta property="twitter:image" content="" />
      </Helmet>
      {data.map((items, key) => (
        <Casebanner key={key} data={items.data} />
      ))}
    </>
  );
};

export default InteractExtCaseStudy;
