import React from "react";
import './Services.css';
const Services = ({ subservices }) => {
  return (
    <div className="container subservices-main-div p-0">
      <h1 className="subserviceh">Services</h1>

      <div className="justify-content-center R1-ss w-100">
        {subservices.map((subservices) => {
          return (
              <div className="mb-4  text-center subservice-div">
                <div className="innerdiv">
                  <img src={subservices.img} alt="" className="subserviceimg" loading="lazy"/>
                  <h1 className="Img-heading">{subservices.heading}</h1>
                  <p>{subservices.para}</p>
                </div>
              </div>
          );
        })}
      </div>
    </div>

  );
};

export default Services;
