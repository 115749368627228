import React from "react";
import "./blogs.css";
import BlogImage from "../../../images/chrome-extension-develpment/BBlog1 1.svg";
import Blog1 from '../../../images/chrome-extension-develpment/demo1.png';
import Blog2 from '../../../images/chrome-extension-develpment/demo2.png';
import Blog3 from '../../../images/chrome-extension-develpment/demo3.webp';
import Blog4 from '../../../images/chrome-extension-develpment/demo4.png';
import Blog5 from '../../../images/chrome-extension-develpment/demo5.png';
import Btn1 from "../../../images/chrome-extension-develpment/Frame 39.svg";
import Btn2 from "../../../images/chrome-extension-develpment/Frame 38.svg";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleArrowLeft, faCircleArrowRight } from '@fortawesome/free-solid-svg-icons';

const Slideshow = () => {
  const slides = [
    {
      image: Blog1 ,
      button: 'Extension',
      head: " Message Passing in Chrome Extension",
      text: "As a developer, understanding the concept of message passing is crucial to creating successful Chrome extensions. By allowing different components of an extension to communicate with each other seamlessly, this technique improves functionality and enhances user experience. With message passing, content scripts can interact with background pages and exchange data, trigger events, and respond to …",
      url : 'https://blogs.lakebrains.com/taste-safe-sensory-nulla-dignissim/',
    },
    {
      image: Blog2 ,
      button: 'Extension',
      head: "How do I use React in the content script Chrome extension?",
      text: "Introduction to Chrome extensions: What Chrome extensions are and their purpose. The Google Chrome web browser can be enhanced through small software programs known as Chrome extensions. By introducing new features, integrating with other services or modifying web pages, users can personalise their browsing experience. Components of a Chrome extension : The manifest file (manifest.json) …",
       url : 'https://blogs.lakebrains.com/how-do-i-use-react-in-the-content-script-chrome-extension/',
   
    },
    {
      image: Blog3 ,
      button: 'Extension',
      head: "Benefits of using a Google Chrome extension for productivity, privacy, and security",
      text: "We fortunate enough to work on something extra ordinary where we have build the browser extension which is having AI capability like live behavior analysis, transcription, noise . Our team will keep pushing our limits in build more smart browser extensions.",
      url : 'https://blogs.lakebrains.com/benefits-of-using-a-google-chrome-extension-for-productivity-privacy-and-security/',
    
    },
    {
      image: Blog4,
      button: 'Extension',
      head: "Debugging and Testing Tips for Chrome Extensions Written in JavaScript",
      text: "Overview: Debugging plays a vital role when working with JavaScript-based Chrome extensions to guarantee their optimal performance. It facilitates the detection and rectification of any faults that may emerge during either the development or implementation of these extensions. Through debugging, programmers can keep track of the program’s execution path, analyze varying variables, and determine the … ",
      url : 'https://blogs.lakebrains.com/debugging-and-testing-tips-for-chrome-extensions-written-in-javascript/',
   
    },
    {
      image: Blog5,
      button: 'Extension',
      head: "Difference between Content script, background script, Pop script & Option script.",
      text: "Abde Ali – MERN Stack Developer Overview: In Chrome extensions v3, there are four primary types of scripts: content scripts, background scripts, popup scripts, and options scripts. Content scripts run in the context of web pages and can modify the DOM and interact with page content. They enable developers to inject custom functionality into specific …",
       url : 'https://blogs.lakebrains.com/difference-between-content-script-background-script-pop-script-option-script/',
   
    },
   
  ];


  const [current, setCurrent ] = React.useState(0);

  const nextSlide = () => {
    setCurrent( current === slides.length -1 ? 0 : current + 1);
  }

  const prevSlide = () => {
    setCurrent ( current === 0 ?   slides.length-1 : current - 1  );
  }

  return(
    <div className="slid-main ">
    <div className='test-slider'>

    {slides.map((slide,index) => {
      return (
        <div className="blog-cards"  key={index}
        style={{display:index === current ? 'block' : 'none'}} > 


            <div className="blog-c">
          <img className='blog-img img-fluid' src={slide.image} alt="slide"/>
          <div className="blog-t">
          <button className="blog-card-btn"  >{slide.button}</button>
            <h1 className="blog-h22">{slide.head}</h1>
            <span className="blog-p2">{slide.text}</span> 
             
             <p className="read-more"><a className="read" href={slide.url}>
               Read More
             </a></p>
            </div>
           </div>
        
        
      
        </div>
      )
    }


    )}
    </div>
         
    <div className='test-slid-btns'>
    
    <FontAwesomeIcon className="left1" icon={faCircleArrowLeft} onClick={prevSlide}></FontAwesomeIcon> 
    <FontAwesomeIcon className="right1" icon={faCircleArrowRight} onClick={nextSlide}></FontAwesomeIcon>
  
    </div>
    </div>
  )
}





function blogs() {
  // const responsive = {
  //   desktop: {
  //     breakpoint: { max: 3000, min: 1024 },
  //     items: 1,
  //     slidesToSlide: 1,
  //   },
  //   tablet: {
  //     breakpoint: { max: 1204, min: 800 },
  //     items: 1,
  //     slidesToSlide: 1,
  //   },
  //   smalltablet: {
  //     breakpoint: { max: 800, min: 550 },
  //     items: 1,
  //     slidesToSlide: 1,
  //   },
  //   mobile: {
  //     breakpoint: { max: 550, min: 0 },
  //     items: 1,
  //     slidesToSlide: 1,
  //   },
  // };
  return (
    <>
      <div className="blogs-main  container">
        <div className="blog-texts">
          <h2 className="blog-h">Lakebrains Blogs</h2>
          <h1 className="blog-h2">Stories of Success and innovation</h1>
          <p className="blog-p">
            Discover the rearmost trends and stylish practices in Chrome
            extension development. Subscribe now to stay informed!
          </p>
        </div>
        {/* <Carousel
          swipeable={true}
          draggable={true}
          //   showDots={true}
          responsive={responsive}
          ssr={true} // means to render carousel on server-side.
          infinite={true}
          autoPlay={true}
          autoPlaySpeed={10000}
          keyBoardControl={true}
          customTransition="all 1s ease-in-out"
          transitionDuration={1000}
          containerclassName="carousel-container12"
          removeArrowOnDeviceType={["tablet", "mobile"]}
          //   deviceType={props.deviceType}
          dotListclassName="custom-dot-list-style1"
          className="backg"
        >
          <div className="blog-cards">
            <div className="blog-c">
              <div className="img-div">
                <img className="blog-img img-fluid"  alt="Bimg"  src={Blog1} />
              </div>
              <div className="blog-t">
                <button className="btn blog-card-btn">EXTENSION </button>
                <h1 className="blog-h22">
                Topic Modeling with LDA using Python
                </h1>
                <p className="blog-p2">
                Topic Modeling with LDA using Python In this blog, we’ll cover Topic Modeling with LDA using Python. Topic modeling is a type of statistical modeling for discovering the abstract “topics” that occur in a collection of documents. What is Topic Modeling? Topic Models are, in a nutshell, a type of statistical language model used for …
                </p>
                <a href="https://blogs.lakebrains.com/topic-modeling-with-lda-using-python/">Read More</a>
              </div>
            </div>
          </div>
          <div className="blog-cards">
            <div className="blog-c">
              <div className="img-div">
                <img className="blog-img img-fluid" alt="Bimg" src={Blog2} />
              </div>
              <div className="blog-t">
                <button className="btn blog-card-btn">EXTENSION </button>
                <h1 className="blog-h22">
                Problems Faced by NLP Practitioners and Developers
                </h1>
                <p className="blog-p2">
                Chrome extensions have become increasingly popular among internet users due to the many benefits they offer. Not only can they help improve productivity, but they also provide enhanced privacy and security. Chrome extensions are small software applications that extend the capabilities of Google’s Chrome browser. For instance, these extensions make it easier for users to …
                </p>
                <a href="https://blogs.lakebrains.com/problems-faced-by-nlp-practitioners-and-developers/">Read More</a>
              </div>
            </div>
          </div>
          <div className="blog-cards">
            <div className="blog-c">
              <div className="img-div">
                <img className="blog-img img-fluid" alt="Bimg"  src={Blog3} />
              </div>
              <div className="blog-t">
                <button className="btn blog-card-btn">EXTENSION </button>
                <h1 className="blog-h22">
                Benefits of using a Google Chrome extension for productivity, privacy, and security
                </h1>
                <p className="blog-p2">
                  We fortunate enough to work on something extra ordinary where
                  we have build the browser extension which is having AI
                  capability like live behavior analysis, transcription, noise .
                  Our team will keep pushing our limits in build more smart
                  browser extensions.
                </p>
                <a href="https://blogs.lakebrains.com/benefits-of-using-a-google-chrome-extension-for-productivity-privacy-and-security/">Read More</a>
              </div>
            </div>
          </div>
          <div className="blog-cards">
            <div className="blog-c">
              <div className="img-div">
                <img className="blog-img img-fluid" src={Blog4} />
              </div>
              <div className="blog-t">
                <button className="btn blog-card-btn">EXTENSION </button>
                <h1 className="blog-h22">
                (Natural Processing Language) NLP IN SaaS
                </h1>
                <p className="blog-p2">

In this article, we’ll take a look at how NLP can be used in software-as-a-service (SaaS) applications. We’ll explore some of the benefits of using NLP in SaaS applications and also look at some of the challenges that need to be considered when using this technology.Lakebrains Technologies is an NLP service provider company in India. …
                </p>
                <a href="https://blogs.lakebrains.com/natural-processing-language-nlp-in-saas/">Read More</a>
              </div>
            </div>
          </div>
          <div className="blog-cards">
            <div className="blog-c">
              <div className="img-div">
                <img className="blog-img img-fluid" alt="Bimg" src={Blog5} />
              </div>
              <div className="blog-t">
                <button className="btn blog-card-btn">EXTENSION </button>
                <h1 className="blog-h22">
                
                Natural Language Processing / NLP Chatbots</h1>
                <p className="blog-p2">
                Natural Language Processing / NLP Chatbots
Natural Language Processing / June 21, 2023
NLP Chatbots
In this blog post, we’ll explore what NLP is, why your business needs an NLP chatbot, and how to get started with one. NLP chatbots are typically designed as customer service or weather information. In order to understand user input. NLP Chatbots: Why Your Business Needs Them Why does a Business need NLP based Chatbot? …
                </p>
                <a href="https://blogs.lakebrains.com/natural-language-processing-nlp-chatbots/">Read More</a>
              </div>
            </div>
          </div>
         
        </Carousel> */}
        <Slideshow/>
      </div>
    </>
  );
}

export default blogs;
