import React from "react";
import "./testimonial.css";
import Btn1 from "../../../images/chrome-extension-develpment/Frame 39.svg";
import Btn2 from "../../../images/chrome-extension-develpment/Frame 38.svg";
import Ceo1 from "../../../images/chrome-extension-develpment/Eric_Hansen 1.svg";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleArrowLeft, faCircleArrowRight } from '@fortawesome/free-solid-svg-icons';
import Ceo2 from '../../../images/chrome-extension-develpment/ceo1.svg';
import Ceo3 from '../../../images/chrome-extension-develpment/ceo2.svg';
import Ceo4 from '../../../images/chrome-extension-develpment/ceo3.svg';




const Slideshow = () => {
  const slides = [
    {
      image: Ceo1,
      head:  'Eric Hansen',
      text: ' Lakebrains has helped us develop several AI software products and websites, they have exeeded our expectations. We will continue to work with lakebrains in the future. '
     
    
    
    },
    {
      image: Ceo2, 
      head: 'Jack',
      text: ' So, as we have built out my application Interact Technologies, which will be released shortly after the new year, they have taken over all programming, such as Website Development, Chrome Extension, NLP, Keyword Extraction, and on the Back-end, building out HubSpot CMS, and whats nice is that everything is communicating really well. I think probably the best part of working with LakeBrains Technologies is the relationship that we have built that goes way beyond the Client- Vendor Relationship.',
 
    },
    {
      image: Ceo3,
      head: ' Joseph Ugalde',
      text:  ' We worked with Chitransh and team to develop a Chrome Extention that allows one of our customers to add more functionalities inside their Cloud ERP system. The communication was clear, professional and the timing always was right. Their guidance and support through the project was key to have a happy customer. Keep it up the good work.',
     
    }  ,
    {
      image: Ceo4,
      head: 'BCG',
      text: ' Overall, I was very happy with the Data Scraping. The quality of the data was up to the mark and encapsulated everything that I had asked for. You guys also had a great turnaround time for the job. Thanks for all the help!',
    },
  ];
  const [current, setCurrent] = React.useState(0);

  const nextSlide = () => {
    setCurrent(current === slides.length - 1 ? 0 : current + 1);
  }
  
  const prevSlide = () => {
    setCurrent(current === 0 ? slides.length - 1 : current - 1);
  }

  return (
    <div className="slid-main ">
    <div className='test-slider'>
   
  

      {slides.map((slide, index) => {
        return (
          <div className="test-2   "
            key={index}
            style={{display: index === current ? 'block' : 'none'}}
          >
         
            
            <span className="test-2p">{slide.text}</span>  
            <div className="ceo-idiv">
          <img className='ceo-image' src={slide.image} alt="slide"/>
          <div className="t-div">
            <h1 className="ceo-name">{slide.head}</h1>
            <span className="CEO">CEO</span>

            </div>
           </div>

          </div>
        )
      })}
      <div className='test-slid-btns'>
    
    <FontAwesomeIcon className="left1" icon={faCircleArrowLeft} onClick={prevSlide}></FontAwesomeIcon> 
    <FontAwesomeIcon className="right1" icon={faCircleArrowRight} onClick={nextSlide}></FontAwesomeIcon>
  
    </div>
    </div>
    </div>
    
  );
}

function testimonial() {
  // const responsive = {
  //   desktop: {
  //     breakpoint: { max: 3000, min: 1024 },
  //     items: 1,
  //     slidesToSlide: 1,
  //   },
  //   tablet: {
  //     breakpoint: { max: 1204, min: 800 },
  //     items: 1,
  //     slidesToSlide: 1,
  //   },
  //   smalltablet: {
  //     breakpoint: { max: 800, min: 550 },
  //     items: 1,
  //     slidesToSlide: 1,
  //   },
  //   mobile: {
  //     breakpoint: { max: 550, min: 0 },
  //     items: 1,
  //     slidesToSlide: 1,
  //   },
  // };
  return (
    <div className="container">
      <div className="test-main">
        <div className="test-1">
          <h1 className="test-h1">OUR TESTIMONIALS</h1>
          <h2 className="test-h2">Clients Says About Us</h2>
          <p className="test-p">
            One of the most notable examples is the rise of artificial
            intelligence (AI). Lakebrains helped startups to automate various
            tasks, such as customer service, marketing, sales through AI.
          </p>

          {/* <div className='test-btns'>
            <button type='button' className='btn' > <img src={Btn1}/></button>
<button className='c-btn' > <img src={Btn2}/></button>
            </div> */}
        </div>
        <Slideshow/>
        {/* <Carousel
          swipeable={true}
          draggable={true}
          //   showDots={true}
          responsive={responsive}
          ssr={true} // means to render carousel on server-side.
          infinite={true}
          autoPlay={true}
          autoPlaySpeed={10000}
          keyBoardControl={true}
          customTransition="all 1s ease-in-out"
          transitionDuration={1000}
          containerclassName="carousel-container12"
          removeArrowOnDeviceType={["tablet", "mobile"]}
          //   deviceType={props.deviceType}
          dotListclassName="custom-dot-list-style1"
          itemclassName="carousel-item-padding-40"
          className="backg"
        >
          <div className="back_in">
            <div className="test-2">
              <p className="test-2p">
              Lakebrains has helped us develop several AI software products and websites, they have exeeded our expectations. We will continue to work with lakebrains in the future.
              </p>
              <div className="ceo-div">
                <div className="ceo-idiv">
                  <img className="ceo-image" src={Ceo1} />
                </div>
                <div className="ceo-t">
                  <span className="ceo-name">Eric Hansen </span>
                  <br />
                  <span className="CEO">CEO</span>
                </div>
                </div>
              </div>
            </div>
            <div className="back_in">
            <div className="test-2">
              <p className="test-2p">
              Overall, I was very happy with the Data Scraping. The quality of the data was up to the mark and encapsulated everything that I had asked for. You guys also had a great turnaround time for the job. Thanks for all the help!
              </p>
              <div className="ceo-div">
                <div className="ceo-idiv">
                  <img className="ceo-image" alt="Ceo"  src={Ceo4} />
                </div>
                <div className="ceo-t">
                  <span className="ceo-name">BCG </span>
                  <br />
                  <span className="CEO">CEO</span>
                </div>
              </div>
            </div>
        
          </div>
          <div className="back_in">
            <div className="test-2">
              <p className="test-2p">
              We worked with Chitransh and team to develop a Chrome Extention that allows one of our customers to add more functionalities inside their Cloud ERP system. The communication was clear, professional and the timing always was right. Their guidance and support through the project was key to have a happy customer. Keep it up the good work.
              </p>
              <div className="ceo-div">
                <div className="ceo-idiv">
                  <img className="ceo-image" alt="CEO" src={Ceo3} />
                </div>
                <div className="ceo-t">
                  <span className="ceo-name">Joseph Ugalde </span>
                  <br />
                  <span className="CEO">CEO</span>
                </div>
              </div>
            </div>
            </div>
            <div className="back_in">
            <div className="test-2">
              <p className="test-2p">
              So, as we've built out my application Interact Technologies, which will be released shortly after the new year, they've taken over all programming, such as Website Development, Chrome Extension, NLP, Keyword Extraction, and on the Back-end, building out HubSpot CMS, and what's nice is that everything is communicating really well. I think probably the best part of working with LakeBrains Technologies is the relationship that we have built that goes way beyond the Client- Vendor Relationship.
              </p>
              <div className="ceo-div">
                <div className="ceo-idiv">
                  <img className="ceo-image"  alt="CEO" src={Ceo2} />
                </div>
                <div className="ceo-t">
                  <span className="ceo-name">Jack </span>
                  <br />
                  <span className="CEO">CEO</span>
                </div>
              </div>
            </div>
            </div>
      

        
        </Carousel> */}
      </div>
    </div>
  );
}

export default testimonial;
