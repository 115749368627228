import Bannnerimage from "../../../../images/CaseStudiesImagesLogo/gnote.png";


const data = [
  {
    slugurl: "/Gnote",
    data: [
      {
        Banner: [
          {
            sector:"Productivity/ Technology",
            headingtext: "G-note: Note taking App",
            headingpara:
              "G-note is an AI-powered note-capturing app that frees you from tedious note taking.",
            image: Bannnerimage,
          },
        ],
        heading:
          "Focus on meetings, not note-taking",
        casestudy: {
          Problem:
            `Note-taking during meetings can be a painstaking process that often slows you down. So, the next time you consider taking notes during a meeting, install G-note. While G-note takes care of note-taking, you can fully focus on your meetings.`,
          challenge: [
            {
              heading: "How to set up G-note:- ",
              challengeli: [
                { First: "Install the G-note plugin." },
                { First: "Register with your gmail account." },
                { First: "Enjoy!" },
              ],
            },
            {
              heading: "Who uses G-note:-",
              challengeli: [
                { First: "Product, design, and engineering teams" },
                { First: "Creative teams, freelancers, and agencies." },
                { First: "HR managers." },
                { First: "Customer support teams." },
                { First: "Company executives." },
              ],
            },
            {
              heading: "The Challenges:-",
              challengeli: [
                { First: "Capture captions from google meet in real time without any user interaction." },
                { First: "Algorithm for Notes Generation , Roadblocks and Action Items." },
                { First: "Easy authentication through google" },
                { First: "Send a formatted mail to user" },
              ],
            },
            {
              heading: "Solutions:-",
              challengeli: [
                { First: "Capturing google meet captions from google meet without any user interaction and without any UI changes of google meet by using webRTC." },
                { First: "Using OpenAi’s chatGpt and LangChain to generate accurate meeting notes, Action Items and Roadblocks." },
                { First: "Using chrome.identity api for google OAuth Authentication." },
                { First: "Sending all meeting notes to users in a well documented manner through nodemailer and HBS npm packages." },
              ],
            },
          ],
        },

        companyinfo: {
          logourl:
            "yasmin",
          about: "About ",
          aboutpara:
            `G-note is an AI-powered note-capturing app that frees you from tedious note taking. It captures and sends your meeting notes privately to your registered email within minutes after the call. The app provides a "summary", "action items", and highlights any "roadblocks" discussed during the meeting in a text format. G-note doesn't store any audio, video, transcripts, or other call-related data.`,
          companysector: [
            {
              first: "Company Name:",
              secound: "GNote",
            },
            {
              first: "INDUSTRY:",
              secound: "Productivity",
            },
            {
              first: "LOCATION:",
              secound: "Udaipur, Rajasthan",
            },
          ],
        },
        customer: [

        ],
      },
    ],
  },
];

export default data;
