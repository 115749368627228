import React, { useState } from "react";
import OurCommitment from "../../Comman/NewHomeComponent/OurCommitment/OurCommitment";
import OurBenefits from "../../Comman/NewHomeComponent/OurBenefits/OurBenefits";

import Experienced from "../../../images/NewHomePage/Experienced.svg";
import USACulture from "../../../images/NewHomePage/USACulture.svg";
import IndianCost from "../../../images/NewHomePage/IndianCost.svg";
import TopQualityCode from "../../../images/NewHomePage/TopQualityCode.svg";
import USAIndiaTime from "../../../images/NewHomePage/USAIndiaTime.svg";

import OurApproach from "../../Comman/NewHomeComponent/OurApproach/OurApproach";
import OurCulture from "../../Comman/NewHomeComponent/OurCulture/OurCulture";
import Home from "../HomePage/home/home";
import Companies from "../../Comman/Companies/Companies";
import DriveMetrics from "../../Comman/Drivematrix/DriveMetrics";
// import Service from "../HomePage/ServiceSection/services-section";
import CaseStudySlider from "../../Comman/NewHomeComponent/CaseStudySlider/CaseStudySlider";
import ProblemSolving from "../../Comman/NewHomeComponent/ProblemSolving/ProblemSolving";
import OurServices from "../../Comman/NewHomeComponent/OurServices/OurServices";
import ProblemSolvingImg from "../../../images/SubPages/OurTestimonials/ProblemSolvingImg.png";
import LiveTranscript from "../../../images/SubPages/OurTestimonials/LiveTranscript.png";
import Squire from "../../../images/SubPages/OurTestimonials/Squire.png";
import Floomy from "../../../images/SubPages/OurTestimonials/Floomy.png";
import Interact from "../../../images/SubPages/OurTestimonials/Interact.png";
import Gnote from "../../../images/SubPages/OurTestimonials/Gnote.png";
import Datalinx from "../../../images/SubPages/OurTestimonials/Datalinx.png";
import Dropscale from "../../../images/SubPages/OurTestimonials/Dropscale.png";
import FormPopup from "../../FormPopup/FormPopup";



const OurBenefit = [
  {
    heading: (
      <>
        Experienced Team
      </>
    ),
    alt: "Experienced Team",
    para: "",
    image: Experienced,
  },
  {
    heading: (
      <>
        USA Culture
      </>
    ),
    alt: "USA Culture",
    para: "",
    image: USACulture,
  },
  {
    heading: (
      <>
        Indian Cost
      </>
    ),
    alt: "Indian Cost",
    para: "",
    image: IndianCost,
  },
  {
    heading: (
      <>
        Top quality code
      </>
    ),
    alt: "Top quality code",
    para: "",
    image: TopQualityCode,
  },
  {
    heading: (
      <>
        USA and India time availability
      </>
    ),
    alt: "USA and India time availability",
    para: "",
    image: USAIndiaTime,
  },
];

const ProblemSolvingData = [
  {
    subHeading: "Recruitment/ Technology",
    heading: "Squire Chrome Extension",
    photo: Squire,
    description:
      "Squire's Chrome Extension lets you easily capture conversations with candidates and push the recording to the Squire application for processing, automatically updating your ATS with the relevant candidate information and an AI-generated recruiter write-up.",
    btntext: "View More",
    caseurl: "/casestudy/squire/",
  },
  {
    subHeading: "Communication/ Technology",
    heading: "Interact Hubspot Website",
    photo: Interact,
    description:
      "Empower your sales team to build stronger relationships and close more deals with our super-charged AI Chrome extension for sales reps and robust web app for sales managers.",
    btntext: "View More",
    caseurl: "/casestudy/interact/",
  },
  {
    subHeading: "Recruitment/ Technology",
    heading: "Squire: Zoom Marketplace",
    photo: Squire,
    description:
      "Squire's Zoom Marketplace App lets you easily capture conversations with candidates and push the recording to the Squire application for processing, automatically updating your ATS with the relevant candidate information and an AI-generated recruiter write-up.",
    btntext: "View More",
    caseurl: "/casestudy/SquireZoom/",
  },
  {
    subHeading: "Learning/ Communication",
    heading: "Live Transcript Extension",
    photo: LiveTranscript,
    description:
      "You could want to record lectures from online meetings, online courses, podcasts, and streaming YouTube videos by our Live Transcript Extension",
    btntext: "View More",
    caseurl: "/casestudy/capture-transcript-from-a-meet/",
  },
  {
    subHeading: "Productivity/ Technology",
    heading: "Linkedin Sales Navigator",
    photo: ProblemSolvingImg,
    description:
      "Linkedin Sales Navigator Data Scraping is a puppeteer script to scrap people data from sales navigators.",
    btntext: "View More",
    caseurl: "/casestudy/LinkedinSalesNavigator/",
  },
  {
    subHeading: "Productivity/ Technology",
    heading: "G-note: Note taking App ",
    photo: Gnote,
    description:
      "G-note is an AI-powered note-capturing app that frees you from tedious note taking.",
    btntext: "View More",
    caseurl: "/casestudy/Gnote/",
  },
  {
    subHeading: "Recruitment/ Technology",
    heading: "Squire Ringcentral App",
    photo: Squire,
    description:
      "Squire's Ringcentral app lets you easily capture conversations with candidates and push the recording to the Squire application for processing, automatically updating your ATS with the relevant candidate information and an AI-generated recruiter write-up.",
    btntext: "View More",
    caseurl: "/casestudy/SquireRingCentral/",
  },
  {
    subHeading: "Productivity/ Technology",
    heading: "Floomy",
    photo: Floomy,
    description:
      "AI Meeting Assistant Tool to Generate Minutes Of Meeting for Google Meet.",
    btntext: "View More",
    caseurl: "/casestudy/floomy/",
  },
  {
    subHeading: "Productivity/ Technology",
    heading: "Datalix",
    photo: Datalinx,
    description:
      "Datalix Chrome Extension helps users to find Ecommerce Companies and Contact Information.",
    btntext: "View More",
    caseurl: "/casestudy/datalix/",
  },
  {
    subHeading: "Productivity/ Technology",
    heading: "Dropscale ",
    photo: Dropscale,
    description:
      "Sharing aliexpress product detail with dropscale to manage them at one place",
    btntext: "View More",
    caseurl: "/casestudy/dropscale/",
  },

];

const NewHomePage = () => {
  const [showPopUp, setShowPopUp] = useState(false);
  return (
    <>
      <Home />
      <FormPopup showPopUp={showPopUp} setShowPopUp={setShowPopUp} onClick={() => setShowPopUp(false)} />
      <Companies />
      <DriveMetrics />
      <OurServices />
      <OurCommitment />
      <OurBenefits archive={OurBenefit} subHeading="Our Benefits" heading="WHY US?" description="Over the years, our team has tested and implemented transparent and efficient workflows for various AI projects. Our process allows our customers to receive reproducible results faster and more flexibly" />
      <OurApproach />
      <OurCulture />
      <ProblemSolving ProblemSolvingData={ProblemSolvingData} />
      <CaseStudySlider />
    </>
  );
};

export default NewHomePage;
