import Bannnerimage from "../../../../images/CaseStudiesImagesLogo/DaisyZendesk.png";

const data = [
  {
    slugurl: "/DaisyZendeskExtension",
    data: [
      {
        Banner: [
          {
            sector: "AI/ ML",
            headingtext: "Daisy Zendesk Extension",
            headingpara:
              "The Zendesk Thread Summarizer Chrome extension boosts workflow efficiency by leveraging OpenAI's GPT-4 and Daisy AI API to extract and  summarise ticket thread information.",
            image: Bannnerimage,
          },
        ],
        heading:
          "Enhance Zendesk Workflow with GPT-4 and Daisy AI-Powered Thread Summarizer Chrome Extension.",
        casestudy: {
          Problem:
            `"The Zendesk Thread Summarizer is a Chrome extension designed to enhance your Zendesk workflow by integrating OpenAI's GPT-4 API. Also we are using the Third party Daisy AI API in order to get the latest active threads.This extension provides features to extract, summarise, and organise information from ticket threads."`,
          challenge: [
            {
              heading: "Why To Use:",
              challengeli: [
                { First: "Retrieves responses from OpenAI for the latest message in a ticket thread." },
                { First: "Thread Extraction:Automatic extraction of sender names and messages for improved communication analysis." },
                { First: "Give a summary , main points and next steps of the retrieved response." },
                { First: "User-friendly Chrome Extension UI occupying 1/3rd of the screen in a vertical layout." },
                { First: "Task Tracking:Visual indication of completed tasks using checkmarks (✓) on the UI." },
              ],
            },
            {
              heading: "How To Use It:-",
              challengeli: [
                { First: "Install the Daisy zendesk Chrome Extension" },
                { First: "Pin the extension and click on it to open the extension" },
                { First: "Authenticate yourself By signing Up" },
                { First: "Make sure to open the zendesk webpage" },
                { First: "Create a thread on the zendesk webpage" },
                { First: "The extension automatically takes the thread and gives the required summary." },
              ],
            },
            {
              heading: "The Challenges:-",
              challengeli: [
                { First: "Scraping the Threads/Messages." },
                { First: "Distinguishing between multiple Users and identifying their threads correctly." },
                { First: "Ensuring a seamless experience ." },
              ],
            },
            {
              heading: "The Approaches:",
              challengeli: [
                { First: "Used the required chrome APIs in order to scrap the Threads correctly." },
                { First: "Mapped the data with username to correctly identify between the user’s and their threads." },
                { First: "Formulates a list of recommended next steps based on tasks mentioned in the ticket thread." },
              ],
            },
          ],
        },

        companyinfo: {
          logourl:
            "Bannnerimage",
          about: "About ",
          aboutpara:
            "The Zendesk Thread Summarizer is a Chrome extension utilizing OpenAI's GPT-4 API to streamline Zendesk workflows. It extracts and organizes ticket thread information efficiently. ",
          companysector: [
            {
              first: "Company Name:",
              secound: "Daisy Zendesk",
            },
            {
              first: "INDUSTRY:",
              secound: "AI/ ML",
            },
          ],
        },
        customer: [

        ],
      },
    ],
  },
];

export default data;
