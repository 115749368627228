import Bannnerimage from "../../../../images/logo/logo_lakebrains.webp";

const data = [
  {
    slugurl: "/ChatGPT-QA",
    data: [
      {
        Banner: [
          {
            sector: "AI/ ML",
            headingtext: "ChatGPT Q & A",
            headingpara:
              "Our AI-driven product simplifies the analysis of PDF documents by generating comprehensive summaries and answering user queries related to the content.",
            image: Bannnerimage,
          },
        ],
        heading:
          "Effortlessly generate summaries and answer questions from PDFs using AI.",
        casestudy: {
          Problem:
            `Our AI product streamlines PDF document analysis by providing automated summaries and facilitating Q&A interactions. Utilising AI technologies, it allows users to effortlessly extract key information and get answers to specific questions, improving efficiency and understanding of complex documents.`,
          challenge: [
            {
              heading: "Why Use Our Product?",
              challengeli: [
                { First: "Extract concise summaries from lengthy PDFs for quick comprehension." },
                { First: "Engage with the document content by asking targeted questions." },
                { First: "Enhance productivity by automating the document analysis process." },
                { First: "Leverage AI for accurate and contextually relevant information retrieval." },
              ],
            },
            {
              heading: "How to Use Our Product:",
              challengeli: [
                { First: `Upload a PDF document to the platform.` },
                { First: "Receive an automatically generated summary of the document's content." },
                { First: "Ask specific questions related to the PDF to get precise answers." },
                { First: `Use the insights and answers to inform business decisions, research, or customer service.` },
              ],
            },
            {
              heading: "Challenges:",
              challengeli: [
                { First: `Ensuring the accuracy and relevance of generated summaries.` },
                { First: "Handling a wide range of document content types." },
                { First: "Integrating seamlessly with any other existing workflows and tools." },
              ],
            },
            {
              heading: "Approaches:",
              challengeli: [
                { First: `Implementing cutting-edge AI and natural language processing algorithms for precise content analysis.` },
                { First: "Developing a robust and flexible system to support various PDF formats and complexities." },
                { First: "Collaborating with users to continuously refine the product based on feedback and usage patterns." },
              ],
            },
            {
              heading: "Impact:",
              challengeli: [
                { First: `Our AI-driven PDF analysis product transforms the way businesses interact with and understand document content. By automating the summarization process and enabling interactive Q&A, it enhances productivity, accuracy, and decision-making, leading to better business outcomes.` },
              ],
            },
          ],
        },

        companyinfo: {
          logourl:
            Bannnerimage,
          about: "About",
          aboutpara:
            "ChatGPT Q&A is an AI-powered solution that streamlines PDF document analysis. It generates detailed summaries and responds to user queries regarding the content.",
          companysector: [
            {
              first: "Company Name:",
              secound: "Lakebrains Technologies",
            },
            {
              first: "INDUSTRY:",
              secound: "AI/ ML",
            },
            {
              first: "LOCATION:",
              secound: "Udaipur",
            },
          ],
        },
        customer: [

        ],
      },
    ],
  },
];

export default data;
