import React from "react";
import Casebanner from "../CaseBanner/Casebanner";
import data from "./WriteGPTOpenAIData";
import Bannnerimage from "../../../../images/casestudy/writeGPT.webp";
import { SuperSEO } from "react-super-seo";

const WriteGPTOpenAI = () => {
  return (
    <>

      <SuperSEO
        title="writeGPT - Lakebrains"
        description="GPT3.5 extension to facilitate effective prompts for emails, content creation, summarising, learning & engagement on any website."
        lang="en"
        twitter={{
          twitterSummaryCard: {
            summaryCardImage: { Bannnerimage },
            summaryCardImageAlt: "writeGPT",
            summaryCardSiteUsername: "writeGPT",
          },
        }}
      />


      {data.map((items, key) => (
        <Casebanner key={key} data={items.data} />
      ))}
    </>
  );
};

export default WriteGPTOpenAI;
