import React from "react";
import "./CaseStudyBanner.css";
import videoSource from '../../../images/Videos/Case Studies banner.mp4';



export default function CaseStudyBanner({ heading, subHeading, description }) {
  return (
    <>
      <div className="video-background">
        <video autoPlay loop muted>
          <source src={videoSource} type="video/mp4" />
        </video>
      </div>
      <div className="elementor-section w-100 vh-100 d-flex justify-content-center align-items-center ">
        <div className="elementor-element-populated  text-dark shadow border border-primary  transition p-sm-4 flex-wrap ">
          <div className="elementor-element-blog ml-md-3">
            {subHeading}
          </div>
          <div className="elementor-element-mtext mt-md-3 ml-md-3">
            {heading}
          </div>
          <div className="elementor-element-ltext mt-md-4 ml-md-3 mb-4">
            {description}
          </div>
        </div>
      </div>

    </>
  );
}
