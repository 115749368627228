import Bannnerimage from "../../../../images/casestudy/texthighlight.webp";
import Logo from "../../../../images/logo/logo_lakebrains.webp";

const data = [
  {
    slugurl: "/texthighlight",
    data: [
      {
        Banner: [
          {
            sector: "Productivity/ Email",
            headingtext: "PreScouter User-360",
            headingpara:
              "A solution for marketing, sales and other department executives of PreScouter to know about all the past interactions we have had with any specific lead/contact so that they can interact with that person accordingly without starting all over again",
            image: Bannnerimage,
          },
        ],
        heading:
          "Gmail Add-on to get the past interactions we have had with any specific lead/contact.",
        casestudy: {
          Problem:
            `"The marketing, sales, and other department executives require a solution that enables them to access comprehensive information about our historical engagements with specific leads and contacts."`,
          challenge: [
            {
              heading: "How to use it:- ",
              challengeli: [
                { First: "When composing any mail or reading mail from inbox or sent." },
                { First: "Click on the add-on button in the right hand side or below the compose window." },
                { First: "It delivers all the past interactions you or your organization had with him or his organization." },
              ],
            },
            {
              heading: "The Challenges:- ",
              challengeli: [
                { First: `It's not possible to trigger an add-on based on ‘onChange’ or ‘onEdit’.` },
                { First: "There is also no possibility for updating any kind of UI on the compose window." },
                { First: "And also we can not read recipient details from the compose window." },
              ],
            },
            {
              heading: "The Approaches:-",
              challengeli: [
                { First: "The approach that we used here was we captured the recipient email from the latest draft." },
                { First: "We created two separate sections for draft and inbox/sent." },
              ],
            },
            {
              heading: "Impact",
              challengeli: [{ First: ` By using this add-on for your organization you can have a huge impact on the productivity of all your departments. Whenever they want to send a mail to anyone outside your organization they can look for the person who had last communicated with him. You can also get the others belonging to the same organization as him and add them as recipient and this goes for both composing or reading an email.` },],
            },
          ],
        },

        companyinfo: {
          logourl:
            Logo,
          about: "About ",
          aboutpara:
            "A solution for marketing, sales and other department executives of PreScouter to know about all the past interactions we have had with any specific lead/contact so that they can interact with that person accordingly without starting all over again.",
          companysector: [
            {
              first: "Company Name:",
              secound: "PreScouter",
            },
            {
              first: "INDUSTRY:",
              secound: "Gmail Add-on",
            },
            {
              first: "LOCATION:",
              secound: "Chicago, United States",
            },
          ],
        },
        customer: [
     
        ],
      },
    ],
  },
];

export default data;
