import React, { useState } from "react";

// This is for backend section
import ServicesTopBanner from "../../ServicesTopBanner/ServicesTopBanner";
import ServicesBoxSection from "../../ServicesBoxSection/ServicesBoxSection";
import ServiceTechnologiesWeWork from "../../ServiceTechnologiesWeWork/ServiceTechnologiesWeWork";
// import TechnologiesWeWork from '../TechnologiesWeWork/TechnologiesWeWork';
import OurTestimonials from "../../../SubSection/ourtestimonials/OurTestimonials";

import Whyus from "../../../../Comman/Why Us/Whyus";

import ScheduleCall from "../../../../Comman/ScheduleCall/ScheduleCall";
import CaseStudySection from "../../../../Comman/CaseStudy/CaseStudy";
import FAQ from "../../../../Comman/FAQ/FAQ";
import { Helmet } from "react-helmet";
import { SuperSEO } from "react-super-seo";
import BlogSlider from '../../../BlogSlider/BlogSlider';
import AdvantagesOfServices from "../../BrowserExtension/AdvantagesOfServices";
// import Demo from '../../../Comman/HubContactForm/HubContactForm'
import SaasImage from '../../../../../images/plugindevelopmentSubPagesIcon/3.png'

// Case Study imgs imports 
import VoiceCommand from '../../../../../images/casestudy/VoiceCommand.webp'
import InteractExtension from '../../../../../images/casestudy/InteractExtension.webp'
import LiveTranscript from '../../../../../images/casestudy/LiveTranscript.webp'

// WHY AI Based Chrome Browser Extension images imports
import Increased_Engagement from '../../../../../images/chrome_extension_icons/IconGraphics/Increased_Engagement.svg'
import Improved_Productivity from '../../../../../images/chrome_extension_icons/IconGraphics/Improved_Productivity.svg'
import Enhanced_Security from '../../../../../images/chrome_extension_icons/IconGraphics/Enhanced_Security.svg'
import IncreasedCustomerSatisfaction from '../../../../../images/chrome_extension_icons/IconGraphics/IncreasedCustomerSatisfaction.svg'

const FrontendData = [
    {
        // image: next,
        h6: "Next.js",
        alt: "Next.js",
        link: "https://nextjs.org/",
    },
    {
        // image: react,
        h6: "React.js",
        alt: "React.js",
        link: "https://reactjs.org/",
    },
    {
        // image: javascript,
        h6: "Javascript",
        alt: "Javascript",
        link: "https://developer.mozilla.org/en-US/docs/Web/JavaScript",
    },
    {
        // image: extension,
        h6: "Extension",
        alt: "Extension",
        link: "https://chrome.google.com/webstore/category/extensions",
    },
    {
        // image: jquery,
        h6: "Jquery",
        alt: "Jquery",
        link: "https://jquery.com/",
    },
    {
        // image: html,
        h6: "Html5",
        alt: "Html5",
        link: "https://en.wikipedia.org/wiki/HTML",
    },
    {
        // image: css,
        h6: "Css3",
        alt: "Css3",
        link: "https://en.wikipedia.org/wiki/CSS",
    },
    {
        // image: bootstrap,
        h6: "Bootstrap",
        alt: "Bootstrap",
        link: "https://getbootstrap.com/",
    },
    {
        // image: less,
        h6: "Less",
        alt: "Less",
        link: "https://lesscss.org/",
    },
    {
        // image: sass,
        h6: "Sass",
        alt: "Sass",
        link: "https://sass-lang.com/",
    },
];

const Bdata = [
    {
        image: Increased_Engagement,
        title: "User-Friendly Content Management ",
        alt: "Increased engagement ",
        description:
            "HubSpot CMS offers a user-friendly interface that makes it easy for non-technical users to manage and update website content. Its drag-and-drop editor allows for seamless content creation and customization, reducing reliance on developers."            ,
            Case: [
            {
                image: "",
                CaseTitle: "Confidence Analysis Model ",
                CaseDescribtion:
                    "Use this file however you want!It’s yours, free, for personal or commercial use.",
            },
        ],
    },
    {
        image:Improved_Productivity,
        title: "Seamless Integration",
        alt: "Improved productivity",
        description:
            " HubSpot CMS integrates smoothly with other HubSpot tools, such as CRM and marketing automation, providing a unified platform for managing marketing activities. This integration enhances data synchronization, lead nurturing, and personalization capabilities.            ",
            Case: [
            {
                image: "",
                CaseTitle: "Confidence Analysis Model ",
                CaseDescribtion:
                    "Use this file however you want!It’s yours, free, for personal or commercial use.",
            },
        ],
    },
    {
        image: Enhanced_Security,
        title: "Mobile Optimization",
        alt: "Enhanced security",
        description:
            "HubSpot CMS is designed with mobile responsiveness in mind. It automatically optimizes content for different devices and screen sizes, ensuring a consistent and engaging user experience across desktop, tablet, and mobile platforms",
        Case: [
            {
                image: "",
                CaseTitle: "Confidence Analysis Model ",
                CaseDescribtion:
                    "Use this file however you want!It’s yours, free, for personal or commercial use.",
            },
        ],
    },
    {
        image: IncreasedCustomerSatisfaction,
        title: "Powerful SEO Features:",
        alt: "Increased customer satisfaction",
        description:
            " HubSpot CMS offers robust SEO features, including built-in optimization tools, customizable meta tags, and URL structure management. These features help improve website visibility, organic search rankings, and overall SEO performance. Overall, HubSpot CMS development provides businesses with a comprehensive and user-friendly platform for managing their website content, integrating marketing efforts, optimizing for mobile devices, and enhancing search engine visibility.",
            Case: [
            {
                image: "",
                CaseTitle: "Confidence Analysis Model ",
                CaseDescribtion:
                    "Use this file however you want!It’s yours, free, for personal or commercial use.",
            },
        ],
    },
];

const Fdata = [
    {
        heading: "Browser Extension",
        subData: [
            {
                accordionHead:
                    " Why should you go with Helpful Insight for Chrome extension Development?",
                accordionPara:
                    "Helpful Insight is a leading Chrome extension development company that specialises in creating simple and complex websites in response to social trends. We have a team of developers who are professionals in developing websites for different businesses. We hire developers who can come up with the best development strategy, helping to increase innovation and scalability.Chrome browser extension development services are also provided for enhanced operation.",
                id: "3",
            },
            {
                accordionHead:
                    "How much does it cost to create a Chrome extension at Helpful Insight?",
                accordionPara:
                    "Our goal is to deliver high-quality, fast and efficient websites at competitive prices entirely based on the services our clients require. We estimate application development costs based on business needs and provide the best services available. Please contact us with your specific requirements, and we will give you the best quote.",
                id: "4",
            },
            {
                accordionHead:
                    "Are Browser extensions the Same as apps",
                accordionPara:
                    'No, browser extensions add functionality to your web browser, modify web pages, or integrate with other services you use your browser with. According to Google, an app is "an application that can run in a browser with a dedicated user interface and typically rich user interaction." Browser extensions are used to enhance the functionality of the browser while running an app with a different user interface inside the browser.',
                id: "5",
            },
            {
                accordionHead:
                    "Why develop a browser extension",
                accordionPara:
                    "Today is the day to develop a custom add-on because it would not be an exaggeration to say that people literally use web browsers every day, which means your chances of finding users for the product will not be far off. And if you want to create a desktop-only product, then developing browser extensions is definitely the most cost-effective way to solve a wide range of business problems.",
                id: "6",
            },
            {
                accordionHead:
                    "Who is the owner of the final source code?",
                accordionPara:
                    "Our clients own 100% of all work done. We transfer the intellectual property rights of the work upon the release of the final payment to the project.",
                id: "6",
            },
            {
                accordionHead:
                    "What if we are not happy with the developer/team provided for my Chrome Browser Extension project?",
                accordionPara:
                    "We believe this is not the case, but we do provide the option to change resources/pools if it does. We also communicate with you regularly to ensure that you are satisfied with the services provided.",
                id: "7",
            },
        ],
    },
];

const extension1 = JSON.stringify(
    <g clip-path="url(#0ba41b69f2)">
        <path
            fill="#ffffff"
            d="M 305.355469 187.5 L 273.214844 187.5 L 273.214844 123.214844 C 273.214844 111.386719 263.613281 101.785156 251.785156 101.785156 L 187.5 101.785156 L 187.5 69.644531 C 187.5 51.898438 173.101562 37.5 155.355469 37.5 C 137.613281 37.5 123.214844 51.898438 123.214844 69.644531 L 123.214844 101.785156 L 58.929688 101.785156 C 47.101562 101.785156 37.5 111.386719 37.5 123.214844 L 37.5 187.5 L 69.644531 187.5 C 87.386719 187.5 101.785156 201.898438 101.785156 219.644531 C 101.785156 237.386719 87.386719 251.785156 69.644531 251.785156 L 37.5 251.785156 L 37.5 316.070312 C 37.5 327.898438 47.101562 337.5 58.929688 337.5 L 123.214844 337.5 L 123.214844 305.355469 C 123.214844 287.613281 137.613281 273.214844 155.355469 273.214844 C 173.101562 273.214844 187.5 287.613281 187.5 305.355469 L 187.5 337.5 L 251.785156 337.5 C 263.613281 337.5 273.214844 327.898438 273.214844 316.070312 L 273.214844 251.785156 L 305.355469 251.785156 C 323.101562 251.785156 337.5 237.386719 337.5 219.644531 C 337.5 201.898438 323.101562 187.5 305.355469 187.5 Z M 305.355469 187.5 "
            fill-opacity="1"
            fill-rule="nonzero"
        />
    </g>
);

// console.log("extension1 =>", extension1);

// Service data for TechnologiesWeWork section
const services_data_browser = [
    {
        name: "AI ML  Model/APIs",
        alt: "AI ML  Model/APIs",
        icon: "custom models",
    },
    {
        name: "HTML, CSS,\n Bootstrap, JS,\n Reacy",
        alt: "HTML, CSS,\n Bootstrap, JS,\n Reacy",
        icon: "validation",
    },
    {
        name: "Web Assembly",
        alt: "Web Assembly",
        icon: "engineering",
    },
    {
        name: "Web Audio API",
        alt: "Web Audio API",
        icon: "management",
    },
    {
        name: "Chrome APis",
        alt: "Chrome APis",
        icon: "manufacturing",
    },
];

const CaseData = [
    {
        heading: " Voice Commands",
        alt: " Voice Commands",
        // logo: "https://www.interactpro.ai/hubfs/raw_assets/public/InteractPro_Theme/images/Logo.svg",
        subheading: "Browser Control Through Voice Commands",
        para: "'Browser Control Through Voice Commands' is a free chrome extension that allows you to control your chrome browser through voice commands. You can add the extension to your chrome browser and then give commands to perform your task. ",
        btntext: "More On Voice Commands",
        imgurl: VoiceCommand,

        caseurl: "/casestudy/browser-control-through-voice-commands",


    },
    {
        heading: "Interact Extension",
        alt: "Interact Extension",
        // logo: "https://idealeads.in/wp-content/uploads/2021/10/ideallead_Logo-e1634960127764.webp",
        subheading: "Interact Chrome Extension",
        para: "Empower your sales team to build stronger relationships and close more deals with our super-charged AI Chrome extension for sales reps and robust web app for sales managers.",
        btntext: "More On Interact Extension",
        imgurl: InteractExtension,
        caseurl: "/casestudy/interact-extension",
    },
    {
        heading: "Transcript Extension",
        alt: "Transcript Extension",
        // logo: "https://meinshort.com/static/media/logo.320ce173.svg",
        subheading: "Live Transcript Extension",
        para: "You could want to record lectures from online meetings, online courses, podcasts, and streaming YouTube videos.",
        btntext: "More On Transcript Extension",
        imgurl: LiveTranscript,
        caseurl: "/casestudy/capture-transcript-from-a-meet",
    },
];

const SaasProduct = ({ city }) => {

    return (
        <div>
            <SuperSEO
        title="Chrome Extension Development Company"
        description="Lakebrains is the best chrome browser extension development services company. Lakebrains build high-quality cross-platform Chrome Extension plugins. Also we help in Migrate manifest V2 to V3."
        lang="en"
        twitter={{
          twitterSummaryCard: {
            // summaryCardImage: {BrowserImage},
            summaryCardImageAlt: 'img',
            summaryCardSiteUsername: "justinmahar",
          },
        }}
      />
            <ServicesTopBanner
                headTitle={
                    city
                        ? `Chrome Extension for Hub Spot CMS Development company in ${city}`
                        : `Chrome Extension for Hub Spot CMS Development company`
                }
                headDes=" Visualized data is more engaging and easier to communicate, making it simpler to share insights and findings with stakeholders and colleagues.                "
                video={SaasImage}
            />
            
            <ServicesBoxSection techtype="Build AI Powered Chrome Extension with Lakebrains Experts" />
            
            {/* 6 banners */}
            <AdvantagesOfServices
                data={Bdata}
                work="Why"
                title="Chrome Extension for Hub Spot CMS Development"
                subTitle="Advantages of Chrome extensions for Hub Spot CMS Development"
            />
            <ServiceTechnologiesWeWork
                Cdata={FrontendData}
                title="Browser"
                subTitle="We have worked tirelessly to create a small and creative browser extension that handles huge amounts of data and complex transactions without sacrificing performance. As a result, our best browser extension development solution uses the latest technologies and ensures the highest quality."
            />

            {/* CASE STUDY SECTION */}
            <CaseStudySection CaseData={CaseData} />
            <Whyus />
            <OurTestimonials />
            <ScheduleCall />
            <BlogSlider />
            <FAQ Fdata={Fdata} ServiceType="Browser Extension" />
        </div>
    );
};

export default SaasProduct;
