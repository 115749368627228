import React from 'react'
import './HireTheBest.css'
import handShake from '../../../../images/HireDeveloper/handShake.webp'
import team from '../../../../images/HireDeveloper/team.webp'
import hr from '../../../../images/HireDeveloper/hr.webp'
import consultation from '../../../../images/HireDeveloper/consultation.webp'

const data = [
    {
        image: consultation,
        alt: 'consultation',
        title: 'Share your requirement',
    },
    {
        image: team,
        alt: 'team',
        title: 'We Shortlist the most suitable talent',
    },
    {
        image: hr,
        alt: 'Select the right fit for the business',
        title: 'Select the right fit for the business',
    },
    {
        image: handShake,
        alt: 'handshake',
        title: 'Remote Onboarding & Support',
    },

]

const HireTheBest = () => {
    return (
        <div>
            <div className='container'>
                <div className='homepage-heading'>
                    <h4>Lorem ipsum dolor sit amet </h4>
                    <h2>Hire the best,leave the rest</h2>
                    <p>our 4 step hiring process  to get you going </p>
                </div>
                <div className='hirebest-items-wrapper-00'>
                    <div className='hirebest-items-wrapper row justify-content-center align-items-start'>
                        {data.map((item, index) => (
                            <div className='col-lg-3 col-md-4 col-sm-6 text-center mb-4 hirebest-child-wrapper  '>
                                <div className='hirebest-image-wrapper-01 mb-3'>
                                    <div className='hirebest-image-wrapper-02'>
                                        <div className='hirebest-image-wrapper m-auto'>
                                            <img src={item.image} alt={item.alt} />
                                        </div>
                                    </div>
                                </div>
                                <p className='hirebest-item-index mb-3'>0{index + 1}</p>
                                <div className='hirebest-item-title'>{item.title}</div>

                            </div>
                        ))
                        }
                    </div>
                </div>
                <div className='d-flex justify-content-center mt-3'><button className='schedule-meeting-btn btn'>Inquire Now</button></div>
            </div>
        </div>
    )
}

export default HireTheBest