import  React  from "react";
import TechImage from "../../../images/chrome-extension-develpment/VoiceCommand 1.svg";
import "./tech.css";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleArrowLeft, faCircleArrowRight } from '@fortawesome/free-solid-svg-icons';
// import './Slideshow.css';
// import Icon1 from '../Assets/Techicons/Vector.svg';
// import Icon2 from '../Assets/Techicons/jquery 1.svg';
// import Icon3 from '../Assets/Techicons/react 1.svg';
// import Icon4 from '../Assets/Techicons/javascript 1.svg';
// import Icon5 from '../Assets/Techicons/bootstrap 1.svg';
// import Icon6 from '../Assets/Techicons/Vector (1).svg';
// import Icon7 from '../Assets/Techicons/Group 65.svg';
// import Icon8 from '../Assets/Techicons/Vector (2).svg';
// import Icon9 from '../Assets/Techicons/nextjs 1.svg';
import Icons from "../../../images/chrome-extension-develpment/Group 167.svg";
import Btn1 from "../../../images/chrome-extension-develpment/Frame 39.svg";
import Btn2 from "../../../images/chrome-extension-develpment/Frame 38.svg";
// import Slideshow from "./slideShow";


const slides = [
  {
    image: TechImage,
    head:  'Live Transcript Extension',
    text: '  We fortunate enough to work on something extra ordinarywhere we have build the browser extension which is having AIcapability like live behavior analysis, transcription, noise . Our team will keep pushing our limits in build more smart browser extensions.',
    button: 'More on live transcript',
    url: 'https://lakebrains.com/casestudy/capture-transcript-from-a-meet/',
    class:"btn-3"
  
  
  },
  {
    image: TechImage, 
    head: 'Interact Chrome Extension',
    text: 'Empower your sales team to build stronger relationships and close more deals with our super-charged AI Chrome extension for sales reps and robust web app for sales managers.',
    button : 'More on Interact Extension',
    url: 'https://lakebrains.com/casestudy/interact-extension/',
    class:"btn-3"
  },
  {
    image: TechImage,
    head: ' Browser Control Through Voice Commands',
    text:  'Browser Control Through Voice Commands is a free chrome extension that allows you to control your chrome browser through voice commands. You can add the extension to your chrome browser and then give commands to perform your task. ',
    button: 'More on Voice Commands',
    url: 'https://lakebrains.com/casestudy/browser-control-through-voice-commands/',
    class:"btn-3"
  }  
];
const Slideshow = () => {

  const [current, setCurrent] = React.useState(0);

  const nextSlide = () => {
    setCurrent(current === slides.length - 1 ? 0 : current + 1);
  }
  
  const prevSlide = () => {
    setCurrent(current === 0 ? slides.length - 1 : current - 1);
  }

  return (
    <div className='slider'>
    
  

      {slides.map((slide, index) => {
        return (
          <div className="tech-2"
            key={index}
            style={{display: index === current ? 'block' : 'none'}}
          >
          <div className="img-div">
          <img className='tech-img' src={slide.image} alt="slide"/>
           </div>
            <div className="t-div">
            <h1 className="t2-h">{slide.head}</h1>
            <p className="t2-p">{slide.text}</p> 
            <div className="tech-btns">  
            <a href={slide.url}>
            <button   className={slide.class}>{slide.button}</button>
            {index === 0 && <button   className="btn-4">View More</button>}
            </a>
            </div>
            </div>
          </div>
        )
      })}
      <div className='slid-btns'>
    
      <FontAwesomeIcon className="left" icon={faCircleArrowLeft} onClick={prevSlide}></FontAwesomeIcon> 
      <FontAwesomeIcon className="right" icon={faCircleArrowRight} onClick={nextSlide}></FontAwesomeIcon>
    
      </div>
    </div>
    
  );
}
// const responsive = {
//   desktop: {
//     breakpoint: { max: 3000, min: 1024 },
//     items: 1,
//     slidesToSlide: 1,
//   },
//   tablet: {
//     breakpoint: { max: 1204, min: 800 },
//     items: 1,
//     slidesToSlide: 1,
//   },
//   smalltablet: {
//     breakpoint: { max: 800, min: 550 },
//     items: 1,
//     slidesToSlide: 1,
//   },
//   mobile: {
//     breakpoint: { max: 550, min: 0 },
//     items: 1,
//     slidesToSlide: 1,
//   },
// };
const Tech = (  ) => {



  return (
    <div className="container">
      
      <div className="tech-main">
        <div className="tech-divs">
          <div className="tech-1">
          <div className="tech-texts">
            <h2 className="tech-h1">Experience AI for your business</h2>
            <h1 className="tech-h2">Innovative Solution for Your Business </h1>
            <p className="tech-p">
              {" "}
              We specialize in solving complex business challenges using
              cutting-edge technologies. Experience the power of Al and
              innovative approaches to take your business to the next level.
            </p>
            </div>
            {/* <div className='slid-btns'>
    
    <FontAwesomeIcon className="left" icon={faCircleArrowLeft} onClick={props.prevSlide}></FontAwesomeIcon> 
    <FontAwesomeIcon className="right" icon={faCircleArrowRight} onClick={props.nextSlide}></FontAwesomeIcon>
  
    </div> */}
          </div>
          <Slideshow />
          {/* <Carousel
            swipeable={true}
            draggable={true}
            //   showDots={true}
            responsive={responsive}
            ssr={true} // means to render carousel on server-side.
            infinite={true}
            autoPlay={true}
            autoPlaySpeed={10000}
            keyBoardControl={true}
            customTransition="all 1s ease-in-out"
            transitionDuration={1000}
            containerclassName="carousel-container12"
            removeArrowOnDeviceType={["tablet", "mobile"]}
            //   deviceType={props.deviceType}
            dotListclassName="custom-dot-list-style1"
            itemclassName="carousel-item-padding-40"
            className="backg"
          >
            <div className="back_in">
              <div className="tech-2">
                <div className="t-div">
                  <h1 className="t2-h">Live Transcript Extension</h1>
                  <p className="t2-p">
                    We fortunate enough to work on something extra ordinary
                    where we have build the browser extension which is having AI
                    capability like live behavior analysis, transcription, noise
                    . Our team will keep pushing our limits in build more smart
                    browser extensions.
                  </p>
                  <div className="btns">
                    <button
                      type="button"
                      className=" btn-3"
                      onClick={() => {
                        window.location.href =
                          "https://lakebrains.com/casestudy/capture-transcript-from-a-meet/";
                      }}
                    >
                      More on live Transcript
                    </button>
                    <button
                      type="button"
                      className=" btn-4"
                      onClick={() => {
                        window.location.href =
                          "https://lakebrains.com/casestudy/capture-transcript-from-a-meet/";
                      }}
                    >
                      View more
                    </button>
                  </div>
                </div>
                <div className="imagebox">
                  <img className="img-fluid imageboxImg" alt="Timg"  src={TechImage} />
                </div>
              </div>

            </div>
            <div className="back_in">
            
            <div className="tech-2">
                <div className="t-div">
                  <h1 className="t2-h"> Interact Chrome Extension</h1>
                  <p className="t2-p">
                  Empower your sales team to build stronger relationships and close more deals with our super-charged AI Chrome extension for sales reps and robust web app for sales managers.
                  </p>
                  <div className="btns">
                    <button
                      type="button"
                      className=" btn-3"
                      onClick={() => {
                        window.location.href =
                          "https://lakebrains.com/casestudy/capture-transcript-from-a-meet/";
                      }}
                    >
                      More on Interact Extension
                    </button>
                    
                  </div>
                </div>
                <div className="imagebox">
                  <img className="img-fluid imageboxImg" alt="Timg" src={TechImage} />
                </div>
              </div>
              </div>
              <div className="back_in">
              <div className="tech-2">
                <div className="t-div">
                  <h1 className="t2-h"> Browser Control Through Voice Commands</h1>
                  <p className="t2-p">
                  'Browser Control Through Voice Commands' is a free chrome extension that allows you to control your chrome browser through voice commands. You can add the extension to your chrome browser and then give commands to perform your task.
                  </p>
                  <div className="btns">
                    <button
                      type="button"
                      className=" btn-3"
                      onClick={() => {
                        window.location.href =
                          "https://lakebrains.com/casestudy/capture-transcript-from-a-meet/";
                      }}
                    >
                      More on Voice Commands
                    </button>
                    
                  </div>
                </div>
                <div className="imagebox">
                  <img className="img-fluid imageboxImg" src={TechImage} />
                </div>
                </div>
              </div>
          
              
          </Carousel> */} 

        </div>
      </div>
      <br />
      <div className="tech-main2 ">
        <div className="tech-3 ">
          <div className="icons">
            <img className="icon" alt="icons" src={Icons} />
          </div>
        </div>

        {/* Changes for chrome */}
        <div className="tech-4 ">
          <h1 className="tech-h1">Our Innovation Tech Stake</h1>
          <h1 className="tech-h22">
            Advanced Technologies that meet your needs
          </h1>
          <p className="tech4-p">
            We pride ourselves on using the latest technologies to create
            innovative solutions that meet your needs. Our tech stake is
            designed to handle complex transactions and large amounts of data,
            while still delivering high performance. We use only the best
            technologies to ensure our solutions are reliable, scalable, and
            secure.
          </p>
        </div>
        
      </div>
    </div>
  );
}




export default Tech;
